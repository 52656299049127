import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Button from './Button'
import Dropdown from './ReactSelectDropdown'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const defaultStyle = 
        {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '15px'
        }

    return (
        <div style={defaultStyle}>
            <span style={{fontSize: '18px', fontWeight: '600', marginTop: '10px'}}>Dealers</span>
            <Dropdown colors={props.colors} data={dealers2} value={''} Style={{width: '87%', marginTop: '5px', marginBottom: '20px'}}></Dropdown>
            <span style={{fontSize: '18px', fontWeight: '600'}}>{props.title}</span>
            <div style={{overflowY: 'scroll', width: '100%', display: 'flex', flexDirection: 'column'}}>
                {props.list.map((item, index) => {
                    return <Button className='center noSelect' colors={props.colors} onClick={() => ''} Style={{padding: '3px 10px 3px 10px', marginTop: '7px', marginRight: '20px', marginLeft: '5px', marginBottom: index === props.list.length - 1 ? '15px':''}}>
                                <span style={{fontWeight: '500'}}>{item}</span>
                            </Button>
                })}
            </div>

        </div>
    )
}

export default Main
