import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link, useNavigate } from "react-router-dom"

import Modal from 'react-modal';

import { useCookies, withCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookie, removeCookies, updateCookies] = useCookies([])

    const nav = useNavigate();

    const checkAuth = () => {
        if (cookies['refresh_token'] === undefined)
        {
            return false;
        } else {
            return true;
        }
    }

    useEffect(() => {
        if (checkAuth())
        {

        }
        else
        {
            console.log('Back to the gulag')
            nav('/login')
        }
    },[])

    return (
        <div>
            {checkAuth() ? props.children:''}
        </div>
    )
}

export default withCookies(Main)
