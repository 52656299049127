import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from './IconButton'
import Button from './Button'
import Dropdown from './ReactSelectDropdown'

import { BarChart, Bar, Rectangle, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Main = (props) => {

  console.log(props)

  const dealers2 = [
      { value: '4 Season Motors', label: '4 Season Motors' },
      { value: 'Chopper City', label: 'Chopper City' },
      { value: 'Auto Station', label: 'Auto Station' },
      { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
      { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
      { value: 'Sunset Motors', label: 'Sunset Motors' },
      { value: 'Speedy Wheels', label: 'Speedy Wheels' },
      { value: 'Elite Autos', label: 'Elite Autos' },
      { value: 'Golden Motors', label: 'Golden Motors' },
      { value: 'Thunder Auto', label: 'Thunder Auto' },
      { value: 'Cityscape Cars', label: 'Cityscape Cars' },
      { value: 'Majestic Motors', label: 'Majestic Motors' },
      { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
      { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
      { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
      { value: 'Royal Wheels', label: 'Royal Wheels' },
      { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
      { value: 'Prime Drive', label: 'Prime Drive' },
      { value: 'Dynamic Motors', label: 'Dynamic Motors' },
      { value: 'Precision Auto', label: 'Precision Auto' },
      { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
      { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
      { value: 'Safari Motors', label: 'Safari Motors' },
      { value: 'Vanguard Auto', label: 'Vanguard Auto' },
      { value: 'Velocity Motors', label: 'Velocity Motors' },
      { value: 'Omega Cars', label: 'Omega Cars' },
      { value: 'Supreme Autos', label: 'Supreme Autos' },
      { value: 'Victory Vehicles', label: 'Victory Vehicles' },
      { value: 'Zenith Motors', label: 'Zenith Motors' }
  ]

  const data = [
      {
        name: 'Jan',
        Net: 11500,
      },
      {
        name: 'Feb',
        Net: 8500,
      },
      {
        name: 'Mar',
        Net: 14500,
      },
      {
        name: 'Apr',
        Net: 11000,
      },
      {
        name: 'May',
        Net: 12000,
      },
      {
        name: 'Jun',
        Net: 11750,
      },
      {
        name: 'Jul',
        Net: 6000,
      },
      {
        name: 'Aug',
        Net: 2900,
      },
      {
        name: 'Sep',
        Net: 7750,
      },
      {
        name: 'Oct',
        Net: 13500,
      },
      {
        name: 'Nov',
        Net: 10000,
      },
      {
        name: 'Dec',
        Net: 5900,
      },
  ];

  const formatData = () => {
    if ('graphData' in props && 'graphKeys' in props)
    {
      console.log(props.graphData)
      let tempData = []
      props.graphKeys.map((key, index) => {
        tempData.push({
          name: key,
          Net: props.graphData[index]
        })
      })
      tempData.reverse()
      return tempData
    }
    else
    {
      return []
    }
  }

  return (
      <div style={{backgroundColor: props.colors.block, display: 'flex', flexDirection: 'column', border: '1px solid ' + props.colors.border, borderRadius: '10px', marginTop: '15px', paddingTop: '15px', alignItems:'center', height: '289px', width: 'calc(100% - 20px)', minHeight: '289px'}}>
          <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', width: '100%'}}>
              <span style={{marginLeft: '24px', fontWeight: '600', fontSize: '20px'}}>{props.title}</span>
              {/* <Dropdown data={dealers2} colors={props.colors} value={'1 Year'} Style={{border: 0, marginRight: '20px'}}></Dropdown> */}
          </div>
          <ResponsiveContainer width="100%" height="100%">
              <BarChart
                  width={500}
                  height={300}
                  data={formatData()}
                  margin={{
                      top: 5,
                      right: 30,
                      left: 20,
                      bottom: 5,
                  }}
                  >
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" style={{WebkitUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}/> {/*These are the inline css for the noSelect class */}
                  <YAxis style={{WebkitUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}/>
                  <Tooltip />
                  <Legend wrapperStyle={{WebkitUserSelect: 'none', msUserSelect: 'none', userSelect: 'none'}}/>
                  <Bar dataKey="Net" fill={props.barColor === undefined ? props.colors.primary:props.barColor} barSize={30} radius={[5, 5, 0, 0]} activeBar={<Rectangle fill={props.barColor === undefined ? "#9ee3ff":props.selectBarColor} stroke={props.barColor === undefined ? props.colors.primary:props.barColor} />} />
              </BarChart>
          </ResponsiveContainer>
      </div>
  )
}

export default Main
