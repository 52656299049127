import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import DownArrow from '../media/icons/Down_Arrow.svg'
import DarkMode from '../media/icons/icons8-dark-mode-96.png'
import ChangeUser from '../media/icons/icons8-change-96.png'
import Logout from '../media/icons/icons8-logout-96.png'
import Settings from '../media/icons/icons8-settings-96-black.png'

import ModalManager from '../elements/ModalManager'
import ChangeUserModal from '../elements/ChangeUserModal'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            role={'string'} - Role text
            initals={'string'} - initals in bubble
            children={'string'} - Name of agent
    */}

    const [windowOpen, setWindowOpen] = useState(false)

    const [cookies, setCookie, removeCookie] = useCookies([])

    const [name, setName] = useState(cookies['name']);
    const [role, setRole] = useState(cookies['role']);
    const [realUser, setRealuser] = useState(cookies['real_user'])

    const defaultStyle = 
    {
        backgroundColor: 'white',
        borderRadius: '23px',
        border: '1px solid ' + props.colors.border,
        color: 'black',
        fontWeight: '500',
        width: '250px',
        height: '45px',
        cursor: 'pointer',
        position: 'relative',
        borderBottomRightRadius: windowOpen ? '0px':'23px',
        borderBottomLeftRadius: windowOpen ? '0px':'23px',
    }

    const impersonateUser = (agent) => {
        console.log("Impersonating Agent: " + agent)

        if (agent === '')
        {
            //basically a catch to stop them from impersonating nobody
            return '';
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var urlencoded = new URLSearchParams();

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: urlencoded,
            redirect: 'follow'
        };

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        props.tokenSafeAPIRequest('/auth/impersonate/' + agent, requestOptions, tokenValues, (result) => {
            console.log(result)
            setCookie('real_user', {
                role: cookies['role'],
                name: cookies['name'],
                access_token: cookies['access_token'],
                refresh_token: cookies['refresh_token'],
                access_token_expires: cookies['access_token_expires'],
                refresh_token_expires: cookies['refresh_token_expires']
            })
            localStorage.setItem('real_user', JSON.stringify({
                role: cookies['role'],
                name: cookies['name'],
                access_token: cookies['access_token'],
                refresh_token: cookies['refresh_token'],
                access_token_expires: cookies['access_token_expires'],
                refresh_token_expires: cookies['refresh_token_expires']
            }))
            localStorage.setItem('refresh_token', result.refresh_token)
            localStorage.setItem('access_token', result.access_token)
            setCookie('refresh_token', result.refresh_token, { path: '/' })
            setCookie('refresh_token_expires', result.refresh_token_expires, { path: '/' })
            setCookie('access_token_expires', result.access_token_expires, { path: '/' })
            userData(
                {
                    refresh_token: result.refresh_token,
                    refresh_token_expires: result.refresh_token_expires,
                    access_token: result.access_token,
                    access_token_expires: result.access_token_expires,
                }
            )
        })
    }

    const userData = (tokenValues) => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + tokenValues.access_token);
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        props.tokenSafeAPIRequest('/user/me', requestOptions, tokenValues, (result) => {
          console.log(result)
          setCookie('name', result.first_name + ' ' + result.last_name, { path: '/' })
          setCookie('role', result.role, { path: '/' })
          setCookie('access_token', tokenValues.access_token, { path: '/' })
          localStorage.setItem('name', result.first_name + ' ' + result.last_name)
          localStorage.setItem('role', result.role)
          localStorage.setItem('access_token', tokenValues.access_token)
          window.location.assign('/');
          window.location.reload()
        })
    }

    const revertUser = () => {
        console.log('Reverting user back to original')
        if (cookies['real_user'] === undefined)
        {
            if (localStorage.real_user === undefined)
            {

            }
            else
            {
                console.log('Cookie Data Missing, Using Local Storage')
                let localStorageData = JSON.parse(localStorage.getItem('real_user'))
                setCookie('name', localStorageData.name, { path: '/' })
                setCookie('role', localStorageData.role, { path: '/' })
                setCookie('access_token', localStorageData.access_token, { path: '/' })
                setCookie('refresh_token', localStorageData.refresh_token, { path: '/' })
                setCookie('refresh_token_expires', localStorageData.refresh_token_expires, { path: '/' })
                setCookie('access_token_expires', localStorageData.access_token_expires, { path: '/' })
                removeCookie('real_user', { path: '/' });
                localStorage.setItem('name', localStorageData.name)
                localStorage.setItem('role', localStorageData.role)
                localStorage.setItem('access_token', localStorageData.access_token)
                localStorage.setItem('refresh_token', localStorageData.refresh_token)
                localStorage.removeItem('real_user')
                window.location.assign('/');
            }
        }
        else
        {
            console.log('Cookies Data Found, Using that')
            let localStorageData = JSON.parse(localStorage.getItem('real_user'))
            setCookie('name', realUser.name, { path: '/' })
            setCookie('role', realUser.role, { path: '/' })
            setCookie('access_token', realUser.access_token, { path: '/' })
            setCookie('refresh_token', realUser.refresh_token, { path: '/' })
            setCookie('refresh_token_expires', realUser.refresh_token_expires, { path: '/' })
            setCookie('access_token_expires', realUser.access_token_expires, { path: '/' })
            removeCookie('real_user', { path: '/' });
            localStorage.setItem('name', localStorageData.name)
            localStorage.setItem('role', localStorageData.role)
            localStorage.setItem('access_token', localStorageData.access_token)
            localStorage.setItem('refresh_token', localStorageData.refresh_token)
            localStorage.removeItem('real_user')
            window.location.assign('/');
        }
    }

    const logout = () => {
        console.log('logging out')
        Object.keys(cookies).forEach(cookieName => {
            console.log(cookieName)
            removeCookie(cookieName, { path: '/' });
        });
        localStorage.clear();
        window.location.assign('/login');
    }

    const [changeUser, setChangeUser] = useState(false)

    function getCapitalizedFirstLetters(input) {
        let words;
        if (typeof input === 'string') {
            words = input.split(' ');
        } else {
            // Handle the case where input is not a string.
            // This could be setting `words` to an empty array or a default value.
            words = []; // or some default value
        }
        if (words.length < 2) {
            return 'Input should contain at least two words.';
        }
        let firstLetters = words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase();
        return firstLetters;
    }

    const makeFirstLetterCapital = (input) => {
        return input.charAt(0).toUpperCase() + input.slice(1)
    }

    const formatRole = (role) => {
        if (typeof role === 'string') {
            return role.replace(/_/g, ' ');
        } else {
            // Handle the case where role is not a string.
            // This could be returning a default value or an empty string.
            return ''; // or some default value
        }
    }

    const checkRealUser = () => {
        console.log(cookies['real_user'])
        console.log(localStorage.real_user)
        if (localStorage.real_user !== undefined)
        {
            return true
        }
        else
        {
            return false;
        }
    }

    return (
        <div className='noSelect' style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <div onClick={() => setWindowOpen((windowOpen) => !windowOpen)} className='center' style={{backgroundColor: props.colors.primary, width: '17.35%', height: '100%', borderRadius: '100px', float: 'left'}}>
                <span style={{color: props.colors.fontInvert}}>{getCapitalizedFirstLetters(props.children === undefined ? name:props.children)}</span>
            </div>
            <div onClick={() => setWindowOpen((windowOpen) => !windowOpen)} style={{width: '50%', height: '100%', float: 'left', marginLeft: '5%', textAlign: 'left'}}>
                <div style={{width: '100%', height: '50%', marginTop: '2px'}}>
                    <span style={{whiteSpace: 'nowrap'}}>{props.children === undefined ? name:props.children}</span>
                </div>
                <div style={{width: '100%', height: '50%', marginTop: '-5px'}}>
                    <span style={{color: props.colors.muted, fontSize: '12px'}}>{makeFirstLetterCapital(props.role === undefined ? formatRole(role):props.role)}</span>
                </div>
            </div>
            <div onClick={() => setWindowOpen((windowOpen) => !windowOpen)} className='center' style={{float: 'left', width: '27%', height: '100%'}}>
                <motion.img style={{rotateX: windowOpen ? '180deg':'0deg'}} src={DownArrow}></motion.img>
            </div>
            {windowOpen ? <motion.div style={{position: 'absolute', backgroundColor: props.colors.block, width: '250px', height: 'auto', top: '44px', borderLeft: '1px solid ' + props.colors.border, borderRight: '1px solid ' + props.colors.border, borderBottom: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'column', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', translateX: '-1px'}}>
                <motion.div onClick={() => "setDarkMode api"} whileHover={{backgroundColor: props.colors.highlight}} transition={{duration: 0}} style={{paddingLeft: '15px', padding: '5px'}}><img style={{width: '24px', height: '24px', float: 'left'}} src={DarkMode}></img><span style={{marginLeft: '10px'}}>Dark Mode</span></motion.div>
                <motion.div onClick={() => checkRealUser() ? revertUser():setChangeUser(true)} whileHover={{backgroundColor: props.colors.highlight}} transition={{duration: 0}} style={{paddingLeft: '15px', padding: '5px'}}><img style={{width: '24px', height: '24px', float: 'left'}} src={ChangeUser}></img><span style={{marginLeft: '10px'}}>{checkRealUser() ? 'Original User':'Change User'}</span></motion.div>
                <Link to='/settings'><motion.div onClick={() => ""} whileHover={{backgroundColor: props.colors.highlight}} transition={{duration: 0}} style={{paddingLeft: '15px', padding: '5px'}}><img style={{width: '24px', height: '24px', float: 'left'}} src={Settings}></img><span style={{marginLeft: '10px'}}>User Settings</span></motion.div></Link>
                <Link to='/login'><motion.div onClick={() => logout()} whileHover={{backgroundColor: props.colors.highlight}} transition={{duration: 0}} style={{paddingLeft: '15px', padding: '5px', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px'}}><img style={{width: '24px', height: '24px', float: 'left'}} src={Logout}></img><span style={{marginLeft: '10px'}}>Logout</span></motion.div></Link>
            </motion.div>:''} 
            <ModalManager colors={props.colors}>
                <ChangeUserModal colors={props.colors} isOpen={changeUser} setIsOpen={() => setChangeUser(false)} setImpersonateUser={impersonateUser} baseURL={props.baseURL} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ChangeUserModal>
            </ModalManager>
        </div>
    )
}

export default Main
