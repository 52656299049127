import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {
    
    const onChange = (e, index) => {
        props.setNewReserveData((newSpiffeeData) => newSpiffeeData.map((dataPoint, indexParent) => {
            if (index === indexParent)
            {
                if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
            } else {return dataPoint}
        }))
        props.setNewReserveID((newSpiffeeID) => newSpiffeeID.map((dataPoint, indexParent) => {
            if (index === indexParent)
            {
                if (e.value === undefined)
                    {
                        return e;
                    }
                    return e.value;
            } else {return dataPoint}
        }))
    }

    const clearReport = () => {
        console.log('clearing report!')
        props.setNewReserveData([
            '',
            0,
            '',
        ])
        props.setNewReserveID([
            '',
            0,
            '',
        ])
    }

    return (
        <div style={{width: '360px', height: '350px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Add Reserve Input</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '15px'}}>Account</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={props.reserveAccounts} value={props.newReserveData[0]}></Dropdown></div>
                <span style={{marginTop: '15px'}}>Amount</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 1)} colors={props.colors} data={props.newReserveData[1]}></TextInput>
                <span style={{marginTop: '15px'}}>Reason</span>
                <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 2)} colors={props.colors} data={props.newReserveData[2]}></TextInput>
                <Button onClick={() => {props.setIsOpen(); props.addReserveInput({account: props.newReserveData[0], amount: props.newReserveData[1], reason: props.newReserveData[2], id: props.newReserveID[0]}); clearReport();}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Add</Button>
            </div>
        </div>
    )
}

export default Main
