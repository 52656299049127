import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../elements/IconButton'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import Button from '../elements/Button'

const Main = (props) => {

    const [reason, setReason] = useState('')

    return (
        <div style={{width: '360px', height: '250px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Deny Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Reason</span>
                <textarea style={{fontFamily: 'montserrat', fontWeight: '500', height: '100%', border: '1px solid ' + props.colors.border, borderRadius: '5px', marginTop: '5px', padding: '10px'}} value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
                <Button onClick={() => props.onClick(reason, props.selectedThursdayReport)} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
