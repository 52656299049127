import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'
import SubmitReport from '../assets/commissionreceivedos/SubmitReport.jsx'

import DenyReport from '../assets/DenyConfirm.jsx'

import Approve from '../media/icons/icons8-approve-96.png'
import Deny from '../media/icons/icons8-cancel-96.png'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Subagent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Shipping Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'ATTN',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Spiff Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Approve/Deny',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => approveReport(props.index, props.tableData)} Style={{backgroundColor: props.colors.primary, marginLeft: '10px'}} size={27} image={Approve} colors={props.colors}></IconButton>
                    <IconButton onClick={() => denyReport(props.index, props.tableData)} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={27} image={Deny} colors={props.colors}></IconButton>
                </div>),
            default: '',
        },
    ])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
    }

    const templates = [
        {
            id: 0,
            name: "Template 1",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 200,
            value: 'Template 1',
            label: 'Template 1',
        },
        {
            id: 1,
            name: "Template 2",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 300,
            value: 'Template 2',
            label: 'Template 2',
        },
        {
            id: 2,
            name: "Template 3",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 400,
            value: 'Template 3',
            label: 'Template 3',
        },
        {
            id: 3,
            name: "Template 4",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 500,
            value: 'Template 4',
            label: 'Template 4',
        },
        {
            id: 4,
            name: "Template 5",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 2600,
            value: 'Template 5',
            label: 'Template 5',
        },
    ]

    const deleteRow = (index) => {
        console.log('Deleted Row')
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
    }

    const onTableChange = (e, y, x) => {
        //console.log('Table Updated')
        if (x === 1) //check what column the change was in, 1 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e)
                {
                    applyTemplate(template, y)
                }
            })
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    return e;
                }
                return data;
            })
        }))
    }

    const applyTemplate = (template, row) => {
        setTableValues((tableValues) => tableValues.map((column, indexX) => {
            if (indexX === 2) {column[row] = template.dealer}
            if (indexX === 3) {column[row] = template.admin}
            if (indexX === 4) {column[row] = template.contractType}
            if (indexX === 5) {column[row] = template.grossPerContract}
            return column;
        }))
    }

    const submitSelectedReports = () => {
        setSubmitReportModal(true)
    }

    const [rowSelected, setRowSelected] = useState([false])

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Subagent
        [tableTitles[1].default], //Spiffee
        [tableTitles[2].default], //Amount
        [tableTitles[3].default], //Shipping Address
        [tableTitles[4].default], //ATTN
        [tableTitles[5].default], //Spiff Type
        [tableTitles[6].default], //Delete
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
    ])

    const showTableData = () => {
        console.log(tableValues)
    }

    const [submitReportModal, setSubmitReportModal] = useState(false)

    const [denyModal, setDenyModal] = useState(false)

    const [refresh, setRefresh] = useState(false)

    function combineObjectsWithSameCode(arr) {
        const combined = arr.reduce((acc, obj) => {
          const key = obj.code;
      
          if (!acc[key]) {
            // Initialize with the current object, set quantity to 1, and start the ID array with the current ID.
            acc[key] = { ...obj, quantity: 1, IDs: [obj.id] };
          } else {
            // If the key exists, increment the quantity and push the current ID into the ID array.
            acc[key].quantity += 1;
            acc[key].IDs.push(obj.id);
          }
      
          // Remove the single ID property since we now have an IDs array.
          delete acc[key].id;
      
          return acc;
        }, {});
      
        console.log(Object.values(combined))
        return Object.values(combined);
      }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/prespiff/need_approval?fetch_links=true', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let tempReportDisplay = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempReportData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            combineObjectsWithSameCode(result).map((report, index) => {
                tempReportDisplay[0].push(report.quantity)
                tempReportDisplay[1].push(report.spiffee_ID === null ? report.dealership_ID.name:report.spiffee_ID.name)
                tempReportDisplay[2].push(report.amount)
                tempReportDisplay[3].push(report.address + ', ' + report.city + ', ' + report.state_code)
                tempReportDisplay[4].push(report.ATTN)
                tempReportDisplay[5].push(report.spiff_type)
                tempReportDisplay[6].push(report.IDs)

                tempReportData[0].push(report.quantity)
                tempReportData[1].push(report.spiffee_ID === null ? report.dealership_ID.id:report.spiffee_ID.id)
                tempReportData[2].push(report.amount)
                tempReportData[3].push(report.address + ', ' + report.city + ', ' + report.state_code)
                tempReportData[4].push(report.ATTN)
                tempReportData[5].push(report.spiff_type)
                tempReportData[6].push(report.IDs)
            })

            setTableValues(tempReportDisplay)
            setTableData(tempReportData)
        })

    },[refresh])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const approveReport = (index, currentTableData) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(transposeArray(currentTableData)[index][6])
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(transposeArray(currentTableData)[index][6])
        };

        props.tokenSafeAPIRequest('/report/prespiff/approve', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Approved Reports Successfully at: ' + getCurrentTimeFormatted())
                setRefresh((refresh) => !refresh)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const denyReport = (index, currentTableData) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(transposeArray(currentTableData)[index][6])
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            IDs: transposeArray(currentTableData)[index][6],
            rejection_reason: '    ',
        })
        };

        props.tokenSafeAPIRequest('/report/prespiff/reject', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Denied Reports Successfully at: ' + getCurrentTimeFormatted())
                setRefresh((refresh) => !refresh)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [notification, setNotification] = useState('')

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={false} buttonList={buttonList} title={"Prespiff Reports (OS)"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div>{/*Just to make sure nothing goes wrong with MainLayout, as this takes the place of MetricHeader*/}</div>
            <Table selectMenu={false} colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange}></Table>
            <ModalManager colors={props.colors}>
                <ManageTemplate colors={props.colors} templates={templates} isOpen={manageTemplateModal} onChange={(value, y, x) => console.log(value, x, y)} setIsOpen={() => setManageTemplateModal(false)}></ManageTemplate>
                <SaveTemplate colors={props.colors} isOpen={manageSaveTemplateModal} onChange={(value, y, x) => console.log(value, x, y)} setIsOpen={() => setManageSaveTemplateModal(false)}></SaveTemplate>
                <SubmitReport colors={props.colors} isOpen={submitReportModal} setIsOpen={() => setSubmitReportModal(false)}></SubmitReport>
                <DenyReport colors={props.colors} isOpen={denyModal} setIsOpen={() => setDenyModal(false)}></DenyReport>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
