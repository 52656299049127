import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'

import Save from '../../media/icons/icons8-save-96.png'
import Edit from '../../media/icons/icons8-edit-96.png'
import Approve from '../../media/icons/icons8-approve-96.png'
import Deny from '../../media/icons/icons8-cancel-96.png'

import TextInput from '../../elements/TextInput.jsx'

import Delete from '../../media/icons/icons8-delete-96.png'

import ModalManager from '../../elements/ModalManager.jsx'
import AddSpiffee from './AddSpiffee.jsx'
import AddReserveInput from './AddReserveInput.jsx'

const Main = (props) => {

    console.log(props)

    const [reportSplits, setReportSplits] = useState(
        {
            flats: props.selectedReport.total_flatee_payout,
            gross: props.selectedReport.contract_gross,
            net: props.selectedReport.net,
            spiffs: props.selectedReport.total_spiffee_payout,
            dppp: props.selectedReport.dppp_amount,
            insideSales: props.selectedReport.inside_sales_payout,
            gap: props.selectedReport.gap_amount,
            contractCount: props.selectedReport.total_contract_count,
        }
    )

    const [addSpiffeeModal, setAddSpiffeeModal] = useState(false)
    const [addReserveInputModal, setAddReserveInputModal] = useState(false)

    const [allAdmins, setAllAdmins] = useState([])
    const [allSubagents, setAllSubagents] = useState([])
    const [allContractTypes, setAllContractTypes] = useState([])

    // const onChange = (value, key) => {
    //     console.log(value, key)
    //     if (key === 'dealership')
    //     {
    //         setData(data => ({
    //             ...data,
    //             [key]: {value: value.value, label: value.label},
    //             contract_type: {value: '', label: ''},
    //             admin: {value: '', label: ''},
    //         }))
    //         loadAdmins(value.value);
    //     }
    //     if (key === 'admin')
    //     {
    //         setData(data => ({
    //             ...data,
    //             [key]: {value: value.value, label: value.label}
    //         }))
    //         loadContractTypes(value.value)
    //     }
    //     else
    //     {
    //         setData(data => ({
    //             ...data,
    //             [key]: {value: value.value, label: value.label}
    //         }))
    //     }
    // }

    // const loadAdmins = (id) => {
    //     //get dealers
    //     var myHeaders = new Headers();
    //     myHeaders.append("Accept", "application/json");
    //     myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
    //     myHeaders.append("Content-Type", "application/json");

    //     var requestOptions = {
    //         method: 'GET',
    //         headers: myHeaders,
    //         redirect: 'follow'
    //     };

    //     props.tokenSafeAPIRequest('/dealership/' + id + '?fetch_links=True', requestOptions, '', (result) => {
    //         console.log(result)
    //         let tempAdminList = []
    //         result.admin_IDs.map((admin, index) => {
    //             tempAdminList.push({value: admin.id, label: admin.name})
    //         })
    //         onChange({value: result.subagent_owner_ID.id, label: result.subagent_owner_ID.first_name + ' ' + result.subagent_owner_ID.last_name}, 'subagent') //loads subagent owner
    //         setAllAdmins(tempAdminList)
    //     })
    // }

    // const loadContractTypes = (id) => {
    //     if (id === '')
    //     {

    //     }
    //     else
    //     {
    //         var myHeaders = new Headers();
    //         myHeaders.append("Accept", "application/json");
    //         myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
    //         myHeaders.append("Content-Type", "application/json");
    
    //         var requestOptions = {
    //             method: 'GET',
    //             headers: myHeaders,
    //             redirect: 'follow',
    //         };
    
    //         props.tokenSafeAPIRequest('/admin/' + id, requestOptions, '', (result) => {
    //             console.log(result)
    //             let tempContractTypeList = []
    //             result.VSC_contracts.map((contractType, index) => {
    //                 tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
    //             })
    //             result.ancillary_contracts.map((contractType, index) => {
    //                 tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
    //             })
    
    //             setAllContractTypes(tempContractTypeList)
    //         })
    //     }
    // }

    useEffect(() => {
        calculateAmounts()
    },[])

    const calculateContractCount = (contractType, net) => {
        if (contractType.value.split(' - ')[0] === 'VSC')
        {
            return (net > 25 ? 1:net/25).toFixed(2)
        }
        else 
        {
            return (net/100).toFixed(2);
        }
    }

    const calculateTotalSpiffs = () => {
        let total = 0
        props.selectedReport.spiffs.map((spiff) => {
            total = total + Number.parseFloat(spiff.amount)
        })
        props.selectedReport.reserve_inputs.map((input) => {
            total = total + Number.parseFloat(input.amount)
        })
        return total;
    }

    const calculateAmounts = () => {
        let flats = props.selectedReport.total_flatee_payout;
        let DPPP = props.selectedReport.dppp_amount;
        let GAP = props.selectedReport.gap_amount;
        let gross = props.selectedReport.contract_gross;
        let totalSpiffs = calculateTotalSpiffs();
        let IS = props.selectedReport.inside_sales ? (gross-DPPP-GAP-totalSpiffs)*0.1:0
        let NET = gross-DPPP-GAP-flats-totalSpiffs-IS
        let SPIFFS = totalSpiffs
        setReportSplits(
        {
            gross: gross,
            dppp: DPPP,
            flats: flats,
            gap: GAP,
            spiffs: SPIFFS,
            insideSales: IS,
            net: NET,
            contractCount: props.selectedReport.total_contract_count * props.selectedReport.quantity
        }
        )
    }

    useEffect(() => {
        // loadAdmins(props.saturdayReports[props.selectedReport].dealership_ID.id)
        // loadContractTypes(props.saturdayReports[props.selectedReport].admin_ID.id)
    },[])

    // const addSpiffee = (data) => {
    //     console.log(data)
    //     data = {
    //         spiffee: {value: data.id, label: data.name},
    //         amount: data.amount,
    //         address: {value: data.address.value, label: data.address.label},
    //         spiff_type: data.spiffType,
    //         ATTN: data.ATTN,
    //         dealership_spiff: data.id === 'dealership_spiff' ? true:false
    //     }
    //     setData((prevState) => ({
    //         ...prevState, // Spread the previous state to maintain other properties
    //         spiffees: [
    //             ...prevState.spiffees, // Spread the existing spiffees
    //             data
    //         ],
    //     }));
    // }

    // const addReserveInput = (data) => {
    //     console.log(data)
    //     data =  {
    //         account: {value: data.id, label: data.account},
    //         amount: data.amount,
    //         reason: data.reason,
    //     }
    //     setData((prevState) => ({
    //         ...prevState, // Spread the previous state to maintain other properties
    //         reserve_inputs: [
    //             ...prevState.reserve_inputs, // Spread the existing spiffees
    //             data
    //         ],
    //     }));
    // }

    // const deleteSpiff = (indexToRemove) => {
    //     setData(prevState => {
    //         // Copy everything from the previous state except for the reserve_inputs array
    //         const newState = {
    //           ...prevState,
    //           spiffees: prevState.spiffees.filter((_, index) => index !== indexToRemove)
    //         };
          
    //         // Return the new state
    //         return newState;
    //     });
    // }

    // const deleteReserveInput = (indexToRemove) => {
    //     setData(prevState => {
    //         // Copy everything from the previous state except for the reserve_inputs array
    //         const newState = {
    //           ...prevState,
    //           reserve_inputs: prevState.reserve_inputs.filter((_, index) => index !== indexToRemove)
    //         };
          
    //         // Return the new state
    //         return newState;
    //     });
    // }

    const processSpifftype = (spiffType) => {
        if (spiffType === '$25 Visa') return 'Visa'
        else if (spiffType === '$50 Visa') return 'Visa'
        else if (spiffType === '$100 Visa') return 'Visa'
        else {return spiffType}
    }

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    return (
        <div style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '1300px', height: 'auto'}}>
            <div style={{display: 'flex', flexDirection: 'row', height: '70px', paddingTop: '10px'}}>
                <span style={{fontWeight: '500', fontSize: '18px'}}>Selected Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px', marginLeft: 'auto'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{width: '100%', height: '110px', marginTop: '20px'}}>
                <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                    <div className='center' style={{width: '100%', height: '100%', borderRight: '1px solid ' + props.colors.border}}>
                        <span style={{fontWeight: '600'}}>Reason</span>
                    </div>
                </div>
                <div style={{width: '100%', height: '58px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                    <div className='center' style={{width: '100%', height: '100%'}}>
                        <span style={{fontWeight: '400'}}>{props.selectedReport?.reason === null ? 'No Reason Provided':props.selectedReport?.reason}</span>
                    </div>
                </div>
            </div>
            <div style={{height: '630px', overflowY: 'auto', marginTop: '20px'}}>
                <div style={{width: '100%', height: '110px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Report Type</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Dealer</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Admin</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Subagent</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', borderLeft: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '600'}}>Contract Type</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '58px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '500'}}>{props.selectedReport.report_type}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '500'}}>{props.selectedReport.dealership_ID.name}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '500'}}>{props.selectedReport.admin_ID.name}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '500'}}>{props.selectedReport.subagent_ID !== null ? (props.selectedReport.subagent_ID.first_name + ' ' + props.selectedReport.subagent_ID.last_name):(props.selectedReport.submitted_by.first_name + ' ' + props.selectedReport.submitted_by.last_name)}</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%'}}>
                        <span style={{fontWeight: '500'}}>{props.selectedReport.contract_type + ' - ' + props.selectedReport.contract_subtype}</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: '110px', marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, borderTopLeftRadius: '10px', display: 'flex', flexDirection: 'column'}}>
                            <span style={{fontWeight: '500'}}>Quantity</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderBottomLeftRadius: '10px'}}>
                            <span style={{fontWeight: '500'}}>{props.selectedReport.quantity}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Gross</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.selectedReport.contract_gross}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Net</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${formatNumber(props.selectedReport.net)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Spiffs</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.selectedReport.total_spiffee_payout}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Flats</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.selectedReport.total_flatee_payout}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>DPPP</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.selectedReport.dppp_amount}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>GAP</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${props.selectedReport.gap_amount}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                            <span style={{fontWeight: '500'}}>Inside Sales</span>
                            <span style={{color: props.colors.muted}}>/ contract</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                            <span>${formatNumber(props.selectedReport.inside_sales_payout)}</span>
                        </div>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px', borderTopRightRadius: '10px'}}>
                            <span style={{fontWeight: '500'}}>Contract Count</span>
                            <span style={{color: props.colors.muted}}>Total</span>
                        </div>
                        <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px', borderBottomRightRadius: '10px'}}>
                            <span>{formatNumber(props.selectedReport.total_contract_count * props.selectedReport.quantity)}</span>
                        </div>
                    </div>
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div className='center' style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '600'}}>Spiffees</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Spiffee</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Amount / Contract</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Shipping Address</span>
                        </div>
                        <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block}}>
                            <span style={{fontWeight: '500'}}>Spiff Type</span>
                        </div>
                    </div>
                    {props.selectedReport.spiffs.map((spiff, index) => {
                        return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{spiff.dealership_spiff ? spiff.dealership_ID.name:spiff.spiffee_ID.name}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>${spiff.amount}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{spiff.address + ', ' +  spiff.city + ', ' + spiff.state_code}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{spiff.spiff_type}</span>
                            </div>
                        </div>
                    })}
                </div>
                <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                    <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                        <div className='center' style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '600'}}>Reserve Inputs</span>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                        <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Account</span>
                        </div>
                        <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                            <span style={{fontWeight: '500'}}>Amount</span>
                        </div>
                    </div>
                    {props.selectedReport.reserve_inputs.map((input, index) => { 
                        return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '50%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>{input.account_ID.name}</span>
                            </div>
                            <div className='center' style={{width: '50%', height: '100%'}}>
                                <span style={{fontWeight: '400'}}>${input.amount}</span>
                            </div>
                        </div>
                    })}
                </div>
            </div>
            <Button onClick={() => props.setIsOpen()} Style={{marginTop: '20px', width: '200px', marginLeft: 'auto'}} active={true} colors={props.colors}>Close</Button>
        </div>
    )
}

export default Main
