import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers2 = [
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' },
        { value: 'Years', label: 'Years' },
    ]

    const [data, setData] = useState({
        date: '',
        duration: '',
        duration_type: '',
    })

    const [dataDisplay, setDataDisplay] = useState({
        date: '',
        duration: '',
        duration_type: '',
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e.value === undefined ? e:e.value
        }))
        setDataDisplay(prevData => ({...prevData, [value]: e.value === undefined ? e:e.label}))
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Late Submission Override</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', marginTop: '0px'}}>Extend By</span>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '5px', marginTop: '5px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <TextInput onChange={(e) => onDataChange(e, 'duration')} colors={props.colors} data={dataDisplay.duration} prefix='' Style={{width: 100, border: 0, borderRight: '1px solid ' + props.colors.border}}></TextInput>
                        <span style={{marginLeft: '40%', margin: 'auto', fontSize: '16px', fontWeight: 'bold'}}>Days</span>
                    </div>
                </div>
                <Button onClick={() => props.submitLateSubmission(data, props.subagents[props.selectedSubagent].id)} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
