import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
    */}

    const defaultStyle = 
    {
        display: 'flex',
        flexDirection: 'row',
        margin: '39px'
    }



    return (
        <motion.div onClick={props.onClick} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <div style={{display: 'flex', flexDirection: 'column', marginRight: '50px'}}>
                <span style={{fontSize: '20px', fontWeight: '500'}}>Account Info</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Username</span>
                <span>{props.data.username}</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Email</span>
                <span>{props.data.email}</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Role</span>
                <span>{props.data.role}</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginRight: '50px'}}>
                <span style={{fontSize: '20px', fontWeight: '500'}}>Address</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Street</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'address')} data={props.data.address}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>City</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'city')} data={props.data.city}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>State</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'state_code')} data={props.data.state_code}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Zip Code</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'zip_code')} data={props.data.zip_code}></TextInput>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', marginRight: '50px'}}>
                <span style={{fontSize: '20px', fontWeight: '500'}}>Personal Info</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>First Name</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'first_name')} data={props.data.first_name}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Last Name</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'last_name')} data={props.data.last_name}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Phone Number</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'phone_number')} data={props.data.phone_number}></TextInput>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Email</span>
                <TextInput colors={props.colors} onChange={(e) => props.onDataChange(e, 'email')} data={props.data.email}></TextInput>
            </div>
            <div style={{display: 'flex', flexDirection: 'column'}}>
                <span style={{fontSize: '20px', fontWeight: '500'}}>General Info</span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Last Password Change: <b>{props.data.last_password_change}</b></span>
                <span style={{fontWeight: '500', marginBottom: '5px', marginTop: '15px'}}>Email Confirm Date: <b>{props.data.email_confirmed_date}</b></span>
                <div className='noSelect' onClick={(e) => props.onDataChange(!props.data.dark_mode, 'dark_mode')} style={{display: 'flex', flexDirection: 'row', marginTop: '15px', marginBottom: '5px', cursor: 'pointer'}}>
                    <span style={{fontWeight: '500', marginRight: '10px'}}>Dark Mode</span>
                    <div style={{cursor: 'pointer', width: '24px', height: '24px', border: props.data.dark_mode ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data.dark_mode === true  ? props.colors.primary:''}}></div>
                </div>
                <div className='noSelect' onClick={(e) => props.onDataChange(!props.data.helpful_tips, 'helpful_tips')} style={{display: 'flex', flexDirection: 'row', marginTop: '15px', marginBottom: '5px', cursor: 'pointer'}}>
                    <span style={{fontWeight: '500', marginRight: '10px'}}>Helpful Popups</span>
                    <div style={{cursor: 'pointer', width: '24px', height: '24px', border: props.data.helpful_tips === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data.helpful_tips === true  ? props.colors.primary:''}}></div>
                </div>
            </div>
        </motion.div>
    )
}

export default Main
