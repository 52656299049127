import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]
    
    const [newSpiffeeData, setNewSpiffeeData] = useState(props.selectedSpiffee)

    const [tempSpiffeeInfo, setTempSpiffeeInfo] = useState(
        props.selectedSpiffee === undefined ? '':
        [
            props.selectedSpiffee.name,
            props.selectedSpiffee.amount,
            props.selectedSpiffee.preSpiff,
            props.selectedSpiffee.address,
            props.selectedSpiffee.ATTN,
            props.selectedSpiffee.spiffType,
            props.selectedSpiffee.id,
        ]
    )

    const onChange = (e, indexParent) => {
        console.log(e, tempSpiffeeInfo)
        if (indexParent === 5)
        {   
            if (e.label === '$25 Visa') {onChange('25', 1)}
            if (e.label === '$50 Visa') {onChange('50', 1)}
            if (e.label === '$100 Visa') {onChange('100', 1)}
        }
        setTempSpiffeeInfo((tempSpiffeeInfo) => tempSpiffeeInfo.map((data, index) => {
            if (index === indexParent)
            {
                if (index === 5)
                {
                    return e.label;
                }
                if (index === 7 && indexParent === 0)
                {
                    //bypass for ID
                    return e.value
                }
                if (index === 3)
                {
                    return e.value
                }
                if (e.label === undefined)
                {
                    return e;
                }
                return e.label;
            } else {return data}
        }))
    }

    const clearData = () => {

    }

    const shippingTypes = [
        { value: 1, label: 'Dealer Address' },
        { value: 2, label: 'Spiffee Address' },
        { value: 3, label: 'Subagent Address' },
    ]

    const [spiffTypes, setSpiffTypes] = useState([
        { value: '100visa', label: '$100 Visa' },
        { value: '50visa', label: '$50 Visa' },
        { value: '25visa', label: '$25 Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Check', label: 'Check' },
    ])


    console.log(props)

    return (
        <div style={{width: '360px', height: '530px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '40%'}}>Edit Spiffee</span>
                <Button onClick={() => {props.setIsOpen(); props.deleteSpiffee(props.spiffeeIndex);}} Style={{flexBasis: '30%', backgroundColor: props.colors.warning, height: '35px'}} active={true} colors={props.colors}>Delete</Button>
                <div style={{flexBasis: '29%'}}></div>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span>Spiffee</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={props.spiffeeList} value={tempSpiffeeInfo[0]}></Dropdown></div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div onClick={(e) => onChange(!tempSpiffeeInfo[2], 2)} style={{width: '19px', height: '19px', borderRadius: '4px', border: tempSpiffeeInfo[2] ? 'none':'1px solid ' + props.colors.border, backgroundColor: tempSpiffeeInfo[2] ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Pre-Spiff Payoff - {newSpiffeeData[2] ? 'True':'False'}</span>
                </div>
                <span style={{marginTop: '15px'}}>Amount / Contract</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 1)} colors={props.colors} data={tempSpiffeeInfo[1]}></TextInput>
                <span style={{marginTop: '15px'}}>Shipping Address</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 3)} index={props.index} indexParent={props.indexParent} data={shippingTypes} value={shippingTypes[(tempSpiffeeInfo[3]-1)].label}></Dropdown></div>
                <span style={{marginTop: '15px'}}>ATTN</span>
                <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 4)} colors={props.colors} data={tempSpiffeeInfo[4]}></TextInput>
                <span style={{marginTop: '15px'}}>Spiff Type</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 5)} index={props.index} indexParent={props.indexParent} data={spiffTypes} value={tempSpiffeeInfo[5]}></Dropdown></div>
                <Button onClick={() => {props.setIsOpen(); props.updateSpiffee(tempSpiffeeInfo, props.spiffeeIndex)}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main