import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

const Main = (props) => {

    const [startDate, setStartDate] = useState(getRecentTuesdayAndUpcomingMonday()[1])
    const [endDate, setEndDate] = useState(getRecentTuesdayAndUpcomingMonday()[0])
    const [year, setYear] = useState(new Date().getFullYear())
    const [mondayWeeks, setMondayWeeks] = useState(getMondaysOfYear(year))
    const [tuesdayWeeks, setTuesdayWeeks] = useState(getTuesdaysOfYear(year))

    function getMondaysOfYear(year) {
        year = Number(year)
        const dates = [];
        let currentDate = new Date(year, 0, 1);
    
        // Adjust the start date to the first Monday of the year
        while (currentDate.getDay() !== 1) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        // Iterate through each Monday of the year
        while (currentDate.getFullYear() === year) {
            const dateString = (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                               currentDate.getDate().toString().padStart(2, '0') + '/' +
                               currentDate.getFullYear().toString().slice(-2);
            dates.push({ value: dateString, label: dateString });
    
            // Move to the next week
            currentDate.setDate(currentDate.getDate() + 7);
        }

        console.log(dates)
    
        return dates;
    }

    function getRecentTuesdayAndUpcomingMonday() {
        const today = new Date();
        const recentTuesday = new Date(today);
        const upcomingMonday = new Date(today);
    
        // Adjust recentTuesday to the most recent Tuesday or today if today is Tuesday
        while (recentTuesday.getDay() !== 2) {
            recentTuesday.setDate(recentTuesday.getDate() - 1);
        }
    
        // Adjust upcomingMonday to the next Monday if today is not Monday, or today if today is Monday
        while (upcomingMonday.getDay() !== 1) {
            upcomingMonday.setDate(upcomingMonday.getDate() + 1);
        }
    
        const formatDate = (date) => {
            return (date.getMonth() + 1).toString().padStart(2, '0') + '/' +
                   date.getDate().toString().padStart(2, '0') + '/' +
                   date.getFullYear().toString().slice(-2);
        };

        console.log([formatDate(upcomingMonday), formatDate(recentTuesday)])
    
        return [formatDate(upcomingMonday), formatDate(recentTuesday)];
    }

    function getTuesdaysOfYear(year) {
        year = Number(year)
        const dates = [];
        let currentDate = new Date(year, 0, 1);
    
        // Adjust the start date to the first Tuesday of the year
        while (currentDate.getDay() !== 2) {
            currentDate.setDate(currentDate.getDate() + 1);
        }
    
        // Iterate through each Tuesday of the year
        while (currentDate.getFullYear() === year) {
            const dateString = (currentDate.getMonth() + 1).toString().padStart(2, '0') + '/' +
                               currentDate.getDate().toString().padStart(2, '0') + '/' +
                               currentDate.getFullYear().toString().slice(-2);
            dates.push({ value: dateString, label: dateString });
    
            // Move to the next week
            currentDate.setDate(currentDate.getDate() + 7);
        }

        console.log(dates)
    
        return dates;
    }

    const getYearsFrom2021ToNow = () => {
        const currentYear = new Date().getFullYear();
        const years = [];
        for (let year = 2021; year <= currentYear; year++) {
          years.push({ value: year.toString(), label: year.toString() });
        }
        return years;
    };

    useEffect(() => {
        console.log(year)
        setMondayWeeks(() => getMondaysOfYear(year))
        setTuesdayWeeks(() => getTuesdaysOfYear(year))
    },[year])

    return (
        <div style={{width: 'auto', height: '450px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Week Selector</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: '500', fontSize: '16px', marginTop: '30px'}}>Select a Year</span>
                <Dropdown colors={props.colors} onChange={(e) => setYear(e.label)} index={props.index} indexParent={props.indexParent} data={getYearsFrom2021ToNow()} width={195} value={year}>Test</Dropdown>
            </div>
            <div style={{flexBasis: '80%', display: 'flex', flexDirection: 'row'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500', fontSize: '16px', marginTop: '30px'}}>Select the Start date</span>
                    <Dropdown colors={props.colors} onChange={(e) => setStartDate(e.label)} index={props.index} indexParent={props.indexParent} data={tuesdayWeeks} width={195} value={startDate}>Test</Dropdown>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '30px'}}>
                    <span style={{fontWeight: '500', fontSize: '16px', marginTop: '30px'}}>Select the End date</span>
                    <Dropdown colors={props.colors} onChange={(e) => setEndDate(e.label)} index={props.index} indexParent={props.indexParent} data={mondayWeeks} width={195} value={endDate}>Test</Dropdown>
                </div>
            </div>
            <Button colors={props.colors} onClick={() => {props.filterDate(new Date(startDate), new Date(endDate)); props.setIsOpen()}} active={true} Style={{width: '200px', marginLeft: 'auto', marginTop: '20px'}}>Save</Button>
        </div>
    )
}

export default Main
