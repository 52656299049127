import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    console.log(props)

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    useEffect(() => {
        console.log(props.dealerAdmins[props.selectedDealer])
        let dpppGapPlus = props.dealerDPPPGAP[props.selectedDealer];
        let admins = props.dealerAdmins[props.selectedDealer]
        let tempTableValues = [
            [],
            [],
            [],
            [],
            [],
            [],
        ]
        let tempTableData = [
            [],
            [],
            [],
            [],
            [],
            [],
        ]
        console.log(dpppGapPlus)
        dpppGapPlus.map((account, index) => {
            tempTableValues[0].push(account.admin_ID)
            tempTableValues[1].push(account.contract_type + ' - ' + account.contract_subtype)
            tempTableValues[2].push(account.DPPP === null ? '':account.DPPP)
            tempTableValues[3].push(account.GAP === null ? '':account.GAP)
            tempTableValues[4].push(account.last_edited)
            tempTableValues[5].push(1)

            tempTableData[0].push(account.admin_ID)
            tempTableData[1].push({type: account.contract_type, subType: account.contract_subtype})
            tempTableData[2].push(account.DPPP)
            tempTableData[3].push(account.GAP)
            tempTableData[4].push(account.last_edited)
            tempTableData[5].push(1)

            loadContractTypes(account.admin_ID, index)
        })

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(tempTableValues[0])
        };

        props.tokenSafeAPIRequest('/admin/bulk', requestOptions, '', (result, status) => {
            console.log(result)
            console.log(tempTableValues)

            tempTableValues[0] = result;
    
            setSpliteeTableValues(tempTableValues)
            setTableData(tempTableData)
    
            let tempAdmins = []
            admins.map((admin, index) => {
                tempAdmins.push({value: admin.id, label: admin.name})
            })
    
            setSpliteeTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Admin')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.indexParent} indexParent={props.index} data={tempAdmins} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                        };
                    }
                    return title;
                })
            })
        })
    },[])

    const findAdminName = (adminID, adminList, index2) => {
        let foundName = ''
        props.dealerAdmins[props.selectedDealer].map((admin, index) => {
            console.log(admin, adminID)
            if (admin.id === adminID)
            {
                console.log('Matched!')
                loadContractTypes(adminID, index2)
                foundName = admin.name;
            }
        })
        return foundName;
    }

    const formatDate = () => {
        const currentDate = new Date();

        // Extracting individual components
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + 1; // getMonth() returns 0-11
        const day = currentDate.getDate();

        // Adding leading zeros if day or month is less than 10
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;

        // Formatting the date in YYYY-MM-DD format
        return `${year}-${formattedMonth}-${formattedDay}`;
    }

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={(e) => props.onChange(e, props.indexParent, props.index)} index={props.index} indexParent={props.indexParent} data={dealers2} width={250} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Contract',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={(e) => props.onChange(e, props.indexParent, props.index)} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'DPPP',
            displayElement: (props) => (<TextInput colors={props.colors} onChange={(e) => props.onChange(e, props.indexParent, props.index)} prefix='$' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: '',
        },
        {
            title: 'GAP Plus',
            displayElement: (props) => (<TextInput colors={props.colors} onChange={(e) => props.onChange(e, props.indexParent, props.index)} prefix='$' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Last Updated',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: formatDate(),
        },
        {
            title: 'Remove',
            displayElement: (props) => (<IconButton image={Delete} onClick={() => deleteRow(props.index, props.tableData)} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: '',
        },
    ])

    const [spliteeTableValues, setSpliteeTableValues] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
        [spliteeTableTitles[3].default], 
        [spliteeTableTitles[4].default], 
        [spliteeTableTitles[5].default], 
    ])

    const [tableData, setTableData] = useState([
        [spliteeTableTitles[0].default],
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default],
        [spliteeTableTitles[3].default],
        [spliteeTableTitles[4].default],
        [spliteeTableTitles[5].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]],
        [[]],
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])
    const [deletedAccountsInfo, setDeletedAccountsInfo] = useState([]);

    const onTableChange = (e,x,y) => {
        console.log('Table Updated', e, x, y)
        if (x === 0)
        {
            loadContractTypes(e.value, y)
        }
        setSpliteeTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const addRow = () => {
        console.log('Add Row!')
        setSpliteeTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const [deleted, setDeleted] = useState(0)

    const deleteRow = (newIndex, currentTableData) => {

        console.log(currentTableData)

        setDeletedAccountsInfo((deletedAccountsInfo) => [...deletedAccountsInfo, currentTableData[2][newIndex]])

        setSpliteeTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const loadContractTypes = (admin, y) => {

        console.log('Loading Contract Types @ ' + y)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + admin, requestOptions, tokenValues, (result) => {
            let tempContractTypeList = []
            result.VSC_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
            })
            result.ancillary_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[1]]
                newSubArray[y] = tempContractTypeList;
                newData[1] = newSubArray;
                return newData
            })
        })
    }

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit DPPPs / GAP Plus</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={spliteeTableValues} tableInfo={spliteeTableTitles} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false}></Table>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button onClick={addRow} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                    <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add New Contract</span>
                </div>
                <Button onClick={() => {props.editDPPPGAP(tableData, deletedAccountsInfo);}} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
