import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import CBA from '../media/images/CBALogo.png'

import TextInput from '../elements/TextInput'
import Button from '../elements/Button'

import Login_Graphic from '../media/images/Login_Graphic.png'

import Notification from '../elements/Notification'

const Main = (props) => {

    const [email, setEmail] = useState('')

    const submitForgetPassword = (inputEmail) => {
        const formData = new URLSearchParams();
        formData.append("email", inputEmail);

        console.log(formData)

        fetch(props.baseURL + '/auth/forgot_password', {
            method: "POST", 
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            },
            body: JSON.stringify({email: inputEmail})
        })
        .then(res => res.json())
        .then(json => {
            console.log(json)
            setNotification(props.getServerResponse(json))
        })
    }

    const [notification, setNotification] = useState('')

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor}}>

            <Notification colors={props.colors} message={notification}></Notification>

            <div style={{width: '450px', height: '80%', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
                <Link to='/login'><img src={CBA} style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '100px'}} width="305px"></img></Link>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px', textAlign: 'center'}}>Forgot Password</span>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px'}}>Email</span>
                <TextInput colors={props.colors} onChange={(e) => setEmail(e)} data={email}></TextInput>
                <Button onClick={() => submitForgetPassword(email)} colors={props.colors} active={true} Style={{marginTop: '20px'}}>Reset Password</Button>
            </div>
            <img src={Login_Graphic} style={{height: '20%'}}></img>
        </div>
    )
}

export default Main
