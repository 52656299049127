import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/commissionreceivedos/ManageTemplates.jsx'
import SaveTemplate from '../assets/commissionreceivedos/SaveTemplate.jsx'

import ParentSelector from '../elements/ParentSelector.jsx'
import DeleteReport from '../assets/DeleteConfirm.jsx'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const buttonList = [
        {
            name: 'New Report',
            link: '',
            onClick: () => {addRow()},
        },
        {
            name: 'Submit Selected Reports',
            link: '',
            onClick: () => {submitSelectedReports()},
        },
        {
            name: 'Manage Templates',
            link: '',
            onClick: () => {setManageTemplateModal(true)},
        },
        {
            name: 'Pending Reports',
            link: '/reports/commissionreceivedospending',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const [selectedReport, setSelectedReport] = useState(-1)

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<Counter allowNegative={false} onChange={props.onChange} indexParent={props.indexParent} colors={props.colors} index={props.index}>{props.data}</Counter>),
            default: 0,
        },
        {
            title: 'Template',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={templates} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Gross / Contract',
            displayElement: (props) => (<TextInput prefix='$' onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: 0,
        },
        {
            title: 'Edit',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => {setManageSaveTemplateModal(true); setSelectedReport(props.index)}} Style={{backgroundColor: props.colors.primary, marginLeft: '10px'}} size={27} image={Save} colors={props.colors}></IconButton>
                    <IconButton onClick={() => deleteRow(props.index)} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={27} image={Delete} colors={props.colors}></IconButton>
                </div>),
            default: undefined,
        },
    ])

    const [templates, setTemplates] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
    }

    const deleteRow = (index) => {
        console.log('Deleted Row: ' + index)
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, index), ...data.slice(index + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e,y,x)
        if (x === 1) //check what column the change was in, 1 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e.label)
                {
                    applyTemplate(template, y)
                }
            })
        }
        if (x === 2)
        {
            console.log(e, x, y)
            loadAdminsSubagent(e.value, y) //loads admins for dropdown and sets subagent
            onTableChange({value: '', label: ''}, y, 4)
            onTableChange({value: '', label: ''}, y, 5)
        }
        if (x === 4)
        {
            loadContractTypes(e.value, y)
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const applyTemplate = (template, row) => {
        console.log(template)
        setTableValues((tableValues) => tableValues.map((column, indexX) => {
            if (indexX === 2) {column[row] = template.dealership_ID.name}
            if (indexX === 3) {column[row] = template.subagent_ID.name}
            if (indexX === 4) {column[row] = template.admin_ID.name}
            if (indexX === 5) {column[row] = template.contract_type}
            if (indexX === 6) {column[row] = template.gross_per_contract}
            return column;
        }))
        setTableData((tableData) => tableData.map((column, indexX) => {
            loadAdminsSubagent(template.dealership_ID.id, row)
            loadContractTypes(template.admin_ID.id, row)
            if (indexX === 2) {column[row] = template.dealership_ID.id}
            if (indexX === 3) {column[row] = template.subagent_ID.id}
            if (indexX === 4) {column[row] = template.admin_ID.id}
            if (indexX === 5) {column[row] = {type: template.contract_type.split(' - ')[0], subType: template.contract_type.split(' - ')[1]}}
            if (indexX === 6) {column[row] = template.gross_per_contract}
            return column;
        }))
    }

    const submitSelectedReports = () => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(
            transposeArray(tableData)
                .filter((_, index) => rowSelected[index]) // Filter out unselected rows
                .map((report) => { console.log(report)
                    return {
                        "admin_ID": report[4],
                        "dealership_ID": report[2],
                        "contract_type": report[5].type,
                        "contract_subtype": report[5].subType,
                        "quantity": report[0],
                        "subagent_ID": report[3],
                        "date": new Date(),
                        "contract_gross": report[6],
                    };
                })
        );

        console.log(raw)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: raw
        };

          props.tokenSafeAPIRequest('/report/thursday_report', requestOptions, tokenValues, (result, status) => {
            console.log(result, status)
            if (status === 200)
            {
                setNotification('Submitted Successful: ' + getCurrentTimeFormatted())
                let deleted = 0;
                rowSelected.map((selected, index) => {
                    if (selected)
                    {
                        deleteRow(index - deleted);
                        deleted++;
                    }
                })
            }
            else
            {
                setNotification(props.getServerResponse(result))
            }
        })
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [rowSelected, setRowSelected] = useState([false])

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Template
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross/Contract
        [tableTitles[6].default], //Edit Buttons (Empty as they don't need data)
        [tableTitles[7].default], //Edit Buttons (Empty as they don't need data)
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
        [[]],
        [[]],
        [[]],
    ])

    const showTableData = () => {
        console.log(tableData)
    }

    const [parentSelectedItem, setParentSelectedItem] = useState(-1)
    const [deleteReportModal, setDeleteReportModal] = useState(false)
    const [allDealers, setAllDealers] = useState([])
    const [allSubagents, setAllSubagents] = useState([])
 
    const [cookies, setCookies] = useCookies([])

    const loadSubagentsList = (dealerInfo, y) => {
        console.log(dealerInfo)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/dealership/' + dealerInfo.id + '?fetch_links=true', requestOptions, '', (result) => {
            if (dealerInfo.id === '')
            {

            }
            else
            {
                console.log(result)
                let tempSubagentList = []
                result.user_connections.map((agent, index) => {
                    tempSubagentList.push({value: agent.user_ID.id, label: agent.user_ID.first_name + ' ' + agent.user_ID.last_name})
                })
                tempSubagentList.push({value: '659742ce7c605a59716c4551', label: 'CBA Old Agents'})
                setAllSubagents(tempSubagentList)
    
                setDropdownData((dropdownData, index) => {
                    const newData = [...dropdownData]
                    const newSubArray = [...newData[3]]
                    newSubArray[y] = tempSubagentList;
                    newData[3] = newSubArray;
                    return newData
                })
            }
        })
    }

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        };
    
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name + (dealer.state_code === null ? '':' - ' + dealer.state_code)})
            })
            setAllDealers(tempDealerList)
            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Dealer')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempDealerList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Commission'})
        };

        props.tokenSafeAPIRequest('/report/template/search?limit=100', requestOptions, tokenValues, (result) => {
            console.log(result)
            setTemplates(result)

            let tempTemplatesList = []
            result.map((template, index) => {
                tempTemplatesList.push({value: template.id, label: template.name})
            })

            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Template')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempTemplatesList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    },[])

    const loadContractTypes = (admin, y) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + admin, requestOptions, tokenValues, (result) => {
            if (admin === '')
            {

            }
            else
            {
                let tempContractTypeList = []
                result.VSC_contracts.map((contractType, index) => {
                    tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
                })
                result.ancillary_contracts.map((contractType, index) => {
                    tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
                })
    
                setDropdownData((dropdownData, index) => {
                    const newData = [...dropdownData]
                    const newSubArray = [...newData[5]]
                    newSubArray[y] = tempContractTypeList;
                    newData[5] = newSubArray;
                    return newData
                })
            }
        })
    }

    const loadAdminsSubagent = (dealer, y) => {

        console.log(dealer, y)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + dealer + '?fetch_links=True', requestOptions, tokenValues, (result) => {
            if (dealer === '')
            {

            }
            else
            {
                console.log(result)
                let tempAdminList = []
                result.admin_IDs.map((admin, index) => {
                    tempAdminList.push({value: admin.id, label: admin.name})
                })
    
                onTableChange({value: result.subagent_owner_ID.id, label: result.subagent_owner_ID.first_name + ' ' + result.subagent_owner_ID.last_name}, y, 3) //e,y,x
    
                loadSubagentsList(result, y)
    
                setDropdownData((dropdownData, index) => {
                    const newData = [...dropdownData]
                    const newSubArray = [...newData[4]]
                    newSubArray[y] = tempAdminList;
                    newData[4] = newSubArray;
                    return newData
                })
            }
        })
    }

    function swapIdToValueInAllObjects(array) {
        array.forEach(obj => {
          if (obj.hasOwnProperty('id')) {
            obj.value = obj.id;
            delete obj.id;
          }
          if (obj.hasOwnProperty('name')) {
            obj.label = obj.name;
            delete obj.name;
          }
        });
      }

    const [notification, setNotification] = useState('')

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const quickSaveTemplate = (template, tableValues) => {
        console.log(template, tableValues)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let newTemplates = []

        newTemplates.push({
            name: template[0],
            template_type: 'Commission',
            dealership_ID: {name: tableValues[2], id: template[3]},
            admin_ID: {name: tableValues[4], id: template[5]},
            contract_type: template[6].type + ' - ' + template[6].subType,
            gross_per_contract: template[7],
            subagent_ID: {name: tableValues[3], id: template[4]}
        })

        console.log(newTemplates)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newTemplates)
        };

        props.tokenSafeAPIRequest('/report/template/import', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Save Templated Successfully at: ' + getCurrentTimeFormatted())
                loadNewTemplates()
                setManageTemplateModal(false)
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const saveTemplates = (tableData, tableValues, deletedTemplates) => {
        let existingTemplates = []
        let newTemplates = []

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let newTableValues = transposeArray(tableValues)
        let newTableData = transposeArray(tableData)
        console.log(newTableValues, newTableData)

        let data = []
        transposeArray(tableData).map((row, index) => {
            if (row[6] === undefined)
            {
                newTemplates.push({
                    name: row[0],
                    template_type: 'Commission',
                    dealership_ID: {name: newTableValues[index][1], id: row[1]},
                    admin_ID: {name: newTableValues[index][3], id: row[3]},
                    contract_type: row[4] === undefined ? '':row[4].type + ' - ' + row[4].subType,
                    gross_per_contract: row[5],
                    subagent_ID: {name: newTableValues[index][2], id: row[2]}
                })
            }
            else
            {
                existingTemplates.push({
                    name: row[0],
                    template_type: 'Commission',
                    dealership_ID: {name: newTableValues[index][1], id: row[1]},
                    admin_ID: {name: newTableValues[index][3], id: row[3]},
                    contract_type: row[4],
                    gross_per_contract: row[5],
                    subagent_ID: {name: newTableValues[index][2], id: row[2]},
                    id: row[6]
                })
            }
        })

        console.log(newTemplates)
        console.log(existingTemplates)

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newTemplates)
        };

        props.tokenSafeAPIRequest('/report/template/import', requestOptions, tokenValues, (result, status) => {
            console.log(result)

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(existingTemplates)
            };

            props.tokenSafeAPIRequest('/report/template/bulk', requestOptions, tokenValues, (result2, status2) => {
                console.log(result2)
                
                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(deletedTemplates)
                };
                
                props.tokenSafeAPIRequest('/report/template/bulk', requestOptions, tokenValues, (result3, status3) => {
                    console.log(result3)
                    console.log(status, status2, status3)
                    if (status === 200 || status2 === 200 || status3 === 200)
                    {
                        setNotification('Save Templated Successfully at: ' + getCurrentTimeFormatted())
                        loadNewTemplates()
                        setManageTemplateModal(false)
                    } else {setNotification(props.getServerResponse(result))}
                })
            })
        })
    }

    const loadNewTemplates = () => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Commission'})
        };

        props.tokenSafeAPIRequest('/report/template/search?limit=100', requestOptions, '', (result) => {
            console.log(result)
            setTemplates(result)

            let tempTemplatesList = []
            result.map((template, index) => {
                tempTemplatesList.push({value: template.id, label: template.name})
            })

            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Template')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempTemplatesList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })
    }

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Commission Reports (OS)"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div></div>{/* <ParentSelector colors={props.colors} parentSelectedItem={parentSelectedItem} setParentSelectedItem={setParentSelectedItem} title={'Admins'} array={parentSelectorArray}></ParentSelector> */}
            <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange}></Table>
            <ModalManager colors={props.colors}>
                <ManageTemplate colors={props.colors} saveTemplates={saveTemplates} templates={templates} isOpen={manageTemplateModal} allDealers={allDealers} setIsOpen={() => setManageTemplateModal(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageTemplate>
                <SaveTemplate colors={props.colors} isOpen={manageSaveTemplateModal} quickSaveTemplate={quickSaveTemplate} tableData={tableData} tableValues={tableValues} selectedReport={selectedReport} setIsOpen={() => setManageSaveTemplateModal(false)}></SaveTemplate>
                <DeleteReport colors={props.colors} isOpen={deleteReportModal} setIsOpen={() => setDeleteReportModal(false)}></DeleteReport>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
