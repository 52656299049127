import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {
    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    console.log(props.tableValues)

    let tempDisplayStorage = transposeArray(props.tableValues)[props.selectedDealer]
    let tempDataStorage = transposeArray(props.tableData)[props.selectedDealer]

    console.log(tempDisplayStorage, tempDataStorage)

    const [data, setData] = useState({
        name: tempDataStorage[2],
        subagent: tempDataStorage[3],
        code: tempDataStorage[1],
        signedIncentiveForm: tempDataStorage[7],
        FIVisit: tempDataStorage[0],
        franchise: tempDataStorage[8],
        insideSales: tempDataStorage[6],
        DBAName: '',
    })

    const [displayData, setDisplayData] = useState({
        name: tempDisplayStorage[2],
        subagent: tempDisplayStorage[3],
        code: tempDisplayStorage[1],
        signedIncentiveForm: tempDisplayStorage[7],
        FIVisit: tempDisplayStorage[0],
        franchise: tempDisplayStorage[8],
        insideSales: tempDisplayStorage[6],
        DBAName: '',
    })

    const onDataChange = (e, value) => {
        console.log(e, value)
        if (value === 'subagent')
        {
            console.log('trying to change a subagent I see!')
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value
            }))
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    const [agentList, setAgentList] = useState([])

    const [cookies, setCookies] = useCookies([])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var raw = JSON.stringify(
            {subagent: true}
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        props.tokenSafeAPIRequest('/user/search?limit=9999999', requestOptions, tokenValues, (result) => {
            let dealers = []
            result.map((agent, index) => {
                dealers.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
            setAgentList(dealers)
        })
    },[])

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Dealer</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            {/* <div style={{flexBasis: '15%', display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                <IconButton onClick={() => props.onDelete()} colors={props.colors} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Dealer</span>
            </div> */}
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'name')} colors={props.colors} data={displayData.name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>DBA Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'DBAName')}colors={props.colors} data={displayData.DBAName} prefix=''></TextInput>
                </div>
                {/* <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Subagent Owner</span>
                    <Dropdown onChange={(e) => onDataChange(e, 'subagent')} colors={props.colors} data={agentList} value={displayData.subagent}></Dropdown>
                </div> */}
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <div onClick={() => onDataChange(!displayData.signedIncentiveForm, 'signedIncentiveForm')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.signedIncentiveForm === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.signedIncentiveForm === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Signed Incentive Form</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.FIVisit, 'FIVisit')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.FIVisit === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.FIVisit === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>F&I Trainer Visit</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.franchise, 'franchise')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.franchise === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.franchise === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Franchise Dealer</span>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div onClick={() => onDataChange(!displayData.insideSales, 'insideSales')} style={{cursor: 'pointer', width: '20px', height: '20px', border: displayData.insideSales === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: displayData.insideSales === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Inside Sales</span>
                </div>
                <Button onClick={(e) => props.onSubmit({...data, name: displayData.name})} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
