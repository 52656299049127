import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../../elements/UserTab.jsx'
import Button from '../../elements/Button.jsx'

import CommissionReceivedImage from '../../media/icons/icons8-open-email-96.png'
import Agent from '../../media/icons/icons8-agent-96.png'
import OpenEnvelope from '../../media/icons/icons8-open-envelope-96.png'
import Adjustments from '../../media/icons/icons8-adjustment-96.png'
import BarChart from '../../media/icons/icons8-bar-chart-96.png'
import Customer from '../../media/icons/icons8-customer-96.png'
import NotificationIcon from '../../media/icons/icons8-notification-96-2.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    function timeAgo(currentTimeUTC, timestamp) {
        // Parse both dates in UTC
        const date1 = new Date(currentTimeUTC);
        
        // If `notice.timestamp` is not already in UTC format (with 'Z' at the end), ensure to parse it correctly
        // Assuming `timestamp` is already a proper UTC date string:
        const date2 = new Date(timestamp.includes('Z') ? timestamp : `${timestamp}Z`);
    
        console.log('Current UTC time:', date1);
        console.log('Timestamp in UTC:', date2);
    
        const millisecondsPerMinute = 60 * 1000;
        const millisecondsPerHour = millisecondsPerMinute * 60;
        const millisecondsPerDay = millisecondsPerHour * 24;
    
        // Calculate the difference in milliseconds
        const difference = Math.abs(date1.getTime() - date2.getTime());
    
        // Format the date if it's older than 1 day
        if (difference > millisecondsPerDay) {
            return date2.toLocaleDateString('en-GB', { timeZone: 'UTC' });
        }
    
        // Calculate and format the difference if it's less than a day
        const days = Math.floor(difference / millisecondsPerDay);
        const hours = Math.floor((difference % millisecondsPerDay) / millisecondsPerHour);
        const minutes = Math.floor((difference % millisecondsPerHour) / millisecondsPerMinute);
    
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    }

    return (
        <div style={{width: '450px', height: '100%', display: 'flex', flexDirection: 'column', padding: '20px', paddingLeft: '30px', paddingRight: '30px'}}>
            <span style={{fontWeight: '500', fontSize: '20px'}}>Notifications</span>

            {props.notifications === '' ? '':props.notifications.slice().reverse().map((notice, index) => {
                return <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <div style={{backgroundColor: props.colors.primary, height: 'fit-content', borderRadius: '100px', padding: '5px', maxWidth: '31px', maxHeight: '31px'}}>
                        <img width="40px" style={{padding: '0px', minWidth: '20px', minHeight: '20px'}} src={NotificationIcon}></img>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', marginLeft: '10px'}}>
                        <span style={{fontWeight: '600'}}>{notice.title}</span>
                        <span style={{fontSize: '14px', marginTop: '3px', fontWeight: '500'}}>{notice.description}</span>
                        <span style={{fontSize: '14px', fontWeight: '500', color: props.colors.muted, marginTop: '3px'}}>{timeAgo(new Date().toISOString(), notice.timestamp)}</span>
                    </div>
                </div>
            })}

            {props.notifications.length === 0 ? 
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '0px'}}>
                    <span style={{fontSize: '14px', marginTop: '3px', fontWeight: '500'}}>No Notifications</span>
                </div>:''}

        </div>
    )
}

export default Main
