import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const [selectedReport, setSelectedReport] = useState('')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/full/' + props.selectedReport.report_code, requestOptions, '', (result, status) => {
            console.log(result)
            setSelectedReport(result)
        })
    },[])

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    console.log(props)

    return (
        <div style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '1300px', height: 'auto'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Input</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            {selectedReport === '' ? '':<div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <div style={{height: '630px', overflowY: 'auto'}}>
                    <div style={{width: '100%', height: '110px'}}>
                        <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '600'}}>Report Type</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '600'}}>Dealer</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '600'}}>Admin</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', borderRight: '1px solid ' + props.colors.border, borderLeft: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '600'}}>Subagent</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', borderLeft: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '600'}}>Contract Type</span>
                            </div>
                        </div>
                        <div style={{width: '100%', height: '58px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '500'}}>{selectedReport.report_type}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '500'}}>{selectedReport.dealership_ID.name}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '500'}}>{selectedReport.admin_ID.name}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                                <span style={{fontWeight: '500'}}>{selectedReport.subagent_ID !== null ? (selectedReport.subagent_ID.first_name + ' ' + selectedReport.subagent_ID.last_name):(selectedReport.submitted_by.first_name + ' ' + selectedReport.submitted_by.last_name)}</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%'}}>
                            <span style={{fontWeight: '500'}}>{selectedReport.contract_type + ' - ' + selectedReport.contract_subtype}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', height: '110px', marginTop: '20px', display: 'flex', flexDirection: 'row'}}>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, borderTopLeftRadius: '10px', display: 'flex', flexDirection: 'column'}}>
                                <span style={{fontWeight: '500'}}>Quantity</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderBottomLeftRadius: '10px'}}>
                                <span style={{fontWeight: '500'}}>{selectedReport.quantity}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>Gross</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${selectedReport.contract_gross}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>Net</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${formatNumber(selectedReport.net)}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>Spiffs</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${selectedReport.total_spiffee_payout}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>Flats</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${selectedReport.total_flatee_payout}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>DPPP</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${selectedReport.dppp_amount}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>GAP</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${selectedReport.gap_amount}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px'}}>
                                <span style={{fontWeight: '500'}}>Inside Sales</span>
                                <span style={{color: props.colors.muted}}>/ contract</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px'}}>
                                <span>${formatNumber(selectedReport.inside_sales_payout)}</span>
                            </div>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                            <div className='center' style={{height: '49px', backgroundColor: props.colors.block, border: '1px solid '+ props.colors.border, display: 'flex', flexDirection: 'column', borderLeft: '0px', borderTopRightRadius: '10px'}}>
                                <span style={{fontWeight: '500'}}>Contract Count</span>
                                <span style={{color: props.colors.muted}}>Total</span>
                            </div>
                            <div className='center' style={{height: '58px', border: '1px solid '+ props.colors.border, width: '100%', borderLeft: '0px', borderBottomRightRadius: '10px'}}>
                                <span>{formatNumber(selectedReport.total_contract_count * selectedReport.quantity)}</span>
                            </div>
                        </div>
                    </div>
                    <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                        <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <div className='center' style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600'}}>Spiffees</span>
                            </div>
                        </div>
                        <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '500'}}>Spiffee</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '500'}}>Amount / Contract</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '500'}}>Shipping Address</span>
                            </div>
                            <div className='center' style={{width: '25%', height: '100%', backgroundColor: props.colors.block}}>
                                <span style={{fontWeight: '500'}}>Spiff Type</span>
                            </div>
                        </div>
                        {selectedReport.spiffs.map((spiff, index) => {
                            return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                                <div className='center' style={{width: '25%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>{spiff.dealership_spiff ? spiff.dealership_ID.name:spiff.spiffee_ID.name}</span>
                                </div>
                                <div className='center' style={{width: '25%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>${spiff.amount}</span>
                                </div>
                                <div className='center' style={{width: '25%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>{spiff.address + ', ' +  spiff.city + ', ' + spiff.state_code}</span>
                                </div>
                                <div className='center' style={{width: '25%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>{spiff.spiff_type}</span>
                                </div>
                            </div>
                        })}
                    </div>
                    <div style={{width: '100%', height: 'auto', marginTop: '20px'}}>
                        <div style={{width: '100%', height: '49px', backgroundColor: props.colors.block, borderTopRightRadius: '10px', borderTopLeftRadius: '10px', borderLeft: '1px solid ' + props.colors.border, borderTop: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                            <div className='center' style={{height: '100%', display: 'flex', flexDirection: 'row'}}>
                                <span style={{fontWeight: '600'}}>Reserve Inputs</span>
                            </div>
                        </div>
                        <div style={{width: '100%', height: '40px', borderBottomRightRadius: '10px', borderBottomLeftRadius: '10px', border: '1px solid ' + props.colors.border, display: 'flex', flexDirection: 'row'}}>
                            <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '500'}}>Account</span>
                            </div>
                            <div className='center' style={{width: '50%', height: '100%', backgroundColor: props.colors.block, borderRight: '1px solid ' + props.colors.border}}>
                                <span style={{fontWeight: '500'}}>Amount</span>
                            </div>
                        </div>
                        {selectedReport.reserve_inputs.map((input, index) => { 
                            return <div style={{width: '100%', height: '58px', border: '1px solid ' + props.colors.border, borderTop: '0px', display: 'flex', flexDirection: 'row'}}>
                                <div className='center' style={{width: '50%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>{input.account_ID.name}</span>
                                </div>
                                <div className='center' style={{width: '50%', height: '100%'}}>
                                    <span style={{fontWeight: '400'}}>${input.amount}</span>
                                </div>
                            </div>
                        })}
                    </div>
                </div>
                <Button onClick={() => props.setIsOpen()} Style={{marginTop: '20px', width: '200px', marginLeft: 'auto'}} active={true} colors={props.colors}>Close</Button>
            </div>}
        </div>
    )
}

export default Main
