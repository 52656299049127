import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton';
import Table from '../../elements/Table'
import Dropdown from '../../elements/ReactSelectDropdown'
import Counter from '../../elements/Counter'
import TextInput from '../../elements/TextInput'

import Delete from '../../media/icons/icons8-delete-96.png'
import Add from '../../media/icons/icons8-add-96.png'

import Button from '../../elements/Button'

import ModalManager from '../../elements/ModalManager'
import AddTemplateSpiffeeModal from '../saturdayreporting/AddTemplateSpiffeeModal'
import AddTemplateReserveInputsModal from '../saturdayreporting/AddTemplateReserveInputsModal'
import Notification from '../../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [addSpiffeeModal, setAddSpiffeeModal] = useState(false)
    const [addReserveInputModal, setAddReserveInputModal] = useState(false)

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Manage Template</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '5%', marginTop: '10px'}}>
                <span style={{marginTop: '4px', fontWeight: '500', fontSize: '18px'}}>Select a dealership to add to edit a template</span>
            </div>
            <div style={{flexBasis: '20%', display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500'}}>Dealer</span>
                    <Dropdown colors={props.colors} onChange={(e) => props.updateDealer(e)} index={props.index} indexParent={props.indexParent} data={props.allDealers} width={195} value={props.data.dealer.name}>Test</Dropdown>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                    <span style={{fontWeight: '500'}}>Dealer Templates</span>
                    <Dropdown colors={props.colors} onChange={(e) => props.selectTemplate(e)} index={props.index} indexParent={props.indexParent} data={props.dealerTemplatesList} width={195} value={props.data.template.name}>Test</Dropdown>
                </div>
                <div style={{marginLeft: '20px', display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontWeight: '500'}}>Template Name</span>
                    <TextInput Style={{height: '38px', width: '200px'}} onChange={(e) => props.onChange(e, 'templateName')} colors={props.colors} data={props.data.templateName}></TextInput>
                </div>
                <Button onClick={() => {props.saveTemplate(props.data);}} colors={props.colors} active={true} Style={{float: 'right', width: 200, height: '35px', marginTop: '25px', marginLeft: '20px'}}>Save Template</Button>
                <Button onClick={() => {props.deleteTemplate(props.data);}} colors={props.colors} Style={{float: 'right', width: 200, height: '35px', marginTop: '25px', marginLeft: '20px', backgroundColor: props.colors.warning, color: props.colors.block}}>Delete Template</Button>
            </div>
            <div style={{flexBasis: '65%', display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <span onClick={() => console.log(props.data)} style={{marginTop: '5px', fontWeight: '500'}}>Spiffees</span>
                    <Button onClick={() => setAddSpiffeeModal(true)} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                </div>
                <div className='noSelect' style={{display: 'flex', flexDirection: 'row'}}>
                    {props.data.spiffees.map((spiffee, index) => { console.log(spiffee)
                        return <span onClick={() => props.deleteSpiffee(index)} style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, padding: '0px 5px 0px 5px', borderRadius: '3px', margin: '5px', cursor: 'pointer'}}>{spiffee.spiffee.name} | ${spiffee.amount_per_contract}</span>
                    })}
                </div> 
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px', fontWeight: '500'}}>
                    <span style={{marginTop: '5px'}}>Reserve Inputs</span>
                    <Button onClick={() => setAddReserveInputModal(true)} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                </div>
                <div className='noSelect' style={{display: 'flex', flexDirection: 'row'}}>
                    {props.data.reserveInputs.map((input, index) => { console.log(input)
                        return <span onClick={() => props.deleteReserveInput(index)} style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, padding: '0px 5px 0px 5px', borderRadius: '3px', margin: '5px', cursor: 'pointer'}}>{input.account.name} | ${input.amount}</span>
                    })}
                </div> 
            </div>
            <div style={{flexBasis: '5%'}}>
                <Button onClick={() => props.setIsOpen()} colors={props.colors} active={true} Style={{float: 'right', width: 200, height: '35px'}}>Close</Button>
            </div>
            <ModalManager colors={props.colors}>
                <AddTemplateSpiffeeModal colors={props.colors} isOpen={addSpiffeeModal} selectedDealer={props.data.dealer} setIsOpen={() => setAddSpiffeeModal(false)} addSpiffee={props.addSpiffee} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></AddTemplateSpiffeeModal>
                <AddTemplateReserveInputsModal colors={props.colors} isOpen={addReserveInputModal} selectedDealer={props.data.dealer} setIsOpen={() => setAddReserveInputModal(false)} addReserveAccount={props.addReserveInput} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></AddTemplateReserveInputsModal>
            </ModalManager>
        </div>
    )
}

export default Main
