import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Counter from '../../elements/Counter.jsx'
import IconButton from '../../elements/IconButton.jsx'

import Dropdown from '../../elements/ReactSelectDropdown.jsx'
import Button from '../../elements/Button.jsx'
import TextInput from '../../elements/TextInput.jsx'

import Save from '../../media/icons/icons8-save-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [shippingTypes, setShippingTypes] = useState([
        { value: 1, label: 'Dealer Address' },
        { value: 2, label: 'Spiffee Address' },
        { value: 3, label: 'Subagent Address' },
    ])

    //these values dont matter for visas bc i did a bandaid fix to determine what the values is for visa via a function when sending the data to the backend submitReport()
    const [spiffTypes, setSpiffTypes] = useState([
        { value: 'Visa', label: '$100 Visa' },
        { value: 'Visa', label: '$50 Visa' },
        { value: 'Visa', label: '$25 Visa' },
        { value: 'Amex', label: 'Amex' },
        { value: 'Check', label: 'Check' },
    ])

    const [cookies, setCookies] = useCookies([])

    console.log(props)

    return (
        <div style={{borderRight: '1px solid ' + props.colors.border, paddingLeft: '20px', paddingRight: '20px', paddingTop: '10px', paddingBottom: '10px', display: 'flex', flexDirection: 'column', width: '891px'}}>
            <div style={{display: 'flex', flexDirection: 'row', height: '70px', paddingTop: '10px'}}>
                <span style={{fontWeight: '500', fontSize: '20px'}}>REPORT 1</span>
                <IconButton onClick={() => props.clearReport()} img={false} Style={{border: '0', marginLeft: '15px', backgroundColor: props.colors.background}} colors={props.colors}>
                    <span style={{backgroundColor: props.colors.primary, color: props.colors.block, padding: '5px', borderRadius: '100px', paddingLeft: '11px', paddingRight: '11px'}}>X</span>
                </IconButton>
                <Counter onChange={(e) => props.onDataChange(e, 'quantity')} allowNegative={false} colors={props.colors} Style={{height: '30px', marginLeft: '30px'}}>{props.data.quantity}</Counter>
                {props.preSpiff ? <div style={{display: 'flex', flexDirection: 'row', width: '50%', marginTop: '5px', marginLeft: '15px'}}>
                    <div style={{cursor: 'pointer', width: '20px', height: '20px', border: props.data.prespiff_payoff ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: props.data.prespiff_payoff ? props.colors.primary:'', borderRadius: '5px'}}></div>
                    <span style={{fontWeight: '500', marginLeft: '10px'}}>Prespiff Payoff</span>
                </div>:''}
            </div>
            <div style={{width: '100%', height: '400px', marginTop: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
                <div style={{width: '48.7%', height: '100%', backgroundColor: props.colors.block, marginRight: '10px', border: '1px solid ' + props.colors.border, borderRadius: '10px', display: 'flex', flexDirection: 'column', padding: '0px 20px 20px 20px'}}>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Dealership</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'dealership', true)} data={props.userDealerships} colors={props.colors} value={props.displayData.dealership} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Admin</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'admin', true)} data={props.dealerAdmins} colors={props.colors} value={props.displayData.admin} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Contract Type</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'contract_type', true)} data={props.adminContractType} colors={props.colors} value={props.displayData.contract_type} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Spiffee</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'spiffee', true)} data={props.dealerSpiffees} colors={props.colors} value={props.displayData.spiffee} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                </div>
                <div style={{width: '48.7%', height: '100%', backgroundColor: props.colors.block, marginRight: '10px', border: '1px solid ' + props.colors.border, borderRadius: '10px', display: 'flex', flexDirection: 'column', padding: '0px 20px 20px 20px'}}>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Amount</span>
                    <div style={{marginTop: '5px'}}><TextInput Style={{marginTop: '5px', width: '100%'}} prefix="$" onChange={(e) => props.onDataChange(e, 'amount')} colors={props.colors} data={props.displayData.amount}></TextInput></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Shipping Address</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'address', true)} data={shippingTypes} colors={props.colors} value={props.displayData.address} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>ATTN</span>
                    <div style={{marginTop: '5px'}}><TextInput Style={{marginTop: '5px', width: '100%'}} prefix="" onChange={(e) => props.onDataChange(e, 'ATTN')} colors={props.colors} data={props.displayData.ATTN}></TextInput></div>
                    <span style={{marginTop: '20px', fontWeight: '500'}}>Spiff Type</span>
                    <div style={{marginTop: '5px'}}><Dropdown input={props.input} Style={{marginTop: '5px'}} onChange={(e) => props.onDataChange(e, 'spiff_type', true)} data={spiffTypes} colors={props.colors} value={props.displayData.spiff_type} index={props.index} indexParent={props.indexParent}></Dropdown></div>
                </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                {props.bottomButtons.length > 0 ? <Button onClick={() => props.onClick()} Style={{backgroundColor: props.bottomButtons[0].backgroundColor, height: '35px'}} active={true} colors={props.colors}>{props.bottomButtons[0].title}</Button>:''}
                {props.bottomButtons.length > 1 ? <Button onClick={() => props.bottomButtons[1].onClick()} Style={{marginLeft: 'auto', backgroundColor: props.bottomButtons[1].backgroundColor, height: '35px'}} active={true} colors={props.colors}>{props.bottomButtons[1].title}</Button>:''}
            </div>
        </div>
    )
}

export default Main
