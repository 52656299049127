import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton';
import Table from '../../elements/Table'
import Dropdown from '../../elements/ReactSelectDropdown'
import Counter from '../../elements/Counter'
import TextInput from '../../elements/TextInput'

import Delete from '../../media/icons/icons8-delete-96.png'
import Add from '../../media/icons/icons8-add-96.png'

import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Name',
            displayElement: (props) => (<TextInput prefix='' width="150px" onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: "",
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Subagents',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Gross / Contract',
            displayElement: (props) => (<TextInput prefix='$' width="100px" onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: 0,
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => deleteRow(props.index, props.tableIDs)} index={props.index} indexParent={props.indexParent} colors={props.colors} image={Delete} size={25} Style={{backgroundColor: props.colors.warning}}></IconButton>),
            default: undefined,
        },
    ])

    useEffect(() => {

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        };
    
        props.tokenSafeAPIRequest('/dealership/names', requestOptions, '', (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name + (dealer.state_code === null ? '':' - ' + dealer.state_code)})
            })
            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Dealer')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempDealerList} 
                                width={195}
                                value={props.data}
                              >
                                Test
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Commission'})
        };

        props.tokenSafeAPIRequest('/report/template/search?fetch_links=true&limit=100', requestOptions, '', (result) => {
            console.log(result)
            let tempTableValues = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempTableData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            result.map((template, index) => {
                tempTableData[0].push(template.name)
                tempTableData[1].push(template.dealership_ID.id)
                tempTableData[2].push(template.subagent_ID.id)
                tempTableData[3].push(template.admin_ID.id)
                tempTableData[4].push(template.contract_type)
                tempTableData[5].push(template.gross_per_contract)
                tempTableData[6].push(template.id)

                tempTableValues[0].push(template.name)
                tempTableValues[1].push(template.dealership_ID.name)
                tempTableValues[2].push(template.subagent_ID.name)
                tempTableValues[3].push(template.admin_ID.name)
                tempTableValues[4].push(template.contract_type)
                tempTableValues[5].push(template.gross_per_contract)
                tempTableValues[6].push(template.id)
                
                // loadAdminsSubagent(template.dealership_ID.id, index)
                // loadContractTypes(template.admin_ID.id, index)
            })

            console.log(tempTableValues)
            console.log(tempTableData)
    
            setTableValues(tempTableValues)
            setTableData(tempTableData)
        })
    },[])
    

    const [tableData, setTableData] = useState([
        [],
        [],
        [],
        [],
        [],
        [],
        [],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]], 
        [[]],
        [[]],
    ])

    const [tableValues, setTableValues] = useState([
        [],
        [],
        [],
        [],
        [],
        [],
        [],
    ]) 

    const [rowSelected, setRowSelected] = useState([false])

    const [deletedTemplates, setDeletedTemplates] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
    }

    const deleteRow = (index, currentTableData) => {
        console.log('Deleted Row: ' + index)
        console.log(currentTableData)

        setDeletedTemplates((deletedTemplates) => [...deletedTemplates, currentTableData[6][index]])

        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, index), ...data.slice(index + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log(e,x,y)
        if (x === 1)
        {
            console.log(e, x, y)
            loadAdminsSubagent(e.value, y) //loads admins for dropdown and sets subagent
        }
        if (x === 3)
        {
            loadContractTypes(e.value, y)
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const loadContractTypes = (admin, y) => {

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + admin, requestOptions, '', (result) => {
            let tempContractTypeList = []
            result.VSC_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
            })
            result.ancillary_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[4]]
                newSubArray[y] = tempContractTypeList;
                newData[4] = newSubArray;
                return newData
            })
        })
    }

    const clearRowData = (row) => {
        console.log(transposeArray(tableData))
        let tempData = transposeArray(tableData)
        let tempDataDisplay = transposeArray(tableValues)
        tempData[row][3] = ''
        tempData[row][4] = ''
        tempDataDisplay[row][3] = ''
        tempDataDisplay[row][4] = ''
        setTableData(transposeArray(tempData))
        setTableValues(transposeArray(tempDataDisplay))
    }

    const loadAdminsSubagent = (dealer, y) => {

        console.log(dealer, y)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + dealer + '?fetch_links=True', requestOptions, '', (result) => {
            console.log(result)
            let tempAdminList = []
            result.admin_IDs.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })

            onTableChange({value: result.subagent_owner_ID.id, label: result.subagent_owner_ID.first_name + ' ' + result.subagent_owner_ID.last_name}, y, 2) //e,y,x

            loadSubagentsList(result, y)

            console.log(tempAdminList)

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[3]]
                newSubArray[y] = tempAdminList;
                newData[3] = newSubArray;
                return newData
            })
        })
    }

    const loadSubagentsList = (dealerInfo, y) => {
        console.log(dealerInfo)
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/dealership/' + dealerInfo.id + '?fetch_links=true', requestOptions, '', (result) => {
            console.log(result)
            let tempSubagentList = []
            result.user_connections.map((agent, index) => {
                tempSubagentList.push({value: agent.id, label: agent.user_ID.first_name + ' ' + agent.user_ID.last_name})
            })
            tempSubagentList.push({value: '659742ce7c605a59716c4551', label: 'CBA Old Agents'})

            console.log(tempSubagentList)

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[2]]
                newSubArray[y] = tempSubagentList;
                newData[2] = newSubArray;
                return newData
            })
        })
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    return (
        <div style={{width: '1600px', height: '456px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Manage Template</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '95%'}}>
                <Table selectMenu={false} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} dropdownData={dropdownData} endButton={true} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} colors={props.colors}>
                    <IconButton size={30} Style={{backgroundColor: props.colors.primary, marginTop: '10px'}} onClick={addRow} colors={props.colors} image={Add}></IconButton>
                </Table>
            </div>
            <div style={{flexBasis: '5%'}}>
                <Button onClick={() => props.saveTemplates(tableData, tableValues, deletedTemplates)} colors={props.colors} active={true} Style={{float: 'right', width: 200}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
