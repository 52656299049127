import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Register New Dealer</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Name</span>
                    <TextInput colors={props.colors} data='' prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Associated Subagent</span>
                    <Dropdown colors={props.colors} onChange={(e) => ''} index={props.index} indexParent={props.indexParent} data={dealers2} width={'100%'} value={props.data}></Dropdown>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Address</span>
                    <TextInput colors={props.colors} data='' prefix=''></TextInput>
                </div>
                <Button colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
