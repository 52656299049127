import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Filters</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                {props.filterData.map((category, index) => {
                    console.log(category)
                    return <div style={{display: 'flex', flexDirection: 'column', margin: '5px'}}>
                            <span>{category.title}</span>
                            <Dropdown data={category.data} onChange={(e) => props.updateData(e, category.key)} colors={props.colors} value={props.displayData[category.key]} width={'100px'} Style={{marginTop: '3px', width: '100%'}}></Dropdown>
                        </div>
                })}
                <Button onClick={() => {props.saveFilters(props.data, props.displayData); props.setIsOpen()}} colors={props.colors} active={true} Style={{marginTop: '10px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
