import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import HomeLayout from '../elements/HomeLayout'
import Sidebar from '../elements/Sidebar'
import HomeController from '../assets/home/HomeController'
import MetricHeader from '../elements/MetricHeader'
import QuickLinks from '../assets/home/QuickLinks'
import Notifications from '../assets/home/Notifications'
import Annoucement from '../assets/home/Annoucement'
import ModalManager from '../elements/ModalManager'
import HolidayCardList from '../assets/home/HolidayCardList'
import ReserveReleaseRequestModal from '../assets/home/ReserveReleaseRequestModal'
import ReserveTotalsRequestModal from '../assets/home/ViewReserveTotalsRequestModal'
import AnnoucementLinkModal from '../assets/home/AnnoucementLinkModal'
import NewAnnoucementModal from '../assets/home/NewAnnoucementModal'
import Metrics from '../assets/home/Metrics'
import EditGiftCardFeesModal from '../assets/home/EditGiftCardFeesModal'
import RequestAccountTotal from '../assets/home/RequestAccountTotal'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookie] = useCookies([])

    const [role, setRole] = useState(localStorage.getItem('role'))
    const [name, setName] = useState(localStorage.getItem('name'))

    const refresh_token = cookies['refresh_token']

    const updateUserTab = (name, role) => {
        setName(name);
        setRole(role)
    }

    const [holidayCardListModal, setHolidayCardListModal] = useState(false)
    const [reserveReleaseRequest, setReserveReleaseRequest] = useState(false)
    const [reserveTotalsRequest, setReserveTotalsRequest] = useState(false)
    const [annoucementLinkModal, setAnnoucementLinkModal] = useState(false)
    const [newAnnoucementModal, setNewAnnoucementModal] = useState(false)

    const [notifications, setNotifications] = useState([])
    const [annoucement, setAnnoucement] = useState([])
    const [reportsToApprove, setReportsToApprove] = useState({})
    const [pendingReports, setPendingReports] = useState({})

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };
    
        props.tokenSafeAPIRequest('/user/me', requestOptions, tokenValues, (result, status) => {
            console.log(result.notifications)
            if (status === 200)
            {
                setNotifications(result.notifications)
            } else {setNotification(props.getServerResponse(result))}
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        props.tokenSafeAPIRequest('/announcement/search?limit=1', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setAnnoucement(result[0])
            } else {setNotification(props.getServerResponse(result))}
        })

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/metrics/subagent/approvals', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setReportsToApprove(result.my_approval_needed)
                setPendingReports(result.my_pending_reports)
            } else {setNotification(props.getServerResponse(result))}
        })
    },[])

    function getFutureDate(hours, days, weeks) {
        console.log(hours, days, weeks)
        hours = (hours === undefined) ? 0 : hours;
        days = (days === undefined) ? 0 : days;
        weeks = (weeks === undefined) ? 0 : weeks;
        const now = new Date();
        const hoursInMilliseconds = hours * 60 * 60 * 1000;
        const daysInMilliseconds = days * 24 * 60 * 60 * 1000;
        const weeksInMilliseconds = weeks * 7 * 24 * 60 * 60 * 1000;
        const totalMilliseconds = hoursInMilliseconds + daysInMilliseconds + weeksInMilliseconds;
    
        return new Date(now.getTime() + totalMilliseconds);
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const createAnnoucement = (annoucementInfo) => {

        console.log(annoucementInfo)

        if (annoucementInfo.duration_type === 'hours') {annoucementInfo.hours = annoucementInfo.duration}
        if (annoucementInfo.duration_type === 'days') {annoucementInfo.days = annoucementInfo.duration}
        if (annoucementInfo.duration_type === 'weeks') {annoucementInfo.weeks = annoucementInfo.duration}

        annoucementInfo.expiry = getFutureDate(annoucementInfo.hours, annoucementInfo.days, annoucementInfo.weeks)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(annoucementInfo)
        };

        props.tokenSafeAPIRequest('/announcement', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNewAnnoucementModal(false)
                setNotification('New Annoucement Created at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [notification, setNotification] = useState('')

    const submitReserveReleaseRequest = (data) => {

        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(
            {
                ...data,
                spiffee_ID: data.recipient.type > 0 ? null:data.recipient.id,
                dealership_ID: data.dealership,
                account_ID: data.reserve_account, 
                payee: data.recipient.type
            }
          )
        };

        props.tokenSafeAPIRequest('/report/reserve_release', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setReserveReleaseRequest(false)
                setNotification('Reserve Release Request Created: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const submitViewReserveTotal = (data) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(
            {
                ...data,
                dealership_ID: data.dealership,
                account_ID: data.reserve_account
            }
          )
        };

        props.tokenSafeAPIRequest('/report/reserve_totals', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setReserveTotalsRequest(false)
                setNotification('View Reserve Account Request Created: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const addNewEntry = (data) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        data.phone_number = data.number;
        data.company = data.dealer;

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(data),
        };

        props.tokenSafeAPIRequest('/holiday_card', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setNotification('New Entry Added: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [requestAccountTotalModal, setRequestAccountTotalModal] = useState(false)

    const [editGiftCardFeesModal, setEditGiftCardFeesModal] = useState(false)

    const setFees = (id, card) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(card)
        };

        props.tokenSafeAPIRequest('/gift_card/' + card.id, requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setNotification('Fee Updated: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <HomeLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <HomeController colors={props.colors} reportsToApprove={reportsToApprove} pendingReports={pendingReports} activeButton={true} title={"Dashboard"} baseURL={props.baseURL} name={name} role={role} updateUserTab={updateUserTab} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></HomeController>
            <MetricHeader colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></MetricHeader>
            <QuickLinks setEditGiftCardFeesModal={setEditGiftCardFeesModal} setHolidayCardListModal={setHolidayCardListModal} tokenSafeAPIRequest={props.tokenSafeAPIRequest} setReserveReleaseRequest={setReserveReleaseRequest} setReserveTotalsRequest={setReserveTotalsRequest} colors={props.colors} isAdmin={true} role={role}></QuickLinks>
            <Notifications colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} notifications={notifications}></Notifications>
            <Annoucement colors={props.colors} setAnnoucementLinkModal={setAnnoucementLinkModal} setNewAnnoucementModal={setNewAnnoucementModal} annoucement={annoucement}></Annoucement>
            <Metrics colors={props.colors}></Metrics>
            <ModalManager colors={props.colors}>
                <HolidayCardList isOpen={holidayCardListModal} setIsOpen={() => setHolidayCardListModal(false)} colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} addNewEntry={addNewEntry} setNotification={setNotification}></HolidayCardList>
                <ReserveReleaseRequestModal colors={props.colors} isOpen={reserveReleaseRequest} setIsOpen={() => setReserveReleaseRequest(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest} submitReserveReleaseRequest={submitReserveReleaseRequest}></ReserveReleaseRequestModal>
                <ReserveTotalsRequestModal colors={props.colors} isOpen={reserveTotalsRequest} setIsOpen={() => setReserveTotalsRequest(false)} tokenSafeAPIRequest={props.tokenSafeAPIRequest} submitViewReserveTotal={submitViewReserveTotal}></ReserveTotalsRequestModal>
                <AnnoucementLinkModal colors={props.colors} isOpen={annoucementLinkModal} setIsOpen={() => setAnnoucementLinkModal(false)} title={'Event Link'} link={'https://www.google.com'} annoucement={annoucement}></AnnoucementLinkModal>
                <NewAnnoucementModal colors={props.colors} isOpen={newAnnoucementModal} setIsOpen={() => setNewAnnoucementModal(false)} createAnnoucement={createAnnoucement}></NewAnnoucementModal>
                <EditGiftCardFeesModal colors={props.colors} isOpen={editGiftCardFeesModal} setIsOpen={() => setEditGiftCardFeesModal(false)} setFees={setFees} getFees={''} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></EditGiftCardFeesModal>
                {/* <RequestAccountTotal colors={props.colors} isOpen={requestAccountTotalModal} setIsOpen={() => setRequestAccountTotalModal(false)}></RequestAccountTotal> */}
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </HomeLayout>
    )
}

export default Main
