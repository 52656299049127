import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import CBA from '../media/images/CBALogo.png'

import TextInput from '../elements/TextInput'
import Button from '../elements/Button'

import Login_Graphic from '../media/images/Login_Graphic.png'
import { useParams } from 'react-router-dom';
import Notification from '../elements/Notification'

const Main = (props) => {

    const { key } = useParams()
    console.log(key)

    const [newPassword, setNewPassword] = useState('')
    const [confirmNewPassword, setConfirmNewPassword] = useState('')

    const [notification, setNotification] = useState('')

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const resetPassword = (newPass, confirmedPass) => {
        if (newPass === confirmNewPassword)
        {
            fetch(props.baseURL + '/auth/reset_password/' + key, {
                method: "POST", 
                redirect: 'follow',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                },
                body: JSON.stringify({
                    password: newPass
                })
            })
            .then(res => res.json())
            .then(json => {
                console.log(json)
                setNotification(props.getServerResponse(json))
            })
        }
        else
        {
            setNotification('Passwords do not Match!: ' + getCurrentTimeFormatted())
        }
    }

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor}}>

            <Notification colors={props.colors} message={notification}></Notification>

            <div style={{width: '450px', height: '80%', margin: 'auto', display: 'flex', flexDirection: 'column', position: 'relative'}}>
                <Link to='/login'><img src={CBA} style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '100px'}} width="305px"></img></Link>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px', textAlign: 'center'}}>Reset Password</span>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px'}}>New Password</span>
                <TextInput colors={props.colors} onChange={(e) => setNewPassword(e)} data={newPassword}></TextInput>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px'}}>Confirm New Password</span>
                <TextInput colors={props.colors} onChange={(e) => setConfirmNewPassword(e)} data={confirmNewPassword}></TextInput>
                <Button onClick={() => resetPassword(newPassword, confirmNewPassword)} colors={props.colors} active={true} Style={{marginTop: '20px'}}>Reset Password</Button>
                <div style={{display: 'flex', flexDirection: 'column', position: 'absolute', left: '500px', top: '350px', width: '500px'}}>
                    <span style={{fontSize: '20px', fontWeight: '500'}}>Password Requirements</span>
                    <span style={{fontWeight: '500', color: props.colors.muted}}>• Must be at least 5 characters long</span>
                    <span style={{fontWeight: '500', color: props.colors.muted}}>• Must contain a capital letter</span>
                    <span style={{fontWeight: '500', color: props.colors.muted}}>• Must contain a number</span>
                </div>
            </div>
            <img src={Login_Graphic} style={{height: '20%'}}></img>
        </div>
    )
}

export default Main
