import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import CBA from '../media/images/CBALogo.png'

import Home from '../media/icons/icons8-house-96-grey.png'
import Manage from '../media/icons/icons8-manage-96-grey.png'
import Reports from '../media/icons/icons8-report-96-grey.png'
import Metrics from '../media/icons/icons8-chart-96-grey.png'

import HomeBlue from '../media/icons/icons8-house-96-blue.png'
import ManageBlue from '../media/icons/icons8-manage-96-blue.png'
import ReportsBlue from '../media/icons/icons8-report-96-blue.png'
import MetricsBlue from '../media/icons/icons8-chart-96-blue.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    
    let tokenValues = 
        {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

    const permissionCheck = () => {
        if (cookies['role'] === 'office_staff')
        {
            return true;
        }
        else if (cookies['role'] === 'management')
        {
            return true;
        }
        else if (cookies['role'] === 'admin')
        {
            return true;
        }
        else if (cookies['role'] === 'superadmin')
        {
            return true;
        }
        else 
        {
            return false
        }
    }   

    const icons = [
        {
            image: CBA, 
            activeImage: CBA,
            width: '44px',
            link: '/',
            permission: true,
        },
        {
            image: Home,
            activeImage: HomeBlue,
            width: '24px',
            link: '/',
            permission: true,
        },
        // {
        //     image: Manage,
        //     activeImage: ManageBlue,
        //     width: '24px',
        //     link: '/manage/subagents',
        //     permission: permissionCheck()
        // },
        // {
        //     image: Reports,
        //     activeImage: ReportsBlue,
        //     width: '24px',
        //     link: '/reports/thursdayreporting',
        //     permission: true
        // },
        {
            image: Metrics,
            activeImage: MetricsBlue,
            width: '24px',
            link: '/output',
            permission: true
        },
    ]

    return (
        <div style={{width: '57px', height: '100vh', position: 'fixed'}}>
            <div style={{width: '100%', height: 85 + (77 * props.tab) + 'px', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderBottomRightRadius: '20px'}}>
                {icons.map((item, index) => 
                <Link to={item.permission ? item.link:''}>
                    <div className='center' style={{width: '100%', height: '77px', cursor: 'pointer'}}>
                        <img src={props.tab === (index-1) ? item.activeImage:item.image} width={item.width}></img>
                    </div>
                </Link>
                )}
            </div>
            <div style={{width: '100%', height: '60px'}}>
                
            </div>
            <div style={{width: '100%', height: "2500px", backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderTopRightRadius: '20px'}}>

            </div>
        </div>
    )
}

export default Main
