import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

const Main = (props) => {

    return (
        <div style={{backgroundColor: props.colors.block, display: 'flex', flexDirection: 'row', border: '1px solid ' + props.colors.border, borderRadius: '10px', marginTop: '15px', padding: '10px 15px 10px 15px', marginRight: '20px'}}>
            {props.headerValues.map((item, index) => {
                return <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                                <span style={{color: props.colors.muted, fontWeight: '600'}}>{item.title}</span>
                                <span style={{fontSize: '24px', fontWeight: '600', marginLeft: '20px'}}>{item.value}</span>
                            </div>
                            {index === props.headerValues.length-1 ? '':<div style={{width: '22px', height: '4px', backgroundColor: props.colors.highlight, borderRadius: '100px', marginTop: '25px', marginLeft: '20px'}}></div>}
                        </div>
            })}
        </div>
    )
}

export default Main
