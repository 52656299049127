import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [data, setData] = useState({
        first_name: '',
        last_name: '',
        email: '',
        address: '',
        state_code: '',
        city: '',
        zip_code: '',
        phone_number: '',
        SSN: '',
        username: '',
        password: '',
        resource_state: 0,
        territory_input: '',
        territories: [], 
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    const addTerritory = () => {
        setData(prevData => ({
            ...prevData,
            territories: [...prevData.territories, prevData.territory_input],
            territory_input: ''
        }))
    }

    const deleteTerritory = (index) => {
        console.log('Deleting Index: ' + index)
        setData(prevData => ({
            ...prevData,
            territories: prevData.territories.filter((_, idx) => idx !== index)
        }))
        console.log(data.territories)
    }

    return (
        <div style={{width: '715px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Create New Subagent</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>First Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'first_name')} colors={props.colors} data={data.first_name} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Last Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'last_name')} colors={props.colors} data={data.last_name} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Street</span>
                        <TextInput onChange={(e) => onDataChange(e, 'address')} colors={props.colors} data={data.address} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>City</span>
                        <TextInput onChange={(e) => onDataChange(e, 'city')} colors={props.colors} data={data.city} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Email</span>
                        <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={data.email} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>State</span>
                        <TextInput onChange={(e) => onDataChange(e, 'state_code')} colors={props.colors} data={data.state_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>SSN</span>
                        <TextInput onChange={(e) => onDataChange(e, 'SSN')} colors={props.colors} data={data.SSN} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Zip Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'zip_code')} colors={props.colors} data={data.zip_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Phone Number</span>
                        <TextInput onChange={(e) => onDataChange(e, 'phone_number')}  colors={props.colors} data={data.phone_number} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Username</span>
                        <TextInput onChange={(e) => onDataChange(e, 'username')} colors={props.colors} data={data.username} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500'}}>Password</span>
                        <TextInput onChange={(e) => onDataChange(e, 'password')} colors={props.colors} data={data.password} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', width: '50%', marginTop: '20px'}}>
                        <div onClick={() => onDataChange(data.inactive === 0 ? 1:0, 'inactive')} style={{cursor: 'pointer', width: '20px', height: '20px', border: data.inactive === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: data.inactive === 1  ? props.colors.primary:'', borderRadius: '5px'}}></div>
                        <span style={{fontWeight: '500', marginLeft: '10px'}}>Mark as Inactive</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <div style={{display: 'flex', flexDirection: 'row'}}>
                            <span style={{fontWeight: '500'}}>Territories</span>
                            <TextInput colors={props.colors} onChange={(e) => onDataChange(e.toUpperCase(), 'territory_input')} data={data.territory_input} prefix='' Style={{height: '25px', width: '30px', padding: '0px', marginLeft: '10px'}}></TextInput>
                            <Button onClick={() => addTerritory()} colors={props.colors} Style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '3px',  marginLeft: '5px', height: '25px', paddingTop: '0px', paddingBottom: '0px'}}>+</Button>
                        </div>
                        <div style={{display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                            {data.territories.map((territory, index) => { 
                                return <span onClick={() => deleteTerritory(index)} style={{backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '3px', padding: '1px 5px 1px 5px', margin: '3px', cursor: 'pointer'}}>{territory}</span>
                            })}
                        </div>
                    </div>
                </div>
                <Button onClick={() => {props.addNewSubagent(data);}} colors={props.colors} active={true} Style={{width: '150px', marginLeft: 'auto'}}>Create</Button>
            </div>
        </div>
    )
}

export default Main
