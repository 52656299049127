import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import GoTo from '../../media/icons/icons8-share-96.png'

const Main = (props) => {

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 'Jon Bukowski',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '$200',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Spiffs</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></Table>
                <Button colors={props.colors} active={true}>Close</Button>
            </div>
        </div>
    )
}

export default Main
