import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

const Main = (props) => {

    {/*
    Documentation
        Props:
            active={false/true} - Swaps default styling to active - False by default
            default={false/true} - Enables default styles entirely - True by default ***STYLE MUST BE SET FOR DEFAULT TO BE SET TO FALSE***
            Style={...styles} - Passthrough to styles for complete control - empty by default
            onClick={function()} - passthrought to onClick to callback a function - empty by default
            children={'string'} - Text on button
    */}

    const defaultStyle = 
        props.active ? 
        {
            display: 'block',
            backgroundColor: props.colors.primary,
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: '10000px',
            color: props.colors.fontInvert,
            fontWeight: '500',
            height: '100%',
            minHeight: '30px',
            maxHeight: '40px',
        }
        :
        {
            display: 'block',
            backgroundColor: props.colors.block,
            paddingLeft: '20px',
            paddingRight: '20px',
            borderRadius: '10000px',
            border: '1px solid ' + props.colors.border,
            color: props.colors.font,
            fontWeight: '500',
            height: '100%',
            minHeight: '30px',
            maxHeight: '40px',
        }

    return (
        <motion.button onClick={props.onClick} whileHover={{scale: 1.03}} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <span>{props.children}</span>
        </motion.button>
    )
}

export default Main
