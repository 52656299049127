import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

const Main = (props) => {

    return (
        <>
            {Array.isArray(props.children) ? props.children.map((modal) => {
                return <Modal style={{overlay: {backgroundColor: 'rgba(0,0,0,0.1)'}, content: {top: '50%', left: '50%', bottom: 'auto', right: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', width: 'auto', height: 'auto', backgroundColor: props.colors.backgroundColor, maxHeight: '90vh'}}} isOpen={modal.props.isOpen} onRequestClose={modal.props.setIsOpen}>
                    {modal}
                </Modal>
            }):
            <Modal style={{overlay: {backgroundColor: 'rgba(0,0,0,0.1)'}, content: {top: '50%', left: '50%', bottom: 'auto', right: 'auto', width: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', height: 'auto', backgroundColor: props.colors.backgroundColor, maxHeight: '90vh'}}} isOpen={props.children.props.isOpen} onRequestClose={props.children.props.setIsOpen}>
                {props.children}
            </Modal>}
        </>
    )
}

export default Main
