import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [selectedReport, setSelectedReport] = useState('')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/report/full/' + props.selectedReport.report_code, requestOptions, '', (result, status) => {
            console.log(result)
            setSelectedReport(result)
        })
    },[])

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return 'Subagents Under Dealer'}
        if (payee === 3) {return 'Inside Sales'}
    }

    return (
        <div style={{width: '400px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Output</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            {selectedReport === '' ? '':<div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Dealer</span>
                        <TextInput colors={props.colors} isDisabled={true} data={props.selectedReport.dealership_ID.name} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Account</span>
                        <TextInput colors={props.colors} isDisabled={true} data={props.selectedReport.account_ID.name} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Amount</span>
                        <TextInput  colors={props.colors} isDisabled={true} data={props.selectedReport.amount} prefix='$'></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Recipient</span>
                        <TextInput colors={props.colors} isDisabled={true} data={convertPayeeToUser(selectedReport.payee, '', '', selectedReport.dealership_ID.name)} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>ATTN</span>
                        <TextInput colors={props.colors} isDisabled={true} data={selectedReport.ATTN} prefix=''></TextInput>
                    </div>
                </div>
                <Button onClick={() => {props.setIsOpen()}} colors={props.colors} active={true} Style={{width: '150px', marginLeft: 'auto', marginTop: '15px'}}>Close</Button>
            </div>}
        </div>
    )
}

export default Main
