import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayout from '../elements/OutputLayout'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'
import ModalManager from '../elements/ModalManager'

import Weekly7Eligibility from '../assets/reportingHistory/Weekly7Eligibility'
import ViewAdminModal from '../assets/reportingHistory/ViewAdminsModal'
import ViewContractTypesModal from '../assets/reportingHistory/ViewContractTypesModal'

import ExportData from '../assets/metrics/ExportData'

import CalendarModal from '../assets/metrics/CalendarModal'

import FiltersModal from '../assets/metrics/FiltersModal'

const Main = (props) => {

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const list = ['4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors']

    const headerValues = [
        {
            title: 'Total IS Net',
            value: '$103,572'
        },
        {
            title: '30% of Net',
            value: '$30,572'
        },
        {
            title: 'Total Dealers',
            value: 592
        },
        {
            title: 'Total Reports',
            value: '3,294'
        },
    ]

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Count',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: '',
        },
        {
            title: 'Void Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [], 
        [], 
        [],
        [], 
        [], 
        [], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const [sort, setSort] = useState('timestamp')
    const [filtersModal, setFiltersModal] = useState(false)
    const [skip, setSkip] = useState(15)
    const [filterData, setFilterData] = useState([
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
    ])

    useEffect(() => {

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        }

        let tempDealerList = []
        let tempAdminList = []
        let tempSubagentList = []
        let tempContractList = []

        //get dealerships
        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempDealerList.push({value: '', label: 'All Dealers'})
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })  
        })

        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
          }

          //contract types
        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
        })

        setFilterData([
            {
                title: 'Dealership',
                data: tempDealerList,
                value: {label: '', value: ''},
                key: 'dealership_ID',
            },
            {
                title: 'Contract Type',
                data: tempContractList,
                value: {label: '', value: ''},
                key: 'contract_type',
            },
        ])
    },[])

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                console.log(category.value.value)
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy,
            report_type: 'Void'
          })
        }

        props.tokenSafeAPIRequest('/metrics/adjustment?fetch_links=true&limit=30' + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            if (result.length === 0)
            {
                setTableValues([
                    [], 
                    [], 
                    [], 
                    [], 
                    [], 
                    [], 
                ])
            }
            else
            {
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                result.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.total_contract_count)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[3].push(report.contract_type === null ? 'Adjustment - Void':report.contract_type + ' - ' + report.contract_subtype)
                    tempTableValues[4].push(report.net)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                })

                setTableValues(tempTableValues)
            }
        })
    },[filterData, sort, calStartDate, calEndDate])

    const onBottom = () => {
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                console.log(category.value.value)
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy,
            report_type: 'Void'
          })
        }

        props.tokenSafeAPIRequest('/metrics/adjustment?fetch_links=true&limit=15' + '&sort_field=' + sort + '&skip=' + skip + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            if (result.length === 0)
            {
                setTableValues([
                    [], 
                    [], 
                    [], 
                    [], 
                    [], 
                    [], 
                ])
            }
            else
            {
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                result.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.total_contract_count)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report?.dealership_ID?.name)
                    tempTableValues[3].push(report.contract_type === null ? 'Adjustment - Void':report.contract_type + ' - ' + report.contract_subtype)
                    tempTableValues[4].push(report.net)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        dealership_ID: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        dealership_ID: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'contract_gross', label: 'Amount' },
        { value: 'timestamp', label: 'Date' },
    ]

    const sortToName = {
        timestamp: 'Date',
        contract_gross: 'Amount',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'quantity', label: 'QTY'}, {id: 'total_contract_count', label: 'Contract Count'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'contract_subtype', label: 'Contract Type'}, {id: 'net', label: 'Amount'}, {id: 'date', label: 'Void Date'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            ...filterDataBy,
            start_date: startDate,
            report_type: 'Void'
        }
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    return (
        <OutputLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} removeFilter={removeFilter} filterData={filterData} sort={sort} setSort={setSort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/adjustment'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Void & Needs to be Replaced Report'}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayout>
    )
}

export default Main
