import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayout from '../elements/OutputLayout'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'
import ModalManager from '../elements/ModalManager'

import Weekly7Eligibility from '../assets/reportingHistory/Weekly7Eligibility'
import ViewAdminModal from '../assets/reportingHistory/ViewAdminsModal'
import ViewContractTypesModal from '../assets/reportingHistory/ViewContractTypesModal'

import ExportData from '../assets/metrics/ExportData'

const Main = (props) => {

    const list = ['4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors', '4 Season Motors']

    const headerValues = [
        {
            title: 'Total IS Net',
            value: '$103,572'
        },
        {
            title: '30% of Net',
            value: '$30,572'
        },
        {
            title: 'Total Dealers',
            value: 592
        },
        {
            title: 'Total Reports',
            value: '3,294'
        },
    ]

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Spiffee',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 'Jon Bukowski',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '4 Season Motors',
        },
        {
            title: 'Agent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 'Jon Bukowski',
        },
        {
            title: 'Pre-Spiff Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '10/24/23',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '$1,000',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [weekly7Eligibility, setWeekly7Eligibility] = useState(false)
    const [viewAdminModal, setViewAdminModal] = useState(false)
    const [viewContractTypesModal, setViewContractTypesModal] = useState(false)

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        };

        props.tokenSafeAPIRequest('/metrics/spiff/outstanding?fetch_links=true&limit=30', requestOptions, '', (result) => {
            console.log(result)
        })
    },[])

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    return (
        <OutputLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <OutputFilter colors={props.colors} filters={filterValues} filterData={[]} sortToName={[]}></OutputFilter>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <Weekly7Eligibility colors={props.colors} isOpen={weekly7Eligibility} setIsOpen={() => setWeekly7Eligibility(false)}></Weekly7Eligibility>
                <ViewAdminModal colors={props.colors} isOpen={viewAdminModal} setIsOpen={() => setViewAdminModal(false)}></ViewAdminModal>
                <ViewContractTypesModal colors={props.colors} isOpen={viewContractTypesModal} setIsOpen={() => setViewContractTypesModal(false)}></ViewContractTypesModal>
                <ExportData colors={props.colors} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)}></ExportData>
            </ModalManager>
        </OutputLayout>
    )
}

export default Main
