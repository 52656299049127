import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const [refresh, setRefresh] = useState(false)
    const [holidayCardList, setHolidayCardList] = useState([])

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: "",
        },
        {
            title: 'Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Company/Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Position',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Number',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => {deleteCard(props.tableData, props.index); setRefresh((refresh) => !refresh)}} Style={{backgroundColor: props.colors.warning}} size={27} image={Delete} colors={props.colors}></IconButton>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Template
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross/Contract
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]],
    ])

    const [data, setData] = useState({
        name: '',
        address: '',
        dealer: '',
        position: '',
        number: '',
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    useEffect(() => {

        console.log('getting more data')

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        data.phone_number = data.number;
        data.company = data.dealer;

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({}),
        };

        props.tokenSafeAPIRequest('/holiday_card/search', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            setHolidayCardList(result)
            let tempHolidayCardList = [
                [],
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((card, index) => {
                tempHolidayCardList[0].push(card.name)
                tempHolidayCardList[1].push(card.address)
                tempHolidayCardList[2].push(card.company)
                tempHolidayCardList[3].push(card.position)
                tempHolidayCardList[4].push(card.phone_number)
                tempHolidayCardList[5].push(card.id)
            })
            setTableValues(tempHolidayCardList)
        })
    }, [refresh])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const deleteCard = (currentTableData, index) => {
        console.log(transposeArray(currentTableData)[index][5])
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({}),
        };

        props.tokenSafeAPIRequest('/holiday_card/' + transposeArray(currentTableData)[index][5], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                props.setNotification('Deleted Holiday Card: ' + getCurrentTimeFormatted())
                setRefresh((refresh) => !refresh)
            } else {props.setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <div style={{width: '1445px', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Holiday Card List</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '5%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '18px', marginTop: '4px'}}>Add a New Entry</span>
                <Button colors={props.colors} onClick={() => {props.addNewEntry(data); setRefresh((refresh) => !refresh)}} active={true} Style={{borderRadius: '5px', fontSize: '24px', width: '35px', height: '35px', padding: 0, marginLeft: '20px'}}>+</Button>
            </div>
            <div style={{flexBasis: '20%', display: 'flex', flexDirection: 'row', textAlign: 'center', marginTop: '15px', textAlign: 'left'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: '20%', margin: '10px', marginTop: '0px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput colors={props.colors} data={data.name} onChange={(e) => onDataChange(e, 'name')}></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: '20%', margin: '10px', marginTop: '0px'}}>
                    <span style={{fontWeight: '500'}}>Address</span>
                    <TextInput colors={props.colors} data={data.address} onChange={(e) => onDataChange(e, 'address')}></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: '20%', margin: '10px', marginTop: '0px'}}>
                    <span style={{fontWeight: '500'}}>Company / Dealer</span>
                    <TextInput colors={props.colors} data={data.dealer} onChange={(e) => onDataChange(e, 'dealer')}></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: '20%', margin: '10px', marginTop: '0px'}}>
                    <span style={{fontWeight: '500'}}>Position</span>
                    <TextInput colors={props.colors} data={data.position} onChange={(e) => onDataChange(e, 'position')}></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexBasis: '20%', margin: '10px', marginTop: '0px'}}>
                    <span style={{fontWeight: '500'}}>Number</span>
                    <TextInput colors={props.colors} data={data.number} onChange={(e) => onDataChange(e, 'number')}></TextInput>
                </div>
            </div>
            <div style={{flexBasis: '70%', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <Table selectMenu={false} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} colors={props.colors}></Table>
            </div>
        </div>
    )
}

export default Main
