import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const [fees, setFees] = useState()
    const [oldFees, setOldFees] = useState()

    const handleChange = (name, newAmount) => {
        setFees((prevCards) => 
          prevCards.map((card) => 
            card.name === name ? { ...card, flat_fee: newAmount } : card
          )
        );
      };

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({}),
        };

        props.tokenSafeAPIRequest('/gift_card/search', requestOptions, '', (result, status) => {
            setFees(result)
            setOldFees(result)
        })
    }, [])

    return (
        <div style={{width: '420px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Gift Card Fees</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'row', marginTop: '10px', borderTop: '2px solid ' + props.colors.border, paddingTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'column', width: '50%', marginTop: '0px', fontWeight: '500'}}>
                    {fees?.map((card, index) => {
                        return (<span style={{marginBottom: '11px', height: '30px'}}>{card.name}</span>)
                    })}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px'}}>
                    {fees?.map((card, index) => {
                        return (<TextInput prefix='$' onChange={(e) => handleChange(card.name, e)} colors={props.colors} data={card.flat_fee} Style={{height: '29px', marginBottom: '10px'}}></TextInput>)
                    })}
                </div>
                <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px'}}>
                    {fees?.map((card, index) => {
                        return (<Button Style={{height: '20px', marginBottom: '9px', marginLeft: '10px'}} onClick={() => props.setFees(card.id, card)} active={true} colors={props.colors}>Save</Button>)
                    })}
                </div>
            </div>
        </div>
    )
}

export default Main
