import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import RegisterNewDealerModal from '../assets/dealerSearch/RegisterNewDealerModal'
import EditDealerModal from '../assets/dealerSearch/EditDealerModal'

import Notification from '../elements/Notification'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const debounceTimerRef = useRef();

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Dealerships')
            searchDealerships(e, 30, true);
        }, 1000); // 3000 ms = 3 seconds
    }

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);
    
    const searchElements = [
        (props) => (<div><TextInput placeholder='Search' colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
        'Agent',
        'State',
        'Last Sale Date',
        'Signed',
    ])
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 1,
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Address',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Associated Subagent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Last Sale Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], //admin
        [[]], //contract type
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e, x, y, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const [registerNewDealerModal, setRegisterNewDelearModal] = useState(false)
    const [editDealerModal, setEditDealerModal] = useState(false)

    const [dealers, setDealers] = useState([])
    const [notification, setNotification] = useState('')

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({})
        };

        props.tokenSafeAPIRequest('/dealership/subagent_search?limit=10&fetch_links=true&active_only=false&skip=0&reverse_sort=false', requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempDealerList = [
                [],
                [],
                [],
                [],
                [],
            ]
            setDealers(result)
            result.map((dealer) => {
                tempDealerList[0].push(dealer.code)
                tempDealerList[1].push(dealer.name)
                tempDealerList[2].push((dealer.address === null ? '':dealer.address + ', ') + (dealer.city === null ? '':dealer.city + ', ') + (dealer.state_code === null ? '':dealer.state_code))
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(formatDateString(dealer.last_production_date))
            })
            setTableValues(tempDealerList)
        })
    },[])

    function formatDateString(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Options for toLocaleDateString to format the date as 'Month Day, Year'
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
        // Format the date
        return date.toLocaleDateString('en-US', options);
    }

    const searchDealerships = (e, preloadedDealers, typedSearch) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({search_field: e})
        };

        props.tokenSafeAPIRequest('/dealership/subagent_search?limit=10&fetch_links=true&active_only=false&skip=0&reverse_sort=false', requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempDealerList = [
                [],
                [],
                [],
                [],
                [],
            ]
            setDealers(result)
            result.map((dealer) => {
                tempDealerList[0].push(dealer.code)
                tempDealerList[1].push(dealer.name)
                tempDealerList[2].push((dealer.address === null ? '':dealer.address + ', ') + (dealer.city === null ? '':dealer.city + ', ') + (dealer.state_code === null ? '':dealer.state_code))
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(formatDateString(dealer.last_production_date))
            })
            setTableValues(tempDealerList)
        })
    }

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={''} title='Dealer Search' tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} searchElementValue={searchElementValues[0]} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={() => ''} dealers={dealers}></ManageTable>
            <ModalManager colors={props.colors}>
                <RegisterNewDealerModal colors={props.colors} isOpen={registerNewDealerModal} setIsOpen={() => setRegisterNewDelearModal(false)}></RegisterNewDealerModal>
                <EditDealerModal colors={props.colors} isOpen={editDealerModal} setIsOpen={() => setEditDealerModal(false)}></EditDealerModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
