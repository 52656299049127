import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from './Sidebar'
import Controller from './Controller'
import MetricHeader from './MetricHeader'
import SaturdayReport from '../assets/saturdayreporting/SaturdayReport.jsx'

const Main = (props) => {

    const defaultStyle = 
        props.active ? 
        {
            display: 'block',
            backgroundColor: props.colors.primary,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            color: props.colors.fontInvert,
            fontWeight: '500',
        }
        :
        {
            display: 'block',
            backgroundColor: props.colors.block,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            border: '1px solid ' + props.colors.border,
            color: props.colors.font,
            fontWeight: '500',
        }

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'start', overflow: 'hidden'}}>
            {props.children[5]}
            {props.children[6]}
            <div style={{float: 'left', width: '57px', height: '100%', flexShrink: 0}}>
                {/*<Sidebar colors={props.colors} tab={0}></Sidebar>*/}
                {props.children[0]}
            </div>  
            <div style={{float: 'left', width: '370px', height: '100%', flexShrink: 0}}>
                {/* {<Controller colors={props.colors} buttonList={props.buttonList} title="Thursday Reporting"></Controller>} */}
                {props.children[1]}
            </div>
            <div style={{width: "auto", marginLeft: '25px', display: 'flex', flexDirection: 'column'}}>
                <div style={{flexBasis: 'auto', borderBottom: '1px solid ' + props.colors.border}}>
                    {/* <MetricHeader colors={props.colors} headerData={props.headerData}></MetricHeader> */}
                    {props.children[2]}
                </div>
                <div style={{flexBasis: 'auto', width: 'calc(100vw - 445px)', height: 'calc(100vh - 188px)', display: 'flex', flexDirection: 'row', maxWidth: '1475px'}}>
                    <div>
                        {props.children[3]}
                    </div>
                    <div style={{width: '550px'}}>
                        {props.children[4]}
                    </div>

                </div>
            </div>
        </div>
        // <div style={{width: '1920px', height: '100vh'}}>

        // </div>
    )
}

export default Main
