import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import PermissionLayout from '../elements/PermissionLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'
import SubmitReport from '../assets/commissionreceivedos/SubmitReport.jsx'

import DenyReport from '../assets/DenyConfirm.jsx'

import Approve from '../media/icons/icons8-approve-96.png'
import Deny from '../media/icons/icons8-cancel-96.png'

import Notification from '../elements/Notification.jsx'

import ViewPermissions from '../assets/permissions/ViewPermissions.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const submitSelectedReports = () => {
        setSubmitReportModal(true)
    }

    const [rowSelected, setRowSelected] = useState([false])

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [submitReportModal, setSubmitReportModal] = useState(false)

    const [denyModal, setDenyModal] = useState(false)

    const [refresh, setRefresh] = useState(false)

    function combineObjectsWithSameCode(arr) {
        const combined = arr.reduce((acc, obj) => {
          const key = obj.code;
      
          if (!acc[key]) {
            // Initialize with the current object, set quantity to 1, and start the ID array with the current ID.
            acc[key] = { ...obj, quantity: 1, IDs: [obj.id] };
          } else {
            // If the key exists, increment the quantity and push the current ID into the ID array.
            acc[key].quantity += 1;
            acc[key].IDs.push(obj.id);
          }
      
          // Remove the single ID property since we now have an IDs array.
          delete acc[key].id;
      
          return acc;
        }, {});
      
        console.log(Object.values(combined))
        return Object.values(combined);
      }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const [notification, setNotification] = useState('')

    const savePermissions = (data, id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        console.log(data)

        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(data)
        };

        props.tokenSafeAPIRequest('/permission/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Permissions at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const resetPermissionsToRole = (id) => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            deny_scope_overrides: {},
            allow_scope_overrides: {},
          })
        };

        props.tokenSafeAPIRequest('/user/' + id, requestOptions, '', (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Permissions at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <PermissionLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={false} buttonList={buttonList} title={"Permissions"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div>{/*Just to make sure nothing goes wrong with MainLayout, as this takes the place of MetricHeader*/}</div>
            <ViewPermissions colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} savePermissions={savePermissions} resetPermissionsToRole={resetPermissionsToRole}></ViewPermissions>
            {/* <Table selectMenu={false} colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange}></Table> */}
            <ModalManager colors={props.colors}>
                <div></div>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </PermissionLayout>
    )
}

export default Main
