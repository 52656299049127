import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'

const Main = (props) => {

    return (
        <div>
            Dashboard
        </div>
    )
}

export default Main
