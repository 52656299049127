import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            onClick={function()} - passthrought to onClick to callback a function - empty by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            img={true/false} - enables if it should be image or text
    */}

    const defaultStyle = 
        {
            backgroundColor: props.colors.block,
            borderRadius: props.size / 5,
            border: '1px solid #D7D7D7',
            color: props.colors.font,
            fontWeight: '500',
            height: 'fit-content',
            width: 'auto',
            minWidth: props.size
        }

    return (
        <motion.button className='center' onClick={props.onClick} style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            {props.img === undefined ? <img style={{width: (props.size), height: (props.size), padding: '2px'}} src={props.image}></img>:props.children}
        </motion.button>
    )
}

export default Main

