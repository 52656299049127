import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayout from '../elements/OutputLayout'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'
import ModalManager from '../elements/ModalManager'

import FiltersModal from '../assets/metrics/FiltersModal'
import DealerAdminsList from '../assets/metrics/DealerAdminsList'

import ExportData from '../assets/metrics/ExportData'

const Main = (props) => {

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap', maxWidth: '500px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Subagent Owner',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Last Production',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Inside Sales',
            displayElement: (props) => (<div style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: undefined,
        },
        {
            title: 'Weekly 7',
            displayElement: (props) => (<div style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: undefined,
        },
        {
            title: 'Admins',
            displayElement: (props) => (<Button onClick={() => {setAdminsModal(true); setSelectedDealer(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: '',
        },
        {
            title: 'Territory',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [weekly7Eligibility, setWeekly7Eligibility] = useState(false)
    const [viewAdminModal, setViewAdminModal] = useState(false)
    const [viewContractTypesModal, setViewContractTypesModal] = useState(false)

    const [sort, setSort] = useState('last_production_date')
    const [skip, setSkip] = useState(15)
    const [filtersModal, setFiltersModal] = useState(false)
    const [adminsModal, setAdminsModal] = useState(false)
    const [tableIDs, setTableIDs] = useState([])
    const [selectedDealer, setSelectedDealer] = useState(-1)
    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'subagent_owner_ID',
        },
        {
            title: 'Inside Sales',
            data: [
                {value: true, label: 'True'},
                {value: false, label: 'False'},
            ],
            value: {label: '', value: ''},
            key: 'inside_sales',
        },
        {
            title: 'Weekly 7',
            data: [
                {value: true, label: 'True'},
                {value: false, label: 'False'},
            ],
            value: {label: '', value: ''},
            key: 'weekly_7',
        },
        {
            title: 'Territory',
            data: [
                {label: 'AL', value: 'AL'},
                {label: 'AK', value: 'AK'},
                {label: 'AZ', value: 'AZ'},
                {label: 'AR', value: 'AR'},
                {label: 'CA', value: 'CA'},
                {label: 'CO', value: 'CO'},
                {label: 'CT', value: 'CT'},
                {label: 'DE', value: 'DE'},
                {label: 'FL', value: 'FL'},
                {label: 'GA', value: 'GA'},
                {label: 'HI', value: 'HI'},
                {label: 'ID', value: 'ID'},
                {label: 'IL', value: 'IL'},
                {label: 'IN', value: 'IN'},
                {label: 'IA', value: 'IA'},
                {label: 'KS', value: 'KS'},
                {label: 'KY', value: 'KY'},
                {label: 'LA', value: 'LA'},
                {label: 'ME', value: 'ME'},
                {label: 'MD', value: 'MD'},
                {label: 'MA', value: 'MA'},
                {label: 'MI', value: 'MI'},
                {label: 'MN', value: 'MN'},
                {label: 'MS', value: 'MS'},
                {label: 'MO', value: 'MO'},
                {label: 'MT', value: 'MT'},
                {label: 'NE', value: 'NE'},
                {label: 'NV', value: 'NV'},
                {label: 'NH', value: 'NH'},
                {label: 'NJ', value: 'NJ'},
                {label: 'NM', value: 'NM'},
                {label: 'NY', value: 'NY'},
                {label: 'NC', value: 'NC'},
                {label: 'ND', value: 'ND'},
                {label: 'OH', value: 'OH'},
                {label: 'OK', value: 'OK'},
                {label: 'OR', value: 'OR'},
                {label: 'PA', value: 'PA'},
                {label: 'RI', value: 'RI'},
                {label: 'SC', value: 'SC'},
                {label: 'SD', value: 'SD'},
                {label: 'TN', value: 'TN'},
                {label: 'TX', value: 'TX'},
                {label: 'UT', value: 'UT'},
                {label: 'VT', value: 'VT'},
                {label: 'VA', value: 'VA'},
                {label: 'WA', value: 'WA'},
                {label: 'WV', value: 'WV'},
                {label: 'WI', value: 'WI'},
                {label: 'WY', value: 'WY'}
            ],
            value: {label: '', value: ''},
            key: 'state_code',
        },
    ])

    function formatDate(dateString) {
        if (!dateString) {
            return "Never Produced";
          }
        // Parse the date string
        const date = new Date(dateString);
      
        // Extract the month, day, and year
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear().toString().substr(-2);
      
        // Pad the month and day with leading zeros if necessary
        if (month.length < 2) 
            month = '0' + month;
        if (day.length < 2) 
            day = '0' + day;
      
        // Return the formatted date
        return [month, day, year].join('/');
    }

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
        }

        let tempSubagentList = []

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
        })

        setFilterData([
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'subagent_owner_ID',
            },
            {
                title: 'Inside Sales',
                data: [
                    {value: true, label: 'True'},
                    {value: false, label: 'False'},
                ],
                value: {label: '', value: ''},
                key: 'inside_sales',
            },
            {
                title: 'Weekly 7',
                data: [
                    {value: true, label: 'True'},
                    {value: false, label: 'False'},
                ],
                value: {label: '', value: ''},
                key: 'weekly_7',
            },
            {
                title: 'Territory',
                data: [
                    {label: 'AL', value: 'AL'},
                    {label: 'AK', value: 'AK'},
                    {label: 'AZ', value: 'AZ'},
                    {label: 'AR', value: 'AR'},
                    {label: 'CA', value: 'CA'},
                    {label: 'CO', value: 'CO'},
                    {label: 'CT', value: 'CT'},
                    {label: 'DE', value: 'DE'},
                    {label: 'FL', value: 'FL'},
                    {label: 'GA', value: 'GA'},
                    {label: 'HI', value: 'HI'},
                    {label: 'ID', value: 'ID'},
                    {label: 'IL', value: 'IL'},
                    {label: 'IN', value: 'IN'},
                    {label: 'IA', value: 'IA'},
                    {label: 'KS', value: 'KS'},
                    {label: 'KY', value: 'KY'},
                    {label: 'LA', value: 'LA'},
                    {label: 'ME', value: 'ME'},
                    {label: 'MD', value: 'MD'},
                    {label: 'MA', value: 'MA'},
                    {label: 'MI', value: 'MI'},
                    {label: 'MN', value: 'MN'},
                    {label: 'MS', value: 'MS'},
                    {label: 'MO', value: 'MO'},
                    {label: 'MT', value: 'MT'},
                    {label: 'NE', value: 'NE'},
                    {label: 'NV', value: 'NV'},
                    {label: 'NH', value: 'NH'},
                    {label: 'NJ', value: 'NJ'},
                    {label: 'NM', value: 'NM'},
                    {label: 'NY', value: 'NY'},
                    {label: 'NC', value: 'NC'},
                    {label: 'ND', value: 'ND'},
                    {label: 'OH', value: 'OH'},
                    {label: 'OK', value: 'OK'},
                    {label: 'OR', value: 'OR'},
                    {label: 'PA', value: 'PA'},
                    {label: 'RI', value: 'RI'},
                    {label: 'SC', value: 'SC'},
                    {label: 'SD', value: 'SD'},
                    {label: 'TN', value: 'TN'},
                    {label: 'TX', value: 'TX'},
                    {label: 'UT', value: 'UT'},
                    {label: 'VT', value: 'VT'},
                    {label: 'VA', value: 'VA'},
                    {label: 'WA', value: 'WA'},
                    {label: 'WV', value: 'WV'},
                    {label: 'WI', value: 'WI'},
                    {label: 'WY', value: 'WY'}
                ],
                value: {label: '', value: ''},
                key: 'state_code',
            },
        ])
    },[])

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            console.log(category)
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }
  
        props.tokenSafeAPIRequest('/metrics/dealership/reporting?fetch_links=true&limit=30&sort_field=' + sort + '&reverse_sort=true', requestOptions, '', (result, status) => {
            console.log(result)
            if (result === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [],
                ])
            }
            else
            {
                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                let tempTableIDs = []
                result.map((dealer) => {
                    tempTableValues[0].push(dealer.name)
                    tempTableValues[1].push(dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name)
                    tempTableValues[2].push(formatDate(dealer.last_production_date))
                    tempTableValues[3].push(dealer.inside_sales)
                    tempTableValues[4].push(dealer.weekly_7)
                    tempTableValues[5].push(dealer.name)
                    tempTableValues[6].push(dealer.state_code)
                    tempTableIDs.push(dealer.admin_IDs)
                })
                setTableIDs(tempTableIDs)
                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[filterData, sort])

    const onBottom = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }
  
        props.tokenSafeAPIRequest('/metrics/dealership/reporting?fetch_links=true&limit=15&sort_field=' + sort + '&reverse_sort=true&skip=' + (skip+15), requestOptions, '', (result, status) => {
            console.log(result)
            if (result === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [],
                ])
            }
            else
            {
                let tempTableValues = tableValues

                let tempTableIDs = tableIDs
                result.map((dealer) => {
                    tempTableValues[0].push(dealer.name)
                    tempTableValues[1].push(dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name)
                    tempTableValues[2].push(formatDate(dealer.last_production_date))
                    tempTableValues[3].push(dealer.inside_sales)
                    tempTableValues[4].push(dealer.weekly_7)
                    tempTableValues[5].push(dealer.name)
                    tempTableValues[6].push(dealer.state_code)
                    tempTableIDs.push(dealer.admin_IDs)
                })
                setTableValues(tempTableValues)
                setTableIDs(tableIDs)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        console.log(displayData)
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => { console.log(item)
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        subagent_owner_ID: '',
        inside_sales: '',
        weekly_7: '',
        state_code: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        subagent_owner_ID: '',
        inside_sales: '',
        weekly_7: '',
        state_code: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'last_production_date', label: 'Production' },
    ]

    const sortToName = {
        last_production_date: 'Production',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'name', label: 'Dealership'}, {id: 'subagent_owner_ID.first_name', label: 'Subagent Owner First Name'}, {id: 'subagent_owner_ID.last_name', label: 'Subagent Owner Last Name'}, {id: 'last_production_date', label: 'Last Production'}, {id: 'inside_sales', label: 'Is Inside Sales'}, {id: 'admin', label: 'Admins'}, {id: 'state_code', label: 'Territory'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            ...filterDataBy,
            start_date: startDate,
        }
    }

    const exportPostProcessing = (result) => {
        result.map((dealer) => {
            let tempDealer = dealer
            let tempDealerAdmins = []
            tempDealer.admin_IDs.map((admin) => {
                tempDealerAdmins.push(admin.name)
            })
            tempDealer.admin = tempDealerAdmins.toString();
            console.log(tempDealerAdmins.toString())
            return tempDealer
        })
        return result;
    }

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))    

    return (
        <OutputLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <DealerAdminsList colors={props.colors} isOpen={adminsModal} setIsOpen={() => setAdminsModal(false)} tableIDs={tableIDs} selectedDealer={selectedDealer}></DealerAdminsList>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/dealership/reporting'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Reporting History Report'} postProcessing={exportPostProcessing}></ExportData>
            </ModalManager>
        </OutputLayout>
    )
}

export default Main
