import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    let loadedData = transposeArray(props.tableValues)[props.selectedRow]
    let loadedIds = transposeArray(props.tableData)[props.selectedRow]

    const [name, setName] = useState('')

    return (
        <div style={{width: '360px', height: '456px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Save Template</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: '500'}}>Name</span>
                <TextInput prefix="" onChange={(e) => setName(e)} colors={props.colors} data={name}>Name</TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Dealer</span>
                <Dropdown colors={props.colors} isDisabled={true} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} value={loadedData[2]}></Dropdown>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Admin</span>
                <Dropdown colors={props.colors} isDisabled={true} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} value={loadedData[3]}></Dropdown>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Contract Type</span>
                <Dropdown colors={props.colors} isDisabled={true} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} value={loadedData[4]}></Dropdown>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Gross / Contract</span>
                <TextInput prefix="$" isDisabled={true} onChange={(e) => console.log(e)} colors={props.colors} data={loadedData[5]}></TextInput>
                <Button Style={{marginTop: '20px'}} onClick={() => props.quickSaveTemplate(name, loadedData, loadedIds)} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
