import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    useEffect(() => {
        console.log(props.subagents[props.selectedSubagent])
        // let reserveAccounts = props.dealerReserveAccounts[props.selectedDealer];
        let tempTableValues = [
            [],
            [],
            [],
        ]
        let tempTableData = [
            [],
            [],
            [],
        ]
        props.subagents[props.selectedSubagent].reserve_account_IDs.map((account, index) => {
            tempTableValues[0].push(account.name === null ? '':account.name)
            tempTableValues[1].push(account.notes === null ? '':account.notes)
            tempTableValues[2].push(account.id)

            tempTableData[0].push(account.name)
            tempTableData[1].push(account.notes)
            tempTableData[2].push(account.id)
        })

        console.log(tempTableValues)
    
        setSpliteeTableValues(tempTableValues)
        setTableData(tempTableData)
    },[])


    
    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'Account Name',
            displayElement: (props) => (<TextInput onChange={(e) => props.onChange(e, props.indexParent, props.index)} colors={props.colors} prefix='' suffix='' data={props.data} Style={{width: '400px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Reason',
            displayElement: (props) => (<TextInput onChange={(e) => props.onChange(e, props.indexParent, props.index)} colors={props.colors} prefix='' suffix='' data={props.data} Style={{width: '500px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Remove',
            displayElement: (props) => (<IconButton onClick={() => deleteRow(props.index, props.tableData)} image={Delete} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: '',
        },
    ])

    const [spliteeTableValues, setSpliteeTableValues] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
    ])

    const [tableData, setTableData] = useState([
        [spliteeTableTitles[0].default],
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]], //admin
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])
    const [deletedAccountsInfo, setDeletedAccountsInfo] = useState([]);

    const onTableChange = (e,x,y) => {
        setSpliteeTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const addRow = () => {
        console.log('Add Row!')
        setSpliteeTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const [deleted, setDeleted] = useState(0)

    const deleteRow = (newIndex, currentTableData) => {

        console.log(currentTableData)

        setDeletedAccountsInfo((deletedAccountsInfo) => [...deletedAccountsInfo, currentTableData[2][newIndex]])

        setSpliteeTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Reserve Accounts</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={spliteeTableValues} tableInfo={spliteeTableTitles} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false}></Table>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button onClick={addRow} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                    <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add New Reserve Account</span>
                </div>
                <Button onClick={() => {props.editReserveAccounts(tableData, deletedAccountsInfo);}} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
