import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../elements/IconButton'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import Button from '../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
    ]

    const [cookies, setCookie] = useCookies([])

    const [agentList, setAgentList] = useState()

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var raw = JSON.stringify(
            {}
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        props.tokenSafeAPIRequest('/user/search?limit=9999999&reverse_sort=false', requestOptions, tokenValues, (result) => {
            let dealers = []
            result.map((agent, index) => {
                dealers.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
            console.log(dealers)
            setAgentList(dealers)
        })
    },[])

    const [value, setValue] = useState('')
    const [selectedAgent, setSelectedAgent] = useState('')
    
    return (
        <div style={{width: '360px', height: '400px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Change User</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '15px'}}>
                    <span style={{fontWeight: '500'}}>Select User</span>
                    <Dropdown colors={props.colors} data={agentList} onChange={(e) => {setSelectedAgent(e.value); setValue(e.label)}} value={value}></Dropdown>
                </div>
                <Button colors={props.colors} active={true} Style={{marginTop: '25px'}} onClick={() => {props.setImpersonateUser(selectedAgent); props.setIsOpen()}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
