import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'
import Save from '../media/icons/icons8-save-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Button from '../elements/Button.jsx'
import Counter from '../elements/Counter.jsx'
import TextInput from '../elements/TextInput.jsx'
import Table from '../elements/Table.jsx'
import IconButton from '../elements/IconButton.jsx'
import Dropdown from '../elements/ReactSelectDropdown.jsx'

import ManageTemplate from '../assets/thursdayreporting/ManageTemplates.jsx'
import SaveTemplate from '../assets/thursdayreporting/SaveTemplate.jsx'

import ParentSelector from '../elements/ParentSelector.jsx'
import DeleteReport from '../assets/DeleteConfirm.jsx'
import DeleteConfirm from '../assets/DeleteConfirm.jsx'

import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [refresh, setRefresh] = useState(false)

    const refreshData = () => {
        setRefresh((refresh) => !refresh)
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const buttonList = [
        {
            name: 'Approve Report',
            link: '',
            onClick: () => {},
        },
        {
            name: 'Deny Report',
            link: '',
            onClick: () => {},
        },
        {
            name: 'Commission Received Reports',
            link: '/reports/commissionreceivedOS',
            onClick: () => {},
        },
        // {
        //     name: 'Show Table Data',
        //     link: '',
        //     onClick: () => {showTableData()},
        // },
    ]

    const headerData = [
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: 7,
            total: 21750,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: 5,
            total: 12275,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: 12,
            total: 24.5,
        },
        {
            image: Contract,
            title: 'Monthly Contracts',
            change: -3,
            total: 123,
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: 0,
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Subagent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Gross / Contract',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: 0,
        },
        {
            title: 'Delete',
            displayElement: (props) => (
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <IconButton onClick={() => {setManageDenyModal(true); setDeleteReportIndex(props.index)}} Style={{backgroundColor: props.colors.warning, marginLeft: '10px', marginRight: '10px'}} size={27} image={Delete} colors={props.colors}></IconButton>
                </div>),
            default: undefined,
        },
    ])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
    }

    const templates = [
        {
            id: 0,
            name: "Template 1",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 200,
            value: 'Template 1',
            label: 'Template 1',
        },
        {
            id: 1,
            name: "Template 2",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 300,
            value: 'Template 2',
            label: 'Template 2',
        },
        {
            id: 2,
            name: "Template 3",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 400,
            value: 'Template 3',
            label: 'Template 3',
        },
        {
            id: 3,
            name: "Template 4",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 500,
            value: 'Template 4',
            label: 'Template 4',
        },
        {
            id: 4,
            name: "Template 5",
            dealer: '4 Season Motors',
            admin: 'Liberty Shield',
            contractType: 'VSC',
            grossPerContract: 2600,
            value: 'Template 5',
            label: 'Template 5',
        },
    ]

    const deleteRow = (index) => {
        console.log('Deleted Row')
        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
    }

    const onTableChange = (e, y, x) => {
        //console.log('Table Updated')
        if (x === 1) //check what column the change was in, 1 in this case is the template column
        {
            templates.map((template) => {
                if (template.name === e)
                {
                    applyTemplate(template, y)
                }
            })
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    return e;
                }
                return data;
            })
        }))
    }

    const applyTemplate = (template, row) => {
        setTableValues((tableValues) => tableValues.map((column, indexX) => {
            if (indexX === 2) {column[row] = template.dealer}
            if (indexX === 3) {column[row] = template.admin}
            if (indexX === 4) {column[row] = template.contractType}
            if (indexX === 5) {column[row] = template.grossPerContract}
            return column;
        }))
    }

    const submitSelectedReports = () => {

    }

    const [rowSelected, setRowSelected] = useState([false])

    const [manageTemplateModal, setManageTemplateModal] = useState(false)
    const [manageSaveTemplateModal, setManageSaveTemplateModal] = useState(false)

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Dealer
        [tableTitles[2].default], //Admin
        [tableTitles[3].default], //Contract Type
        [tableTitles[4].default], //Gross/Contract
        [tableTitles[5].default], //Edit Buttons (Empty as they don't need data)
        [tableTitles[6].default], //Edit Buttons (Empty as they don't need data)
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [dealers2], //admin
        [dealers2], //contract type
        [[]],
        [[]],
    ])

    const showTableData = () => {
        console.log(tableValues)
    }

    const [parentSelectedItem, setParentSelectedItem] = useState(-1)
    const [deleteReportModal, setDeleteReportModal] = useState(false)

    const [reportID, setReportID] = useState('')

    useEffect(() => {
        let tokenValues = 
        {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        let tempTableValues = [
            [],
            [],
            [],
            [],
            [],
            [],
            [],
            [],
        ]
    
        props.tokenSafeAPIRequest('/report/thursday_report/pending/CommissionReceived', requestOptions, tokenValues, (result) => {
            console.log(result)
            if (result.length === 0)
            {

            }
            else
            {
                let tempReportID = []
                result.map((report, index) => {
                    tempTableValues[0].push(report.quantity)
                    tempTableValues[1].push(report.dealership_ID)
                    tempTableValues[2].push(report.subagent_ID)
                    tempTableValues[3].push(report.admin_ID)
                    tempTableValues[4].push(report.contract_type + " - " + report.contract_subtype)
                    tempTableValues[5].push(report.contract_gross)
                    tempTableValues[6].push(report.status_message)
                    tempTableValues[7].push('')
                    tempReportID.push([report.id, report.code])
                })
                console.log(tempTableValues)
                setReportID(formatReportIDs(tempReportID))
    
                let newTempTableValues = (countDuplicateSubarrays(transposeArray(tempTableValues)))
                newTempTableValues.map((report, index) => {
                    report[0][0] = report[1]
                    report.pop()
                })
                let finalNewTempTableValues = transposeArray(transposeArray(newTempTableValues)[0])
                let tempData = {...finalNewTempTableValues} //needed to make copy otherwise it saves reference and then updates as finalNewTempTableValue changes from the rest of the function
                setTableData(tempData)
    
                console.log(finalNewTempTableValues)
    
                var requestOptions2 = {
                    method: 'POST',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(finalNewTempTableValues[1])
                };
    
                props.tokenSafeAPIRequest('/dealership/bulk?basic_info=true', requestOptions2, tokenValues, (result2) => {
                    finalNewTempTableValues[1] = result2
                    var requestOptions3 = {
                        method: 'POST',
                        headers: myHeaders,
                        redirect: 'follow',
                        body: JSON.stringify(finalNewTempTableValues[2])
                    };
            
                    props.tokenSafeAPIRequest('/user/bulk?basic_info=true', requestOptions3, tokenValues, (result3) => {
                        finalNewTempTableValues[2] = result3
                        var requestOptions4 = {
                            method: 'POST',
                            headers: myHeaders,
                            redirect: 'follow',
                            body: JSON.stringify(finalNewTempTableValues[3])
                        };
                        console.log(finalNewTempTableValues[3])
                        props.tokenSafeAPIRequest('/admin/bulk?basic_info=true', requestOptions4, tokenValues, (result4) => {
                            console.log(result4)
                            finalNewTempTableValues[3] = result4
                            setTableValues(finalNewTempTableValues)
                            setRowSelected((rowSelected) => new Array(finalNewTempTableValues[0].length).fill('').map((report, index) => {
                                return [...rowSelected, false]
                            }))
                        })
                    })
                })
            }
        })
    },[refresh])

    function formatReportIDs(originalArray) {
        const result = [];
      
        // Create a temporary object to store codes and their corresponding IDs
        const temp = {};
      
        originalArray.forEach(([id, code]) => {
          // If the code doesn't exist in the temp object, add it with an empty array
          if (!temp[code]) {
            temp[code] = [];
          }
      
          // Push the id to the corresponding code's array
          temp[code].push(id);
        });
      
        // Convert the temp object into the desired array format
        for (const [code, ids] of Object.entries(temp)) {
          result.push({ code, ids });
        }
      
        return result;
      }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const countDuplicateSubarrays = (arrayOfArrays) => {
        const arrayMap = new Map();
      
        arrayOfArrays.forEach(subArray => {
          // Convert each subarray to a string to use as a unique key for comparison
          const key = JSON.stringify(subArray);
          if (arrayMap.has(key)) {
            arrayMap.set(key, arrayMap.get(key) + 1);
          } else {
            arrayMap.set(key, 1);
          }
        });
      
        // Convert the map back into an array of arrays with quantities
        return Array.from(arrayMap, ([key, value]) => [JSON.parse(key), value]);
    }

    const [deleteReportIndex, setDeleteReportIndex] = useState(-1)

    const deleteReport = () => {
        console.log(reportID)
        let tokenValues = 
        {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var raw = JSON.stringify(reportID[deleteReportIndex].ids);

    
        var requestOptions = {
          method: 'DELETE',
          headers: myHeaders,
          redirect: 'follow',
          body: raw,
        };
       
        props.tokenSafeAPIRequest('/report/thursday_report', requestOptions, tokenValues, (result,status) => {
            if (status === 200)
            {
                setManageDenyModal(false);
                setNotification('Deleted: ' + getCurrentTimeFormatted())
                deleteRow(deleteReportIndex)
                refreshData()
            } else {setNotification(setNotification(props.getServerResponse(result)))}
        })
    }

    const [cookies, setCookies] = useCookies([])

    const [notification, setNotification] = useState('')

    const [manageDenyModal, setManageDenyModal] = useState(false)

    return (
        <MainLayout buttonList={buttonList} headerData={headerData} colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"Pending Commission Received (OS)"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div></div>{/* <ParentSelector colors={props.colors} parentSelectedItem={parentSelectedItem} setParentSelectedItem={setParentSelectedItem} title={'Admins'} array={parentSelectorArray}></ParentSelector> */}
            <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange}></Table>
            <ModalManager colors={props.colors}>
                <DeleteConfirm colors={props.colors} isOpen={manageDenyModal} setIsOpen={() => setManageDenyModal(false)} onClick={deleteReport}></DeleteConfirm>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
