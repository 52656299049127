import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [skip, setSkip] = useState(0)

    const [loadedData, setLoadedData] = useState([])
    const [stillGettingData, setStillGettingData] = useState(0)

    const isCancelled = useRef(false);

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return user.first_name + ' ' + user.last_name}
        if (payee === 3) {return 'Inside Sales'}
    }

    const handleCancelFetch = () => {
        isCancelled.current = true;
    };

    function transformInputDate(inputDate) {
        // Parse the input date string
        const date = new Date(inputDate);
      
        // Extract the year, month, and day from the input date
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
        const day = date.getUTCDate();
      
        // Format the month and day to ensure they are in 'MM' or 'DD' format
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
      
        // Construct the new date string
        // If you intended to literally use '2123' as the year, replace 'year' with '2123' in the next line
        const newDateStr = `${year}-${formattedMonth}-${formattedDay}`;
      
        return newDateStr;
      }
      

    const addDate = () => {
        console.log(props)
        if ('filterData' in props)
        {
            if ('start_date' in props.filterData)
            {
                return ('&start_date=' + transformInputDate(props.filterData.start_date) + '&end_date=2123-01-01')
            }
            else
            {
                console.log(props.filterData.start_date)
                return ''
            }
        }
        else
        {
            return ''
        }

    }

    const fetchAllData = () => {
        let skip = 0;
        const limit = 15; // Or any other limit you prefer
        let allData = []; // Temporary array to store all fetched data
        setStillGettingData(1)

        console.log('Fetching Data!')
    
        const fetchNext = () => {
            if (isCancelled.current) {
                console.log("Fetching has been cancelled by the user.");
                setStillGettingData(0); // Update state to reflect cancellation (You can choose a different value or approach as needed)
                return; // Exit the function to stop further fetching
            }
            getData(skip, limit, (hasMoreData, newData) => {
                if (hasMoreData && newData) {
                    allData = allData.concat(newData); // Accumulate data
                    skip += limit;
                    fetchNext(); // Recursively fetch next chunk
                } else {
                    console.log("All data has been fetched.");
                    console.log(allData); // Log all fetched data
                    downloadCSV(
                        allData,
                        props.exportKeys,
                        props.fileTitle
                    )
                    setStillGettingData(2)
                    // Optionally, you can also update your state here if needed
                    // setLoadedData(allData);
                }
            });
        };
    
        fetchNext();
    };
    
    // Adjusted getData function to accept a callback with data
    const getData = (skip, limit, onCompletion) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(props.filterData)
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(props.filterData)
        };
    
        props.tokenSafeAPIRequest(props.url + (props.specific !== undefined ? ('/' + props.specific):'') + '?limit=' + limit + '&fetch_links=true&skip=' + skip + '&sort_field=' + props.sort + '&start_date=' + props.calStartDate + '&end_date=' + props.calEndDate + '&active_only=false', requestOptions, '', (result, status) => {
            console.log(result)
            if ('postProcessing' in props)
            {
                result = props.postProcessing(result)
            }
            if ('reports' in result)
            {
                if (result.reports && result.reports.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.reports]);
                    if (onCompletion) onCompletion(true, result.reports); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
            else if ('accounts' in result)
            {
                if (result.accounts && result.accounts.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.accounts]);
                    if (onCompletion) onCompletion(true, result.accounts); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
            else if ('transactions' in result)
            {
                if (result.transactions && result.transactions.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.transactions]);
                    if (onCompletion) onCompletion(true, result.transactions); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
            else if ('dealerships' in result)
            {
                if (result.dealerships && result.dealerships.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.dealerships]);
                    if (onCompletion) onCompletion(true, result.dealerships); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
            else if ('spiffs' in result)
            {
                if (result.spiffs && result.spiffs.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.spiffs]);
                    if (onCompletion) onCompletion(true, result.spiffs); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
            else if ('dppps_and_gap' in result)
                {
                    if (result.dppps_and_gap && result.dppps_and_gap.length > 0) {
                        setLoadedData((loadedData) => [...loadedData, ...result.dppps_and_gap]);
                        if (onCompletion) onCompletion(true, result.dppps_and_gap); // Pass data back
                    } else {
                        if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                    }
                }
            else
            {
                if (result && result.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result]);
                    if (onCompletion) onCompletion(true, result); // Pass data back
                } else {
                    if (onCompletion) onCompletion(false); // No more data, no new data to pass back
                }
            }
        });
    };

    function downloadCSV(data, keys, filename = 'data.csv') {
        // Helper function to flatten nested objects
        const flattenObject = (obj, parentKey = '', res = {}) => {
            for (let key in obj) {
                let propName = parentKey ? parentKey + '.' + key : key;
                if (typeof obj[key] == 'object' && obj[key] !== null && !Array.isArray(obj[key])) {
                    flattenObject(obj[key], propName, res);
                } else {
                    res[propName] = obj[key];
                }
            }
            return res;
        };
    
        // Creating the CSV content
        let csvContent = keys.map(key => key.label).join(',') + '\n'; // Column titles based on 'label'
    
        data.forEach(item => {
            const flatItem = flattenObject(item);
            const row = keys.map(key => {
                let value = flatItem[key.id] ? flatItem[key.id].toString() : '';
                return `"${value.replace(/"/g, '""')}"`; // Handle commas and quotes
            }).join(',');
            csvContent += row + '\n';
        });
    
        // Initiating the download
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            const link = document.createElement('a');
            if (link.download !== undefined) { // Feature detection
                const url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={() => {handleCancelFetch(); props.setIsOpen()}} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={() => {handleCancelFetch(); props.setIsOpen()}}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', fontSize:'16px'}}>Downloaded Rows: {loadedData.length}</span>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => fetchAllData()}>Download Data</Button>
            </div>
        </div>
    )
}

export default Main