import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const categorys = [
        { value: 'Ancillary', label: 'Ancillary' },
        { value: 'VSC', label: 'VSC' },
    ]

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [spliteeTableTitles, setSpliteeTableTitles] = useState([
        {
            title: 'Category',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={onTableChange} index={props.index} indexParent={props.indexParent} data={categorys} width={200} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Contract Name',
            displayElement: (props) => (<TextInput colors={props.colors} onChange={(e) => onTableChange(e, props.index, 1)} prefix='' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Remove',
            displayElement: (props) => (<IconButton image={Delete} onClick={() => deleteRow(props.index, props.tableIDs)} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [spliteeTableTitles[0].default], 
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default], 
    ])

    const [tableData, setTableData] = useState([
        [spliteeTableTitles[0].default],
        [spliteeTableTitles[1].default],
        [spliteeTableTitles[2].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const [spliteeRowSelected, setSpliteeRowSelected] = useState([false])

    useEffect(() => {
        let tempTableValues = [
            [],
            [],
            [],
        ]
        let tempTableData = [
            [],
            [],
            [],
        ]
        props.Admins[props.selectedAdmin].VSC_contracts.map((contract, index) => {
            tempTableValues[0].push('VSC')
            tempTableValues[1].push(contract)
            tempTableValues[2].push('')

            tempTableData[0].push('VSC')
            tempTableData[1].push(contract)
            tempTableData[2].push('')
        })

        props.Admins[props.selectedAdmin].ancillary_contracts.map((contract, index) => {
            tempTableValues[0].push('Ancillary')
            tempTableValues[1].push(contract)
            tempTableValues[2].push('')

            tempTableData[0].push('Ancillary')
            tempTableData[1].push(contract)
            tempTableData[2].push('')
        })

        setTableData(tempTableData)
        setTableValues(tempTableValues)
    },[])

    const [deletedTypes, setDeletedTypes] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((spliteeTableValues2) => spliteeTableValues2.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteeTableTitles[index].default
        ]))
    }

    const deleteRow = (newIndex, currentTableData) => {

        console.log(newIndex, currentTableData)

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedTypes((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: '500px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Contract Types</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={tableValues} tableInfo={spliteeTableTitles} tableIDs={tableData} dropdownData={dropdownData} rows={spliteeRowSelected} setRows={setSpliteeRowSelected} onChange={onTableChange} selectMenu={false} key="Modal"></Table>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Button colors={props.colors} onClick={() => addRow()} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                    <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add New Contract Type</span>
                </div>
                <Button colors={props.colors} onClick={() => props.editContractTypes(tableData, deletedTypes, props.Admins[props.selectedAdmin].id)} active={true} Style={{marginLeft: 'auto', width: '250px', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
