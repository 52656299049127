import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../elements/UserTab'
import Button from '../elements/Button'
import Dropdown from '../elements/ReactSelectDropdown'

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
    */}

    const defaultStyle = 
        {
            display: 'flex',
            flexDirection: 'column',
            margin: '39px',
            marginTop: '15px',
        }
        

    return (
        <motion.div style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <div style={{display: 'flex', flexDirection: 'row'}}>
                <UserTab colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></UserTab>
                {props.buttonText === '' ? '':<Button onClick={() => {props.onClick()}} Style={{marginLeft: '15px', height: '35px', marginTop: '5px'}} colors={props.colors} active={true}>{props.buttonText}</Button>}
                {'buttonText2' in props ? <Button onClick={() => {props.onClick2()}} Style={{marginLeft: '15px', height: '35px', marginTop: '5px'}} colors={props.colors} active={true}>{props.buttonText2}</Button>:''}
            </div>
            <div style={{display: 'flex', flexDirection: 'row', marginTop: '15px'}}>
                <span style={{width: '267px', WebkitLineClamp: 2, textOverflow: 'ellipsis', fontSize: '40px', fontWeight: '500'}}>{props.title}</span>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                    <span style={{flexGrow: 1, fontSize: '20px', fontWeight: '500'}}>Filters</span>
                    <div style={{display: 'flex', flexDirection: 'row', flexGrow: 1}}>
                        {props.searchElements.map((element, index) => <>{element({index: index, colors: props.colors})}</>)}
                    </div>
                </div>
            </div>
        </motion.div>
    )
}

export default Main
