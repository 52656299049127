import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Button from '../elements/Button'

const Main = (props) => {

    {/*
    Documentation
        Props:
            active={false/true} - Swaps default styling to active - False by default
            default={false/true} - Enables default styles entirely - True by default ***STYLE MUST BE SET FOR DEFAULT TO BE SET TO FALSE***
            Style={...styles} - Passthrough to styles for complete control - empty by default
            onClick={function()} - passthrought to onClick to callback a function - empty by default
            children={'string'} - Text on button
    */}

    const defaultStyle = 
        {
            width: '100%',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginTop: '15px'
        }

    return (
        <div style={defaultStyle}>
            <span style={{fontSize: '18px', fontWeight: '600'}}>{props.title}</span>
            <div style={{overflowY: 'scroll', width: '100%', display: 'flex', flexDirection: 'column'}}>
                {props.list.map((item, index) => {
                    return <Button className='center noSelect' colors={props.colors} onClick={() => ''} Style={{padding: '3px 10px 3px 10px', marginTop: '7px', marginRight: '20px', marginLeft: '5px', marginBottom: index === props.list.length - 1 ? '15px':''}}>
                                <span style={{fontWeight: '500'}}>{item}</span>
                            </Button>
                })}
            </div>

        </div>
    )
}

export default Main
