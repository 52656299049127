import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from './IconButton'
import Button from './Button'
import Dropdown from './ReactSelectDropdown'

const Main = (props) => {

    console.log(props)

    return (
        <div style={{backgroundColor: props.colors.block, display: 'flex', flexDirection: 'row', border: '1px solid ' + props.colors.border, borderRadius: '10px', marginTop: '15px', padding: '10px 15px 10px 15px', alignItems:'center', marginRight: '20px'}}>
            <span style={{fontWeight: '600', marginRight: '10px'}}>Filters</span>
            <Button onClick={props.onClick} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px'}}>+</Button>
            <div style={{borderRight: '1px solid ' + props.colors.border, width: '20px', height: '100%'}}></div>
            <div style={{overflowY: 'auto', height: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', flexWrap: 'wrap'}}>
                {Object.entries(props.filterData).map((category, index) => { 
                    return category[1].value.label === "" ? '':<span onClick={() => props.removeFilter(category[1].key)} className='noSelect' style={{backgroundColor: props.colors.highlight, borderRadius: '100px', padding: '3px 10px 3px 10px', marginLeft: '10px', border: '1px solid ' + props.colors.border, cursor: 'pointer', marginTop: '5px'}}>{category[1].value.label === 'True' || category[1].value.label === 'False' ? category[1].title + ': ' + category[1].value.label:category[1].value.label}</span>
                })}
            </div>
            <span style={{fontWeight: '500', color: props.colors.muted, marginLeft: 'auto', marginRight: '10px', borderLeft: '2px solid ' + props.colors.border, paddingLeft: '15px'}}>Sort By: </span>
            <Dropdown data={props.sortOptions} onChange={(e) => props.setSort(e.value)} colors={props.colors} value={props.sortToName[props.sort]} width={'150px'} Style={{border: 0}}></Dropdown>
        </div>
    )
}

export default Main
