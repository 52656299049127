import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import EditAdminModal from '../assets/manageAdmins/EditAdminModal'
import EditContractTypesModal from '../assets/manageAdmins/EditContractTypesModal'
import CreateNewAdminModal from '../assets/manageAdmins/CreateNewAdminModal'

import Edit from '../media/icons/icons8-edit-96.png'
import Delete from '../media/icons/icons8-delete-96.png'

import Notification from '../elements/Notification'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies]= useCookies([])

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const refreshData = () => {
        //setRefresh((refresh) => !refresh)
        refreshAllData();
    }

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        let tempContractList = []

        //get all contract types
        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempContractList.push({value: '', label: "All"})
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
            console.log(tempContractList)
            setFilterData([
                {
                    key: 'resource_state',
                    options: [
                        {value: 1, label: 'Active'},
                        {value: 0, label: 'Not Active'},
                        {value: '', label: 'All'},
                    ],
                    selectedValue: '',
                    selectedDisplay: 'Active Status'
                },
                {
                    key: 'contract_type',
                    options: tempContractList,
                    selectedValue: '',
                    selectedDisplay: 'Contract Types'
                },
            ])
        })
    },[])

    const [filterData, setFilterData] = useState([
        {
            key: 'resource_state',
            options: [
                {value: 1, label: 'Active'},
                {value: 0, label: 'Not Active'},
                {value: '', label: 'All'},
            ],
            selectedValue: '',
            selectedDisplay: 'Active Status'
        },
        {
            key: 'contract_type',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Contract Types'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }
    
    const searchElements = [
        (props) => (<div><TextInput colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1].options} width={195} value={filterData[1].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const debounceTimerRef = useRef();

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Subagents')
            searchAdmins(e);
        }, 1000); // 3000 ms = 3 seconds
    }
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit Admin',
            displayElement: (props) => (<IconButton onClick={() => {setEditAdminModal(true); setSelectedAdmin(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: '',
        },
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Active',
            displayElement: (props) => (<div onClick={() => {updateActiveStatus(props.index, props.tableData, props.tableIDs); onTableChange(props.data === 0 ? 1:0, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:''}}></div>),
            default: false,
        },
        {
            title: 'Contract Types',
            displayElement: (props) => (<Button onClick={() => {setEditContractTypesModal(true); setSelectedAdmin(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Types</Button>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default], 
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e, x, y, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    function convertDateFormat(dateStr) {
        // Parse the date string
        var dateObj = new Date(dateStr);
    
        // Extracting date components
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based in JS
        var day = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear().toString().substr(-2);
    
        // Constructing the new date format
        return month + '/' + day + '/' + year;
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    const [editAdminModal, setEditAdminModal] = useState(false)
    const [editContractTypesModal, setEditContractTypesModal] = useState(false)
    const [createNewAdminModal, setCreateNewAdminModal] = useState(false)
    const [selectedAdmin, setSelectedAdmin] = useState(-1)
    const [admins, setAdmins] = useState([])

    const [notification, setNotification] = useState('')

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
        })
        };

        props.tokenSafeAPIRequest('/admin/search?active_only=false&limit=99999999', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            setAdmins(result)
            let tempAdmins = [
                [],
                [],
                [],
                [],
                [],
            ];
            let tempAdminsData = [
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((admin, index) => {
                tempAdmins[0].push('')
                tempAdmins[1].push(admin.code)
                tempAdmins[2].push(admin.name)
                tempAdmins[3].push(admin.resource_state)
                tempAdmins[4].push('')

                tempAdminsData[0].push('')
                tempAdminsData[1].push(admin.code)
                tempAdminsData[2].push(admin.id)
                tempAdminsData[3].push(admin.resource_state)
                tempAdminsData[4].push('')
            })

            setTableData(tempAdminsData)
            setTableValues(tempAdmins)
        })
    },[])

    const refreshAllData = () => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
        })
        };

        props.tokenSafeAPIRequest('/admin/search?active_only=false&limit=99999999', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            setAdmins(result)
            let tempAdmins = [
                [],
                [],
                [],
                [],
                [],
            ];
            let tempAdminsData = [
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((admin, index) => {
                tempAdmins[0].push('')
                tempAdmins[1].push(admin.code)
                tempAdmins[2].push(admin.name)
                tempAdmins[3].push(admin.resource_state)
                tempAdmins[4].push('')

                tempAdminsData[0].push('')
                tempAdminsData[1].push(admin.code)
                tempAdminsData[2].push(admin.id)
                tempAdminsData[3].push(admin.resource_state)
                tempAdminsData[4].push('')
            })

            if (searchElementValues[0] === '')
            {
                setTableValues(tempAdmins)
                setTableData(tempAdminsData)
            }
            else
            {
                searchAdmins(searchElementValues[0])
            }
        })
    }

    const searchAdmins = (searchValue) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            name: searchValue
        })
        };

        props.tokenSafeAPIRequest('/admin/search?active_only=false&limit=99999999', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            setAdmins(result)
            let tempAdmins = [
                [],
                [],
                [],
                [],
                [],
            ];
            let tempAdminsData = [
                [],
                [],
                [],
                [],
                [],
            ];
            result.map((admin, index) => {
                tempAdmins[0].push('')
                tempAdmins[1].push(admin.code)
                tempAdmins[2].push(admin.name)
                tempAdmins[3].push(admin.resource_state)
                tempAdmins[4].push('')

                tempAdminsData[0].push('')
                tempAdminsData[1].push(admin.code)
                tempAdminsData[2].push(admin.id)
                tempAdminsData[3].push(admin.resource_state)
                tempAdminsData[4].push('')
            })

            setTableValues(tempAdmins)
            setTableData(tempAdminsData)
        })
    }

    const addNewAdmin = (data) => {
        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        };

        props.tokenSafeAPIRequest('/admin', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setCreateNewAdminModal(false)
                setNotification('Created Admin Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editAdmin = (data) => {

        data = setEmptyStringsToNull(data);

        console.log(data)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(data)
        };

        props.tokenSafeAPIRequest('/admin/' + data.id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditAdminModal(false)
                setNotification('Edited Admin Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const deleteAdmin = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/admin/destroy/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditAdminModal(false)
                setNotification('Deleted Admin Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editContractTypes = (data, deletedTypes, id) => {
        console.log(transposeArray(data), deletedTypes, id)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let VSCs = []
        let Ancillarys =[]

        transposeArray(data).map((contract, index) => {
            console.log(contract)
            if (contract[0] === 'VSC')
            {
                VSCs.push(contract[1])
            }
            if (contract[0] === 'Ancillary')
            {
                Ancillarys.push(contract[1])
            }
        })

        let raw = {
            VSC_contracts: VSCs,
            ancillary_contracts: Ancillarys
        }

        console.log(raw)
    
        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(raw)
        };

        props.tokenSafeAPIRequest('/admin/' + id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setEditContractTypesModal(false)
                setNotification('Edited Contract Types Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const updateActiveStatus = (rowIndex, tableValues, tableData) => {

        console.log(tableData)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                resource_state: transposeArray(tableData)[rowIndex][3] === 0 ? 1:0,
            })
        };

        props.tokenSafeAPIRequest('/admin/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Status Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} onClick={() => setCreateNewAdminModal(true)} buttonText={'Add New Admin'} title='Manage Admins' tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            <ManageTable colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} rows={rowSelected} dropdownData={dropdownData} setRows={setRowSelected} onChange={onTableChange} onBottom={() => ''} selectMenu={false}></ManageTable>
            <ModalManager colors={props.colors}>
                <EditAdminModal colors={props.colors} Admins={admins} selectedAdmin={selectedAdmin} editAdmin={editAdmin} deleteAdmin={deleteAdmin} isOpen={editAdminModal} setIsOpen={() => setEditAdminModal(false)}></EditAdminModal>
                <EditContractTypesModal colors={props.colors} isOpen={editContractTypesModal} Admins={admins} selectedAdmin={selectedAdmin} editContractTypes={editContractTypes} setIsOpen={() => setEditContractTypesModal(false)}></EditContractTypesModal>
                <CreateNewAdminModal colors={props.colors} isOpen={createNewAdminModal} setIsOpen={() => setCreateNewAdminModal(false)} addNewAdmin={addNewAdmin}></CreateNewAdminModal>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main
