import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../elements/UserTab.jsx'
import Button from '../elements/Button.jsx'

const Main = (props) => {

    return (
        <div style={{width: '370px', height: '100vh', position: 'fixed', display: 'flex', flexDirection: 'column', borderRight: '1px solid ' + props.colors.border, marginLeft: '20px'}}>
            <div className='verticalCenter' style={{flexBasis: '75px', marginLeft: '20px', marginTop: '10px'}}>
                <UserTab colors={props.colors} initals="JB" tokenSafeAPIRequest={props.tokenSafeAPIRequest}></UserTab>
            </div>
            <div style={{flexBasis: '150px', borderBottom: '1px solid ' + props.colors.border, marginLeft: '20px'}}>
                <span style={{color: props.colors.font, fontSize: '40px', fontWeight: '500'}}>{props.title}</span>
            </div>
            <div className='noSelect' style={{flexBasis: 'auto', overflowY: 'auto'}}>
                {props.buttonList.length > 0 ? <Link to={props.buttonList[0].link}><Button onClick={props.buttonList[0].onClick} colors={props.colors} active={props.activeButton} Style={{width: '80%', margin: 'auto', marginTop: '15px', marginBottom: '10px', height: '40px'}}>{props.buttonList[0].name}</Button></Link>:''}
                {props.buttonList.map((button, index) => {
                    return index === 0 ? '':<Link to={button.link} style={{height: '30px'}}><Button onClick={button.onClick} active={props.selected === index ? true:false} colors={props.colors} Style={{width: '80%', margin: 'auto', marginBottom: '10px', height: '40px'}}>{button.name}</Button></Link>
                })}
            </div>
        </div>
    )
}

export default Main
