import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from './Sidebar'
import Controller from './Controller'
import MetricHeader from './MetricHeader'

const Main = (props) => {

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'start', overflow: 'hidden'}}>
            {props.children[4]}
            {props.children[5]}
            <div style={{width: '57px', height: '100%', flexShrink: 0}}>
                {/*<Sidebar colors={props.colors} tab={0}></Sidebar>*/}
                {props.children[0]}
            </div>  
            <div style={{width: '370px', height: '100%', flexShrink: 0}}>
                {/* {<Controller colors={props.colors} buttonList={props.buttonList} title="Thursday Reporting"></Controller>} */}
                {props.children[1]}
            </div>
            <div style={{width: 'calc(100vw - 370px - 57px)', marginLeft: '40px', display: 'flex', flexDirection: 'column', marginTop: '15px', overflow: 'hidden'}}>
                {props.children[3].props.children.map((item, index) => {
                    return item;
                })}
            </div>
        </div>
    )
}

export default Main
