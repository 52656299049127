import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import Delete from '../../media/icons/icons8-delete-96.png'
import GoTo from '../../media/icons/icons8-share-96.png'

import LinkImg from '../../media/icons/icons8-share-96.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const timeScales = [
        {value: 'days', label: 'Days'},
        {value: 'weeks', label: 'Weeks'},
        {value: 'months', label: 'Months'},
        {value: 'years', label: 'Years'},
        {value: 'inf', label: 'Inf'},
    ]

    const [cookies, setCookies, removeCookies, updateCookies] = useCookies([])

    const [agentList, setAgentList] = useState([])
    const [adminList, setAdminList] = useState([])

    const [tempDealerOwner, setTempDealerOwner] = useState({
        value: '',
        label: ''
    })
    const [tempDealerOwnerDuration, setTempDealerOwnerDuration] = useState()
    const [tempDealerOwnerDurationType, setTempDealerOwnerDurationType] = useState({
        value: '',
        label: ''
    })
    const [possibleDealerOwners, setPossibleDealerOwners] = useState()

    const [tab, setTab] = useState(0)

    const [overrideSplits, setOverrideSplits] = useState('')

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    useEffect(() => {

        console.log(props.dealerAdmins[props.selectedDealer])
        console.log(props.dealerUserConnections[props.selectedDealer])
        console.log(props)

        let userConnections = props.dealerUserConnections[props.selectedDealer]
        let admins = props.dealerAdmins[props.selectedDealer]
        let spiffees = props.dealerSpiffees[props.selectedDealer]

        let tempFlatees = [
            [],
            [],
            [],
            [],
        ]
        let tempFlateesData = [
            [],
            [],
            [],
            [],
        ]
        let tempSplitee = [
            [],
            [],
            [],
            [],
            [],
        ]
        let tempSpliteeData = [
            [],
            [],
            [],
            [],
            [],
        ]
        let tempAdminInfos = [
            [],
            [],
            [],
        ]
        let tempAdminData = [
            [],
            [],
            [],
        ]
        let tempSpiffees = [
            [],
            [],
            [],
        ]
        let tempSpiffeesData = [
            [],
            [],
            [],
        ]
        let tempPossibleDealerOwners = []

        userConnections.map((user, index) => {
            console.log(user)
            if (user.connection_type === 'flatee')
            {
                tempFlatees[0].push(user.user_ID.code)
                tempFlatees[1].push(user.user_ID.first_name + ' ' + user.user_ID.last_name)
                tempFlatees[2].push(user.commission_amount)
                tempFlatees[3].push(user.id)

                tempFlateesData[0].push(user.user_ID.code)
                tempFlateesData[1].push(user.user_ID.id)
                tempFlateesData[2].push(user.commission_amount)
                tempFlateesData[3].push(user.id)
            }
            if (user.connection_type === 'splitee')
            {
                tempSplitee[0].push(user.user_ID.code)
                tempSplitee[1].push(user.user_ID.first_name + ' ' + user.user_ID.last_name)
                tempSplitee[2].push(user.owner)
                tempSplitee[3].push(user.profit_split === 0 ? calculateProfitSplits(userConnections):(user.profit_split*100).toFixed(2))
                tempSplitee[4].push(user.id)

                tempSpliteeData[0].push(user.user_ID.code)
                tempSpliteeData[1].push(user.user_ID.id)
                tempSpliteeData[2].push(user.owner)
                tempSpliteeData[3].push(user.profit_split === 0 ? calculateProfitSplits(userConnections):(user.profit_split*100).toFixed(2))
                tempSpliteeData[4].push(user.id)

                tempPossibleDealerOwners.push({value: user.user_ID.id, label: user.user_ID.first_name + ' ' + user.user_ID.last_name})
            }
        })

        admins.map((admin, index) => {
            console.log(admin)
            tempAdminInfos[0].push(admin.code)
            tempAdminInfos[1].push(admin.name)
            tempAdminInfos[2].push('')

            tempAdminData[0].push(admin.code)
            tempAdminData[1].push(admin.id)
            tempAdminData[2].push('')
        })

        spiffees.map((spiffee, index) => {
            tempSpiffees[0].push(spiffee.code)
            tempSpiffees[1].push(spiffee.name)
            tempSpiffees[2].push('')

            tempSpiffeesData[0].push(spiffee.code)
            tempSpiffeesData[1].push(spiffee.id)
            tempSpiffeesData[2].push('')
        })

        console.log(tempFlatees)
        console.log(tempFlateesData)
        console.log(tempSplitee)
        setFlateeTableValues(tempFlatees)
        setFlateeTableData(tempFlateesData)

        setAdminTableValues(tempAdminInfos)
        setAdminTableData(tempAdminData)

        setSpiffeesTableValues(tempSpiffees)
        setSpiffeesTableData(tempSpiffeesData)

        setSpliteesTableValues(tempSplitee)
        setSpliteesTableData(tempSpliteeData)

        setPossibleDealerOwners(tempPossibleDealerOwners)

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + cookies['access_token']);

        var raw = JSON.stringify(
            {subagent: true}
        );

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        props.tokenSafeAPIRequest('/user/search?limit=9999999', requestOptions, tokenValues, (result) => {
            let dealers = []
            result.map((agent, index) => {
                dealers.push({value: agent.id, label: agent.first_name + " " + agent.last_name})
            })
            console.log(dealers)
            setAgentList(dealers)

            setFlateeTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Name')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={dealers} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                        };
                    }
                    return title;
                })
            })

            setSpliteesTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Name')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={dealers} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                        };
                    }
                    return title;
                })
            })

            props.tokenSafeAPIRequest('/admin/search?limit=9999999', requestOptions, tokenValues, (result) => {
                let admins = []
                result.map((admin, index) => {
                    admins.push({value: admin.id, label: admin.name})
                })
                setAdminsTableTitles((tableTitles) => {
                    return tableTitles.map((title) => {
                        if (title.title === 'Name')
                        {
                            return {
                                ...title,
                                displayElement: (props) => (<Dropdown menuPlacement={props.index > 2 ? 'top':'bottom'} colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={admins} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
                            };
                        }
                        return title;
                    })
                })
            })
        })
    },[])

    const calculateProfitSplits = (connections) => {
        let spliteeCount = 0;
        connections.map((user, index) => {
            if (user.connection_type === 'splitee')
            {
                spliteeCount++;
            }
        })
        return (100/spliteeCount).toFixed(2);
    }

    //SPLITEE CODE

    const [deletedSplitees, setDeletedSplitees] = useState([])

    const [spliteesTableTitles, setSpliteesTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Owner',
            displayElement: (props) => (<div onClick={() => props.onChange(!props.data, props.index, props.indexParent)} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:tempDealerHelper(props.tableIDs[1][props.index], props.dealerTempOwnerId) ? props.colors.border:''}}></div>),
            default: '',
        },
        {
            title: 'Split',
            displayElement: (props) => (<TextInput onChange={(e) => props.onChange(e, props.index, props.indexParent)} colors={props.colors} prefix='' suffix='%' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: '',
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => deleteRowSplitees(props.index, props.tableIDs)} image={Delete} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: '',
        },
    ])

    const tempDealerHelper = (tableID, tempOwnerId) => {
        if (tempOwnerId !== null)
        {
            if (tableID === tempOwnerId.id)
            {
                return true;
            }
            else
            {
                return false
            }
        }
        else
        {
            return false;
        }
    }

    const [spliteesTableValues, setSpliteesTableValues] = useState([
        [spliteesTableTitles[0].default], 
        [spliteesTableTitles[1].default],
        [spliteesTableTitles[2].default], 
        [spliteesTableTitles[3].default],
        [spliteesTableTitles[4].default], 
    ])

    const [spliteesTableData, setSpliteesTableData] = useState([
        [spliteesTableTitles[0].default],
        [spliteesTableTitles[1].default],
        [spliteesTableTitles[2].default],
        [spliteesTableTitles[3].default],
        [spliteesTableTitles[4].default], 
    ])

    const [spliteesDropdownData, setSpliteesDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const addRowSplitees = () => {
        console.log('Add Row!')
        setSpliteesTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            spliteesTableTitles[index].default
        ]))
        setSpliteesDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setSpliteesTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spliteesTableTitles[index].default
        ]))
    }

    const deleteRowSplitees = (newIndex, currentTableData) => {

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedSplitees((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setSpliteesTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setSpliteesDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setSpliteesTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onSpliteesTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setSpliteesTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setSpliteesTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    //END SPLITEE CODE

    //SPIFFEES CODE

    const [deletedSpiffees, setDeletedSpiffees] = useState([])

    const [spiffeesTableTitles, setSpiffeesTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Link',
            displayElement: (props) => (<Link to="/manage/spiffees"><IconButton onClick={() => ''} image={LinkImg} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.primary, borderRadius: '5px'}}></IconButton></Link>),
            default: undefined,
        },
    ])

    const [spiffeesTableValues, setSpiffeesTableValues] = useState([
        [spiffeesTableTitles[0].default], 
        [spiffeesTableTitles[1].default],
        [spiffeesTableTitles[2].default], 
    ])

    const [spiffeesTableData, setSpiffeesTableData] = useState([
        [spiffeesTableTitles[0].default],
        [spiffeesTableTitles[1].default],
        [spiffeesTableTitles[2].default],
    ])

    const [spiffeesDropdownData, setSpiffeesDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const addRowSpiffees = () => {
        console.log('Add Row!')
        setSpiffeesTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            spiffeesTableTitles[index].default
        ]))
        setSpiffeesDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setSpiffeesTableData((tableData) => tableData.map((column, index) => [
            ...column,
            spiffeesTableTitles[index].default
        ]))
    }

    const deleteRowSpiffees = (newIndex, currentTableData) => {

        console.log(transposeArray(currentTableData)[newIndex])

        //setDeletedSpiffees((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setSpiffeesTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setSpiffeesDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setSpiffeesTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onSpiffeesTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setSpiffeesTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setSpiffeesTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    //END SPIFFEES CODE

    //ADMIN CODE

    const [deletedAdmins, setDeletedAdmins] = useState([])

    const [adminTableTitles, setAdminsTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => deleteRowAdmins(props.index, props.tableIDs)} image={Delete} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: undefined,
        },
    ])

    const [adminTableValues, setAdminTableValues] = useState([
        [adminTableTitles[0].default], 
        [adminTableTitles[1].default],
        [adminTableTitles[2].default], 
    ])

    const [adminTableData, setAdminTableData] = useState([
        [adminTableTitles[0].default],
        [adminTableTitles[1].default],
        [adminTableTitles[2].default],
    ])

    const [adminDropdownData, setAdminDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const addRowAdmins = () => {
        console.log('Add Row!')
        setAdminTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            adminTableTitles[index].default
        ]))
        setAdminDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setAdminTableData((tableData) => tableData.map((column, index) => [
            ...column,
            adminTableTitles[index].default
        ]))
    }

    const deleteRowAdmins = (newIndex, currentTableData) => {

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedAdmins((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setAdminTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setAdminDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setAdminTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onAdminTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setAdminTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setAdminTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    //END ADMIN CODE

    //FLATEE CODE

    const [deletedFlatees, setDeletedFlatees] = useState([])

    const [flateeTableTitles, setFlateeTableTitles] = useState([
        {
            title: 'ID',
            displayElement: (props) => (<span style={{width: '50px', marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '-',
        },
        {
            title: 'Name',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={260} value={props.data} Style={{marginLeft: '30px', marginRight: '30px'}}></Dropdown>),
            default: '',
        },
        {
            title: 'Amount',
            displayElement: (props) => (<TextInput onChange={(e) => props.onChange(e, props.index, props.indexParent)} colors={props.colors} prefix='$' suffix='' data={props.data} Style={{width: '100px'}}></TextInput>),
            default: 0,
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => deleteRowFlatees(props.index, props.tableIDs)} image={Delete} colors={props.colors} size="24px" Style={{padding: '3px', backgroundColor: props.colors.warning, borderRadius: '5px'}}></IconButton>),
            default: undefined,
        },
    ])

    const [flateeTableValues, setFlateeTableValues] = useState([
        [flateeTableTitles[0].default], 
        [flateeTableTitles[1].default],
        [flateeTableTitles[2].default], 
        [flateeTableTitles[3].default], 
    ])

    const [flateeTableData, setFlateeTableData] = useState([
        [flateeTableTitles[0].default],
        [flateeTableTitles[1].default],
        [flateeTableTitles[2].default],
        [flateeTableTitles[3].default], 
    ])

    const [flateeDropdownData, setFlateeDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
    ])

    const addRowFlatees = () => {
        console.log('Add Row!')
        setFlateeTableValues((spliteeTableValues) => spliteeTableValues.map((column, index) => [
            ...column,
            flateeTableTitles[index].default
        ]))
        setFlateeDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setFlateeTableData((tableData) => tableData.map((column, index) => [
            ...column,
            flateeTableTitles[index].default
        ]))
    }

    const deleteRowFlatees = (newIndex, currentTableData) => {

        console.log(transposeArray(currentTableData)[newIndex])

        setDeletedFlatees((deletedFlatees) => [...deletedFlatees, transposeArray(currentTableData)[newIndex]])

        setFlateeTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
        setFlateeDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, newIndex), ...data.slice(newIndex + 1)];
        }))
        setFlateeTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, newIndex), ...column.slice(newIndex + 1)];
        }))
    }

    const onFlateeTableChange = (e, y, x) => {
        console.log('Table Updated', e, x, y)
        setFlateeTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setFlateeTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    //END FLATEE CODE

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>

            {/*Header*/}

            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Connections</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', height: '35px', marginTop: '20px'}}>
                <Button onClick={() => setTab(0)} colors={props.colors} active={tab === 0 ? true:false} Style={{flexGrow: 1, marginRight: '5px'}}>Splitees</Button>
                <Button onClick={() => setTab(1)} colors={props.colors} active={tab === 1 ? true:false} Style={{flexGrow: 1, marginLeft: '5px', marginRight: '5px'}}>Flatees</Button>
                <Button onClick={() => setTab(2)} colors={props.colors} active={tab === 2 ? true:false} Style={{flexGrow: 1, marginRight: '5px', marginLeft: '5px'}}>Admins</Button>
                <Button onClick={() => setTab(3)} colors={props.colors} active={tab === 3 ? true:false} Style={{flexGrow: 1, marginLeft: '5px'}}>Spiffees</Button>
            </div>

            {/*Splittees*/}

            {tab === 0 ? <div style={{display: 'flex', flexDirection: 'column', height: '650px'}}>
                <div onClick={() => setOverrideSplits((overrideSplits) => !overrideSplits)} className='noSelect' style={{display: 'flex', flexDirection: 'row', width: '50%', marginTop: '20px', cursor: 'pointer'}}>
                    <div style={{cursor: 'pointer', width: '20px', height: '20px', border: overrideSplits === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: overrideSplits === true  ? props.colors.primary:'', borderRadius: '5px'}}></div>
                    <span style={{fontWeight: '500', marginLeft: '10px'}}>Manually Override Splits</span>
                </div>
                <Table colors={props.colors} keys={generateKey()} tableData={spliteesTableValues} tableIDs={spliteesTableData} tableInfo={spliteesTableTitles} dropdownData={spliteesDropdownData} dealerTempOwnerId={props.dealerTempOwnerId} onChange={onSpliteesTableChange} selectMenu={false} key='Modal'></Table> 
                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px'}}>
                    <Button onClick={() => addRowSplitees()} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px'}}>+</Button>
                    <span style={{marginTop: '4px', fontWeight: '500', marginLeft: '15px'}}>Add New Splittee</span>
                </div>

                {/*Temp Dealer Owner}*/}

                <div style={{display: 'flex', flexDirection: 'column', marginTop: '0px', paddingLeft: '20px'}}>
                    <span style={{fontWeight: '500'}}>Temporary Dealer Owner</span>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Dropdown onChange={(e) => setTempDealerOwner(e)} colors={props.colors} data={possibleDealerOwners} value={tempDealerOwner.label === null ? '':tempDealerOwner.label} Style={{width: '300px', marginRight: '10px'}}></Dropdown>
                        <TextInput onChange={(e) => setTempDealerOwnerDuration(e)} colors={props.colors} data={tempDealerOwnerDuration} prefix='' type="number" Style={{width: '100px'}}></TextInput>
                        <Dropdown onChange={(e) => setTempDealerOwnerDurationType(e)} colors={props.colors} data={timeScales} value={tempDealerOwnerDurationType.label}></Dropdown>
                        <Button onClick={() => props.setTempDealerOwner(tempDealerOwner, tempDealerOwnerDuration, tempDealerOwnerDurationType)} colors={props.colors} active={true} Style={{width: '100px', marginLeft: '10px'}}>Set</Button>
                    </div>
                </div>
                <Button onClick={() => props.editSpliteesInfo(spliteesTableData, deletedSplitees, overrideSplits)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Save</Button>
            </div>:''}

            {/*Flatees*/}

            {tab === 1 ? <div style={{display: 'flex', flexDirection: 'column', height: '500px'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={flateeTableValues} tableIDs={flateeTableData} tableInfo={flateeTableTitles} dropdownData={flateeDropdownData} onChange={onFlateeTableChange} selectMenu={false} key='Modal'></Table>
                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px'}}>
                    <Button onClick={() => addRowFlatees()} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px'}}>+</Button>
                    <span style={{marginTop: '4px', fontWeight: '500', marginLeft: '15px'}}>Add New Flatees</span>
                </div>
                <Button onClick={() => props.editFlateesInfo(flateeTableData, deletedFlatees)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Save</Button>
            </div>:''}

            {/*Admins*/}

            {tab === 2 ? <div style={{display: 'flex', flexDirection: 'column', height: '500px'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={adminTableValues} tableIDs={adminTableData} tableInfo={adminTableTitles} dropdownData={adminDropdownData} onChange={onAdminTableChange} selectMenu={false} key='Modal'></Table>
                <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px'}}>
                    <Button onClick={() => addRowAdmins()} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px'}}>+</Button>
                    <span style={{marginTop: '4px', fontWeight: '500', marginLeft: '15px'}}>Add New Admin</span>
                </div>
                <Button onClick={() => props.editAdminsInfo(adminTableData, deletedAdmins)} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Save</Button>
            </div>:''}

            {/*Spiffees*/}

            {tab === 3 ? <div style={{display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={spiffeesTableValues} tableInfo={spiffeesTableTitles} dropdownData={spiffeesDropdownData} onChange={onSpiffeesTableChange} selectMenu={false} key='Modal'></Table>
                <Button onClick={() => props.setIsOpen()} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px', marginTop: '15px'}}>Close</Button>
            </div>:''}
        </div>
    )
}

export default Main
