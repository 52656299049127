import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    let tempIndex = -1

    console.log(props)

    const [dealershipReserveAccounts, setDealershipReserveAccounts] = useState([])

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/account/dealership/' + props.selectedDealer.id, requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempReserveAccounts = []
            result.map((account, index) => {
                tempReserveAccounts.push({value: account.id, label: account.name})
            })
            setDealershipReserveAccounts(tempReserveAccounts)
            // let tempDealershipSpiffees = [];
            // result.map((spiffee, index) => {
            //     tempDealershipSpiffees.push({value: spiffee.id, label: spiffee.first_name === null ? spiffee.name:spiffee.first_name + ' ' + spiffee.last_name})
            // })
            // tempDealershipSpiffees.push({value: 'dealership_spiff', label: props.selectedDealer.name})
            // setDealershipSpiffees(tempDealershipSpiffees)
        })
    },[])

    const [data, setData] = useState({
        account: {id: '', name: ''},
        amount: '',
        reason: '',
    })

    const onChange = (e, key) => {
        setData((data) => {
            return ({
                ...data,
                [key]: key === 'account' ? {id: e.value, name: e.label}:e
            })
        })
    }

    const clearReport = () => {
        setData({
            account: {id: '', name: ''},
            amount: '',
            reason: '',
        })
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Add Reserve Input</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span>Account</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 'account')} index={props.index} indexParent={props.indexParent} data={dealershipReserveAccounts} value={data.account.name}></Dropdown></div>
                <span style={{marginTop: '15px'}}>Amount</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 'amount')} colors={props.colors} data={data.amount}></TextInput>
                <span style={{marginTop: '15px'}}>Reason</span>
                <TextInput Style={{marginTop: '5px'}} onChange={(e) => onChange(e, 'reason')} colors={props.colors} data={data.reason}></TextInput>
                <Button onClick={() => {props.setIsOpen(); props.addReserveAccount(data); clearReport();}} Style={{marginTop: '35px'}} active={true} colors={props.colors}>Add</Button>
            </div>
        </div>
    )
}

export default Main
