import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const [data, setData] = useState({
        dealership: '',
        reserve_account: '',
        amount: 0,
        recipient: '',
        ATTN: '',
        reason: '',
    })

    const [displayData, setDisplayData] = useState({
        dealership: '',
        reserve_account: '',
        amount: 0,
        recipient: '',
        ATTN: '',
        reason: '',
    })

    const onDataChange = (e, value, dropdown) => {
        console.log(e, value, dropdown)
        if (dropdown)
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label,
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value,
            }))
            if (value === 'dealership')
            {
                loadReserveAccounts(e.value)
                onDataChange({value: '', label: ''}, 'reserve_account', true)
            }
            if (value === 'admin')
            {
                //loadContractTypes(e.value)
            }
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    const [subagentDealers, setSubagentDealers] = useState([])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/user/my_dealerships?reverse_sort=true', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setSubagentDealers(tempDealerList)
        })


    },[])

    const [dealerReserveAccounts, setDealerReserveAccounts] = useState([])
    const [dealerSubagentSpiffes, setDealerSubagentSpiffees] = useState([])

    const loadReserveAccounts = (id) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/account/dealership/' + id, requestOptions, tokenValues, (result) => {
            console.log(result)

            let tempReserveAccountsList = []

            result.map((account, index) => {
                tempReserveAccountsList.push({value: account.id, label: account.name})
            })
            setDealerReserveAccounts(tempReserveAccountsList)
        })
    }


    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>View Reserve Totals Request</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: '500'}}>Dealer</span>
                <Dropdown colors={props.colors} onChange={(e) => onDataChange(e, 'dealership', true)} index={props.index} indexParent={props.indexParent} data={subagentDealers} value={displayData.dealership}></Dropdown>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Account</span>
                <Dropdown colors={props.colors} onChange={(e) => onDataChange(e, 'reserve_account', true)} index={props.index} indexParent={props.indexParent} data={dealerReserveAccounts} value={displayData.reserve_account}></Dropdown>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Reason</span>
                <TextInput onChange={(e) => onDataChange(e, 'reason')} colors={props.colors} data={displayData.reason}></TextInput>
                <Button Style={{marginTop: '20px'}} onClick={() => props.submitViewReserveTotal(data)} active={true} colors={props.colors}>Request</Button>
            </div>
        </div>
    )
}

export default Main
