import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    console.log(props)

    const [skip, setSkip] = useState(0)

    const [loadedData, setLoadedData] = useState([])
    const [stillGettingData, setStillGettingData] = useState(0)

    const isCancelled = useRef(false);

    const convertPayeeToUser = (payee, user, spiffee, dealership) => {
        if (payee === null) {return 'No User'}
        if (payee === 0) {return spiffee.name}
        if (payee === 1) {return dealership.name}
        if (payee === 2) {return user.first_name + ' ' + user.last_name}
        if (payee === 3) {return 'Inside Sales'}
    }

    const handleCancelFetch = () => {
        isCancelled.current = true;
    };

    function transformInputDate(inputDate) {
        // Parse the input date string
        const date = new Date(inputDate);
      
        // Extract the year, month, and day from the input date
        const year = date.getUTCFullYear();
        const month = date.getUTCMonth() + 1; // getUTCMonth() returns 0-11
        const day = date.getUTCDate();
      
        // Format the month and day to ensure they are in 'MM' or 'DD' format
        const formattedMonth = month < 10 ? `0${month}` : month;
        const formattedDay = day < 10 ? `0${day}` : day;
      
        // Construct the new date string
        // If you intended to literally use '2123' as the year, replace 'year' with '2123' in the next line
        const newDateStr = `${year}-${formattedMonth}-${formattedDay}`;
      
        return newDateStr;
      }
      

    const addDate = () => {
        console.log(props.filterData.start_date)
        if (props.filterData.start_date !== undefined)
        {
            return ('&start_date=' + transformInputDate(props.filterData.start_date) + '&end_date=2123-01-01')
        }
        else
        {
            return ''
        }
    }
    
    // Adjusted getData function to accept a callback with data
    const getData = (skip, limit) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log(props.filterData)
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(props.filterData)
        };
    
        props.tokenSafeAPIRequest(props.url + (props.specific !== undefined ? ('/' + props.specific):'') + '?limit=' + limit + '&fetch_links=true&skip=' + skip + '&sort_field=' + props.sort, requestOptions, '', (result, status) => {
            console.log(result)
            if ('postProcessing' in props)
            {
                result = props.postProcessing(result)
            }
            if ('reports' in result)
            {
                if (result.reports && result.reports.length > 0) {
                    setLoadedData((loadedData) => [...loadedData, ...result.reports]);
                } else {

                }
            }
            if (result.admin_report_info.length > 0)
            {
                setLoadedData((loadedData) => [...loadedData, ...result.admin_report_info]);
                downloadCSV(result.admin_report_info, props.exportKeys, props.fileTitle)
            }
        });
    };

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    function downloadCSV(data, keys, filename = 'data.csv', info) {
        console.log(data, keys, filename, info)
        data = data.map((table) => {
            return transposeArray(table)
        })
        info = info.map((table) => {
            return {
                admin_name: table.admin_ID.name,
                report_type: table.report_subtype,
                total_contract_count: table.total_contract_count,
                total_net: table.total_net,
                total_contract_gross: table.total_contract_gross,
                total_spiffee_payout: table.total_spiffee_payout,
            }
        })
        console.log(data, keys, filename, info)
        exportToCsv("report.csv", info, data);
    }

    function exportToCsv(filename, admins, reports) {
        // Merge admin data with report data
        const mergedData = admins.map((admin, i) => ({
            ...admin,
            details: reports[i]
        }));
    
        // Create CSV content
        let csvContent = "";
    
        // Add Summary section
        csvContent += "Summary\r\n";
        let totalNetSum = 0;
        let totalContractCountSum = 0;
    
        mergedData.forEach((adminData) => {
            const { admin_name, report_type, total_net, total_contract_count } = adminData;
            csvContent += `${admin_name},${report_type},${total_net},${total_contract_count}\r\n`;
            totalNetSum += total_net;
            totalContractCountSum += total_contract_count;
        });
    
        // Add Total row
        csvContent += `total,,${totalNetSum.toFixed(2)},${totalContractCountSum.toFixed(2)}\r\n\r\n`;
    
        console.log(mergedData);
    
        mergedData.forEach((adminData) => {
            // Add Admin title
            csvContent += `${adminData.admin_name} - ${adminData.report_type}\r\n`;
            // Add Headers
            csvContent += "Dealer,Contract Type,Gross/c,Spiffs/c,Net/c,CC/c,QTY,Total Net,Total CC,Unique Identifier\r\n";
            // Add report data
            adminData.details.forEach((detail) => {
                csvContent += detail.join(",") + "\r\n";
            });
            // Add a newline to separate sections
            csvContent += "\r\n";
        });
    
        // Create a blob with the CSV content
        const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, filename);
        } else {
            // Create a link element and download the CSV file
            const link = document.createElement("a");
            if (link.download !== undefined) { // feature detection for download attribute
                const url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", filename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }
    

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Export Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={() => {handleCancelFetch(); props.setIsOpen()}} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={() => {handleCancelFetch(); props.setIsOpen()}}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', fontSize:'16px'}}>Downloaded Rows: {loadedData.length}</span>
                <Button colors={props.colors} active={true} Style={{marginTop: '10px'}} onClick={() => downloadCSV(props.reportData, props.exportKeys, props.fileTitle, props.admin_report_info)}>Download Data</Button>
            </div>
        </div>
    )
}

export default Main
