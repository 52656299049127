import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [data, setData] = useState({
        first_name: '',
        middle_name: '',
        last_name: '',
        code: '',
        position: '',
        city: '',
        state_code: '',
        phone_number: '',
        birthday: '',
        email: '',
        SSN: '',
        address: '',
        ITIN: '',
        zip_code: '',
        corporate_spiffee: false,
    })

    
    const onDataChange = (e, value) => {
        if (value === 'corporate_spiffee')
        {
            //will reset data if he swaps. Probably should of changed this, but he said its ok to leave. If he ever changes his mind, its here.
            // setData({
            //     first_name: '',
            //     middle_name: '',
            //     last_name: '',
            //     code: '',
            //     position: '',
            //     city: '',
            //     state_code: '',
            //     phone_number: '',
            //     birthday: '',
            //     email: '',
            //     SSN: '',
            //     address: '',
            //     ITIN: '',
            //     zip_code: '',
            //     corporate_spiffee: false,
            // })
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
        else
        {
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    return (
        <div style={{width: '700px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Create New Spiffee</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>{data.corporate_spiffee === false ? 'First Name':'Name'}</span>
                        <TextInput onChange={(e) => data.corporate_spiffee ? onDataChange(e, 'name'):onDataChange(e, 'first_name')} colors={props.colors} data={data.corporate_spiffee ? data.name:data.first_name} prefix=''></TextInput>
                    </div>
                    {data.corporate_spiffee ? '':<div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Last Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'last_name')} colors={props.colors} data={data.last_name} prefix=''></TextInput>
                    </div>}
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Middle Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'middle_name')} colors={props.colors} data={data.middle_name} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Position</span>
                        <TextInput onChange={(e) => onDataChange(e, 'position')} colors={props.colors} data={data.position} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Street</span>
                        <TextInput onChange={(e) => onDataChange(e, 'address')} colors={props.colors} data={data.address} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>City</span>
                        <TextInput onChange={(e) => onDataChange(e, 'city')} colors={props.colors} data={data.city} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>State</span>
                        <TextInput onChange={(e) => onDataChange(e, 'state_code')} colors={props.colors} data={data.state_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Zip Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'zip_code')} colors={props.colors} data={data.zip_code} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Phone Number - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'phone_number')} colors={props.colors} data={data.phone_number} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Birthday - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'birthday')} colors={props.colors} data={data.birthday} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Email - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={data.email} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>SSN - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'SSN')} colors={props.colors} data={data.SSN} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>ITIN</span>
                        <TextInput onChange={(e) => onDataChange(e, 'ITIN')} colors={props.colors} data={data.ITIN} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'code')} colors={props.colors} data={data.code} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <div onClick={() => onDataChange(!data.corporate_spiffee, 'corporate_spiffee')} style={{cursor: 'pointer', width: '20px', height: '20px', border: data.corporate_spiffee === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: data.corporate_spiffee === true  ? props.colors.primary:''}}></div>
                    <span style={{marginLeft: '10px'}}>Corporate Spiffee</span>
                </div>
                <Button onClick={() => {props.addNewSpiffee(data);}} colors={props.colors} active={true} Style={{width: '150px', marginLeft: 'auto', marginTop: '15px'}}>Add</Button>
            </div>
        </div>
    )
}

export default Main
