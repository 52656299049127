import * as React from "react";

import { CompactTable } from "@table-library/react-table-library/compact";
import { useTheme } from "@table-library/react-table-library/theme";
import { getTheme } from "@table-library/react-table-library/baseline";

import IconButton from '../elements/IconButton'
import Button from '../elements/Button'

import Edit from '../media/icons/icons8-edit-96.png'

import { Table, Header, HeaderRow, Body, Row, HeaderCell, Cell, } from '@table-library/react-table-library/table';

const Component = (props) => {

    console.log(props)

    const nodes = props.data.flat();

    console.log(nodes)

    const data = { nodes };

    const key = 'Composed Table';

    const theme = useTheme(getTheme());

    const resize = { minWidth: 800 };

    {/* <IconButton onClick={() => ''} Style={{backgroundColor: props.colors.primary}} size={27} image={Edit} colors={props.colors}></IconButton>
                <Cell><span >{item.name === null ? '-':item.name}</span></Cell>
                <Cell><span >{item.subagent_owner_ID.first_name + ' ' + item.subagent_owner_ID.last_name}</span></Cell>
                <Cell><span >{item.code === null ? '-':item.code}</span></Cell>
                <Cell><div style={{cursor: 'pointer', width: '28px', height: '28px', border: item.weekly_7 === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: item.weekly_7 === true  ? props.colors.primary:''}}></div></Cell>
                <Cell><IconButton onClick={() => ''} Style={{backgroundColor: props.colors.primary}} size={27} image={Edit} colors={props.colors}></IconButton></Cell>
                <Cell><Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{item.name}</Button></Cell>
                <Cell><Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{item.name}</Button></Cell>
                <Cell><Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{item.name}</Button></Cell>
                <Cell><div style={{cursor: 'pointer', width: '28px', height: '28px', border: false === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: false === true  ? props.colors.primary:''}}></div></Cell> */}

  return (
    <Table data={data} theme={theme}>
      {(tableList) => (
        <>
          <Header>
            <HeaderRow>
              <HeaderCell>Edit Dealer</HeaderCell>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Owner</HeaderCell>
              <HeaderCell>Code</HeaderCell>
              <HeaderCell>Weekly 7</HeaderCell>
              <HeaderCell>DPPP / GAP Plus</HeaderCell>
              <HeaderCell>Contact</HeaderCell>
              <HeaderCell>Reserve Account</HeaderCell>
              <HeaderCell>Connections</HeaderCell>
              <HeaderCell>Active</HeaderCell>
            </HeaderRow>
          </Header>

          <Body>
            {tableList.map((item) => (
              <Row key={item.id} item={item}>
                {props.displayComponents.map((element, index) => {
                    return <Cell>{element({item: item, colors: props.colors})}</Cell>
                })}
              </Row>
            ))}
          </Body>
        </>
      )}
    </Table>
  );
};

export default Component;