import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import ManageLayout from '../elements/ManageLayout'
import Sidebar from '../elements/Sidebar'
import ManageHeader from '../elements/ManageHeader'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import ManageTable from '../elements/ManageTable'
import IconButton from '../elements/IconButton'
import Button from '../elements/Button'
import ModalManager from '../elements/ModalManager'

import EditDealerModal from '../assets/manageDealers/EditDealerModal'
import EditConnectionsModal from '../assets/manageDealers/EditConnectionsModal'
import EditContactInfoModal from '../assets/manageDealers/EditContactInfoModal'
import EditReserveAccountsModal from '../assets/manageDealers/EditReserveAccountsModal'
import EditDPPPGapModal from '../assets/manageDealers/EditDPPPGapModal'
import CreateNewDealerModal from '../assets/manageDealers/CreateNewDealerModal'

import TableTest from '../elements/TableTest'

import Edit from '../media/icons/icons8-edit-96.png'

import { useCookies } from 'react-cookie'

import ExportData from '../assets/metrics/ExportData'

import Notification from '../elements/Notification'

const Main = (props) => {

    const [cookies, setCookie, removeCookie, updateCookies] = useCookies([])

    const state_list = [
        {value: '', label: 'All States'},
        {value: 'AL', label: 'AL'},
        {value: 'AK', label: 'AK'},
        {value: 'AZ', label: 'AZ'},
        {value: 'AR', label: 'AR'},
        {value: 'CA', label: 'CA'},
        {value: 'CO', label: 'CO'},
        {value: 'CT', label: 'CT'},
        {value: 'DE', label: 'DE'},
        {value: 'FL', label: 'FL'},
        {value: 'GA', label: 'GA'},
        {value: 'HI', label: 'HI'},
        {value: 'ID', label: 'ID'},
        {value: 'IL', label: 'IL'},
        {value: 'IN', label: 'IN'},
        {value: 'IA', label: 'IA'},
        {value: 'KS', label: 'KS'},
        {value: 'KY', label: 'KY'},
        {value: 'LA', label: 'LA'},
        {value: 'ME', label: 'ME'},
        {value: 'MD', label: 'MD'},
        {value: 'MA', label: 'MA'},
        {value: 'MI', label: 'MI'},
        {value: 'MN', label: 'MN'},
        {value: 'MS', label: 'MS'},
        {value: 'MO', label: 'MO'},
        {value: 'MT', label: 'MT'},
        {value: 'NE', label: 'NE'},
        {value: 'NV', label: 'NV'},
        {value: 'NH', label: 'NH'},
        {value: 'NJ', label: 'NJ'},
        {value: 'NM', label: 'NM'},
        {value: 'NY', label: 'NY'},
        {value: 'NC', label: 'NC'},
        {value: 'ND', label: 'ND'},
        {value: 'OH', label: 'OH'},
        {value: 'OK', label: 'OK'},
        {value: 'OR', label: 'OR'},
        {value: 'PA', label: 'PA'},
        {value: 'RI', label: 'RI'},
        {value: 'SC', label: 'SC'},
        {value: 'SD', label: 'SD'},
        {value: 'TN', label: 'TN'},
        {value: 'TX', label: 'TX'},
        {value: 'UT', label: 'UT'},
        {value: 'VT', label: 'VT'},
        {value: 'VA', label: 'VA'},
        {value: 'WA', label: 'WA'},
        {value: 'WV', label: 'WV'},
        {value: 'WI', label: 'WI'},
        {value: 'WY', label: 'WY'},
    ];

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempContractList = []
        let tempDealerList = []
        let tempSubagentList = []
        let tempSpiffeesList = []
        let tempAdminList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        props.tokenSafeAPIRequest('/admin/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempAdminList.push({value: '', label: 'All Admins'})
            result.map((admin, index) => {
                tempAdminList.push({value: [admin.id], label: admin.name})
            })
        })

        props.tokenSafeAPIRequest('/spiffee/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSpiffeesList.push({value: '', label: 'All Spiffees'})
            result.map((spiffee, index) => {
                tempSpiffeesList.push({value: [spiffee.id], label: spiffee.name})
            })
        })

        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
        }

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            tempSubagentList.push({value: '', label: 'All Subagents'})
            result.map((subagent, index) => {
                tempSubagentList.push({value: [subagent.id], label: subagent.name})
            })
        })

        setFilterData([
            {
                key: 'state_code',
                options: state_list,
                selectedValue: '',
                selectedDisplay: 'State'
            },
            {
                key: 'splitee_IDs',
                options: tempSubagentList,
                selectedValue: '',
                selectedDisplay: 'Splittee'
            },
            {
                key: 'spiffee_IDs',
                options: tempSpiffeesList,
                selectedValue: '',
                selectedDisplay: 'Spiffee'
            },
            {
                key: 'weekly_7',
                options: [
                    {value: '', label: 'All'},
                    {value: true, label: 'Has Weekly 7'},
                    {value: false, label: 'No Weekly 7'},
                ],
                selectedValue: '',
                selectedDisplay: 'Weekly 7'
            },
            {
                key: 'admin_IDs',
                options: tempAdminList,
                selectedValue: '',
                selectedDisplay: 'Admin'
            },
        ])
    },[])

    const [filterData, setFilterData] = useState([
        {
            key: 'state_code',
            options: state_list,
            selectedValue: '',
            selectedDisplay: 'State'
        },
        {
            key: 'splitee_IDs',
            options: [
                {value: '', label: 'All'},
                {value: true, label: 'Has Deal'},
                {value: false, label: 'No Deal'},
            ],
            selectedValue: '',
            selectedDisplay: 'Splittee'
        },
        {
            key: 'spiffee_IDs',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Spiffee'
        },
        {
            key: 'weekly_7',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Weekly 7'
        },
        {
            key: 'admin_IDs',
            options: [],
            selectedValue: '',
            selectedDisplay: 'Admin'
        },
    ])

    const handleFilterDataChange = (index, value) => {
        setFilterData((filterData) => filterData.map((filter, index2) => {
            if (index2 === index)
            {
                filter.selectedValue = value.value;
                filter.selectedDisplay = value.label;
            }
            return filter;
        }))
        refreshAllData()
    }
    
    const searchElements = [
        (props) => (<div><TextInput placeholder='Search' colors={props.colors} onChange={(e) => onSearchElementUpdate(e, props.index)} prefix="" width={270} data={searchElementValues[0]}></TextInput></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(0, e)} index={props.index} indexParent={props.indexParent} data={filterData[0].options} width={195} value={filterData[0].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(1, e)} index={props.index} indexParent={props.indexParent} data={filterData[1].options} width={195} value={filterData[1].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(2, e)} index={props.index} indexParent={props.indexParent} data={filterData[2].options} width={195} value={filterData[2].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(3, e)} index={props.index} indexParent={props.indexParent} data={filterData[3].options} width={195} value={filterData[3].selectedDisplay}></Dropdown></div>),
        (props) => (<div style={{marginLeft: '15px'}}><Dropdown colors={props.colors} onChange={(e) => handleFilterDataChange(4, e)} index={props.index} indexParent={props.indexParent} data={filterData[4].options} width={195} value={filterData[4].selectedDisplay}></Dropdown></div>),
    ]

    const [searchElementValues, setSearchElementValues] = useState([
        '',
    ])

    const debounceTimerRef = useRef();

    const [dealers, setDealers] = useState([])

    const onSearchElementUpdate = (e, indexParent) => {
        setSearchElementValues((searchElementValues) => 
            searchElementValues.map((value, index) => 
                indexParent === index ? e : value
        ));

        // Reset the debounce timer
        if (debounceTimerRef.current) {
            clearTimeout(debounceTimerRef.current);
        }

        debounceTimerRef.current = setTimeout(() => {
            console.log('Searching Dealerships')
            searchDealerships(e, 31, true);
        }, 1000); // 3000 ms = 3 seconds
    }

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);
    
    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Edit Dealer',
            displayElement: (props) => (<IconButton onClick={() => {setEditDealerModal(true); setSelectedDealer(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: '',
        },
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap', maxWidth: '425px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Owner',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Weekly 7',
            displayElement: (props) => (<div onClick={() => {updateWeekly7(props.index, props.tableData, props.tableIDs); onTableChange(!props.data, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: false,
        },
        {
            title: 'DPPP / GAP Plus',
            displayElement: (props) => (<IconButton onClick={() => {setEditDPPPGapModal(true); setSelectedDealer(props.index)}} Style={{backgroundColor: props.colors.primary}} size={31} image={Edit} colors={props.colors}></IconButton>),
            default: false,
        },
        {
            title: 'Contact',
            displayElement: (props) => (<Button onClick={() => {setEditContactInfoModal(true); setSelectedDealer(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Info</Button>),
            default: undefined,
        },
        {
            title: 'Reserve Accounts',
            displayElement: (props) => (<Button onClick={() => {setEditReserveAccountsModal(true); setSelectedDealer(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button>),
            default: undefined,
        },
        {
            title: 'Connections',
            displayElement: (props) => (<Button onClick={() => {setEditConnectionsModal(true); setSelectedDealer(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>Edit</Button>),
            default: undefined,
        },
        {
            title: 'Active',
            displayElement: (props) => (<div onClick={() => {updateActiveStatus(props.index, props.tableData, props.tableIDs); onTableChange(props.data === 0 ? 1:0, props.indexParent, props.index, props.tableData, props.tableIDs)}} style={{cursor: 'pointer', width: '28px', height: '28px', border: props.data === 1 ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === 1  ? props.colors.primary:'', marginTop: '4px', marginRight: '20px', marginLeft: '20px'}}></div>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default], 
        [tableTitles[5].default], 
        [tableTitles[6].default], 
        [tableTitles[7].default], 
        [tableTitles[8].default],
        [tableTitles[9].default],
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
        [tableTitles[7].default],
        [tableTitles[8].default],
        [tableTitles[9].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const [selectedDealer, setSelectedDealer] = useState(-1);

    const onTableChange = (e, x, y, tableValuesIn, tableDataIn) => {
        console.log('Table Updated', e, x, y)
        setTableValues(() => tableValuesIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData(() => tableDataIn.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    const [editDealerModal, setEditDealerModal] = useState(false)
    const [editConnectionsModal, setEditConnectionsModal] = useState(false)
    const [editContactInfoModal, setEditContactInfoModal] = useState(false)
    const [editReserveAccountsModal, setEditReserveAccountsModal] = useState(false)
    const [editDPPPGapModal, setEditDPPPGapModal] = useState(false)
    const [createNewDealerModal, setCreateNewDealerModal] = useState(false)

    const [skip, setSkip] = useState(15)

    const [dealerContactInfos, setDealerContactInfos] = useState([])
    const [dealerReserveAccounts, setDealerReserveAccounts] = useState([])
    const [dealerUserConnections, setDealerUserConnections] = useState([])
    const [dealerAdmins, setDealerAdmins] = useState([])
    const [dealerTempOwnerId, setDealerTempOwnerId] = useState([])
    const [dealerSpiffees, setDealerSpiffees] = useState([])
    const [dealerDPPPGAP, setDealerDPPPGAP] = useState([])

    const [refresh, setRefresh] = useState(false)

    const refreshData = (skip) => {       
        if (skip !== undefined)
        {
            setRefresh((refresh) => !refresh)
        }
        else
        {
            refreshAllData();
        }
    }

    function setEmptyStringsToNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === '') {
                obj[key] = null;
            }
        });
        return obj;
    }

    const [checkIfRan, setCheckIfRan] = useState(false)

    useEffect(() => {
        //THIS IS NOT THE NORMAL USEEFFECT
        //this is a special useEffect specifically for refreshing data from a weekly7/active change as instead of passing the data through the table and back into this element, I can just use this instead.
        //this mirrors refreshAllData(), I cant call that method as its being called from inside the table so the skip value is not the current skip value and will always return 0 (the initally set value).
        if (checkIfRan)
        {
            updateCookies();

            let tokenValues = {
                refresh_token: cookies['refresh_token'],
                refresh_token_expires: cookies['refresh_token_expires'],
                access_token: cookies['access_token'],
                access_token_expires: cookies['access_token_expires'],
            }
    
            //get dealers
            var myHeaders = new Headers();
            myHeaders.append("Accept", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
            myHeaders.append("Content-Type", "application/json");
        
            let formattedFilters = {}
            filterData.map((filter) => {
                if (filter.selectedValue === '')
                {
    
                }
                else
                {
                    if (filter.key === 'contract_type')
                    {
                        console.log(filter.selectedValue)
                        if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                        {
                            formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                        }
                        else
                        {
                            formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                        }
                    }
                    formattedFilters[filter.key] = filter.selectedValue
                }
            })
        
            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              redirect: 'follow',
              body: JSON.stringify({
                ...formattedFilters,
                })
            }
    
            let limit = 0;
            if (skip === 0)
            {
                limit = 30
            }
            else
            {
                limit = skip;
            }
        
            props.tokenSafeAPIRequest('/dealership/search?limit=' + limit + '&fetch_links=true&active_only=false&skip=0&reverse_sort=false', requestOptions, tokenValues, (result, status) => {
                console.log(result)
                if (status === 200)
                {
    
                } else {setNotification(props.getServerResponse(result))}
                let tempDealerList = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                let tempIdsList = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                let tempDealerContactInfo = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]
                let tempDealerReserveAccountInfo = []
                let tempUserConnections = []
                let tempAdmins = []
                let tempTempAgentOwner = []
                let tempSpiffees = []
                let tempDPPPGAP = []
                setDealers(result)
                result.map((dealer, index) => {
                    tempDealerList[0].push(dealer.FI_visit);
                    tempDealerList[1].push(dealer.code);
                    tempDealerList[2].push(dealer.name);
                    tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                    tempDealerList[4].push(dealer.weekly_7);
                    tempDealerList[5].push(dealer.GAPPLUS);
                    tempDealerList[6].push(dealer.inside_sales);
                    tempDealerList[7].push(dealer.signed_incentive_form);
                    tempDealerList[8].push(dealer.franchise_dealer);
                    tempDealerList[9].push(dealer.resource_state);
    
                    tempIdsList[0].push(dealer.FI_visit);
                    tempIdsList[1].push(dealer.code);
                    tempIdsList[2].push(dealer.id);
                    tempIdsList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.id : '');
                    tempIdsList[4].push(dealer.weekly_7);
                    tempIdsList[5].push(dealer.GAPPLUS);
                    tempIdsList[6].push(dealer.inside_sales);
                    tempIdsList[7].push(dealer.signed_incentive_form);
                    tempIdsList[8].push(dealer.franchise_dealer);
                    tempIdsList[9].push(dealer.resource_state);
    
                    tempDealerContactInfo[0].push(dealer.contact_name);
                    tempDealerContactInfo[1].push(dealer.contact_email);
                    tempDealerContactInfo[2].push(dealer.phone_number);
                    tempDealerContactInfo[3].push(dealer.address);
                    tempDealerContactInfo[4].push(dealer.city);
                    tempDealerContactInfo[5].push(dealer.state_code);
                    tempDealerContactInfo[6].push(dealer.zip_code);
    
                    tempDealerReserveAccountInfo.push(dealer.reserve_account_IDs);
    
                    tempUserConnections.push(dealer.user_connections);
    
                    tempAdmins.push(dealer.admin_IDs);
    
                    tempTempAgentOwner.push(dealer.temporary_subagent_owner_ID);
    
                    tempSpiffees.push(dealer.spiffee_IDs)
    
                    tempDPPPGAP.push(dealer.dppp_gap_plus)
                })
    
                if (searchElementValues[0] === '')
                {
                    setTableData(tempIdsList);
                    setDealerContactInfos(tempDealerContactInfo);
                    setTableValues(tempDealerList)
                    setDealerReserveAccounts(tempDealerReserveAccountInfo)
                    setDealerUserConnections(tempUserConnections)
                    setDealerAdmins(tempAdmins)
                    setDealerTempOwnerId(tempTempAgentOwner)
                    setDealerSpiffees(tempSpiffees)
                    setDealerDPPPGAP(tempDPPPGAP)
                }
                else
                {
                    searchDealerships(searchElementValues[0], limit)
                }
            })
    
            //setSkip((skip) => skip + 15)
        }
    },[refresh])

    useEffect(() => {

        updateCookies();

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            })
        }
    
        props.tokenSafeAPIRequest('/dealership/search?limit=30&fetch_links=true&active_only=false&reverse_sort=false', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempDealerList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempIdsList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerContactInfo = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerReserveAccountInfo = []
            let tempUserConnections = []
            let tempAdmins = []
            let tempTempAgentOwner = []
            let tempSpiffees = []
            let tempDPPPGAP = []
            setDealers(result)
            result.map((dealer, index) => {
                tempDealerList[0].push(dealer.FI_visit);
                tempDealerList[1].push(dealer.code);
                tempDealerList[2].push(dealer.name);
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(dealer.weekly_7);
                tempDealerList[5].push(dealer.GAPPLUS);
                tempDealerList[6].push(dealer.inside_sales);
                tempDealerList[7].push(dealer.signed_incentive_form);
                tempDealerList[8].push(dealer.franchise_dealer);
                tempDealerList[9].push(dealer.resource_state);

                tempIdsList[0].push(dealer.FI_visit);
                tempIdsList[1].push(dealer.code);
                tempIdsList[2].push(dealer.id);
                tempIdsList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.id : '');
                tempIdsList[4].push(dealer.weekly_7);
                tempIdsList[5].push(dealer.GAPPLUS);
                tempIdsList[6].push(dealer.inside_sales);
                tempIdsList[7].push(dealer.signed_incentive_form);
                tempIdsList[8].push(dealer.franchise_dealer);
                tempIdsList[9].push(dealer.resource_state);

                tempDealerContactInfo[0].push(dealer.contact_name);
                tempDealerContactInfo[1].push(dealer.contact_email);
                tempDealerContactInfo[2].push(dealer.phone_number);
                tempDealerContactInfo[3].push(dealer.address);
                tempDealerContactInfo[4].push(dealer.city);
                tempDealerContactInfo[5].push(dealer.state_code);
                tempDealerContactInfo[6].push(dealer.zip_code);

                tempDealerReserveAccountInfo.push(dealer.reserve_account_IDs);

                tempUserConnections.push(dealer.user_connections);

                tempAdmins.push(dealer.admin_IDs);

                tempTempAgentOwner.push(dealer.temporary_subagent_owner_ID);

                tempSpiffees.push(dealer.spiffee_IDs)

                tempDPPPGAP.push(dealer.dppp_gap_plus)
            })

            if (searchElementValues[0] === '')
            {
                setTableData(tempIdsList);
                setDealerContactInfos(tempDealerContactInfo);
                setTableValues(tempDealerList)
                setDealerReserveAccounts(tempDealerReserveAccountInfo)
                setDealerUserConnections(tempUserConnections)
                setDealerAdmins(tempAdmins)
                setDealerTempOwnerId(tempTempAgentOwner)
                setDealerSpiffees(tempSpiffees)
                setDealerDPPPGAP(tempDPPPGAP)
            }
            else
            {
                searchDealerships(searchElementValues[0], skip)
            }
        })

        setSkip((skip) => skip + 15)
        setCheckIfRan(true)

    },[])

    const refreshAllData = () => {
        updateCookies();

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...formattedFilters,
            })
        }

        console.log(skip)

        let limit = 0;
        if (skip === 0)
        {
            limit = 30
        }
        else
        {
            limit = skip;
        }
    
        props.tokenSafeAPIRequest('/dealership/search?limit=' + limit + '&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {

            } else {setNotification(props.getServerResponse(result))}
            let tempDealerList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempIdsList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerContactInfo = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerReserveAccountInfo = []
            let tempUserConnections = []
            let tempAdmins = []
            let tempTempAgentOwner = []
            let tempSpiffees = []
            let tempDPPPGAP = []
            setDealers(result)
            result.map((dealer, index) => {
                tempDealerList[0].push(dealer.FI_visit);
                tempDealerList[1].push(dealer.code);
                tempDealerList[2].push(dealer.name);
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(dealer.weekly_7);
                tempDealerList[5].push(dealer.GAPPLUS);
                tempDealerList[6].push(dealer.inside_sales);
                tempDealerList[7].push(dealer.signed_incentive_form);
                tempDealerList[8].push(dealer.franchise_dealer);
                tempDealerList[9].push(dealer.resource_state);

                tempIdsList[0].push(dealer.FI_visit);
                tempIdsList[1].push(dealer.code);
                tempIdsList[2].push(dealer.id);
                tempIdsList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.id : '');
                tempIdsList[4].push(dealer.weekly_7);
                tempIdsList[5].push(dealer.GAPPLUS);
                tempIdsList[6].push(dealer.inside_sales);
                tempIdsList[7].push(dealer.signed_incentive_form);
                tempIdsList[8].push(dealer.franchise_dealer);
                tempIdsList[9].push(dealer.resource_state);

                tempDealerContactInfo[0].push(dealer.contact_name);
                tempDealerContactInfo[1].push(dealer.contact_email);
                tempDealerContactInfo[2].push(dealer.phone_number);
                tempDealerContactInfo[3].push(dealer.address);
                tempDealerContactInfo[4].push(dealer.city);
                tempDealerContactInfo[5].push(dealer.state_code);
                tempDealerContactInfo[6].push(dealer.zip_code);

                tempDealerReserveAccountInfo.push(dealer.reserve_account_IDs);

                tempUserConnections.push(dealer.user_connections);

                tempAdmins.push(dealer.admin_IDs);

                tempTempAgentOwner.push(dealer.temporary_subagent_owner_ID);

                tempSpiffees.push(dealer.spiffee_IDs)

                tempDPPPGAP.push(dealer.dppp_gap_plus)
            })

            if (searchElementValues[0] === '')
            {
                setTableData(tempIdsList);
                setDealerContactInfos(tempDealerContactInfo);
                setTableValues(tempDealerList)
                setDealerReserveAccounts(tempDealerReserveAccountInfo)
                setDealerUserConnections(tempUserConnections)
                setDealerAdmins(tempAdmins)
                setDealerTempOwnerId(tempTempAgentOwner)
                setDealerSpiffees(tempSpiffees)
                setDealerDPPPGAP(tempDPPPGAP)
            }
            else
            {
                searchDealerships(searchElementValues[0], limit)
            }
        })

        //setSkip((skip) => skip + 15)
    }

    function mergeData(originalArray, apiResponse) {
        let apiIndex = 0;
        let mergedArray = originalArray.map(item => {
            if (item === null) {
                return null;
            } else {
                return apiResponse[apiIndex++];
            }
        });
        return mergedArray;
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const searchDealerships = (e, preloadedDealers, typedSearch) => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        console.log('Searching By: ' + e)

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            search_field: e,
            ...formattedFilters,
            })
        }

        console.log(requestOptions)
    
        props.tokenSafeAPIRequest('/dealership/search?limit=' + preloadedDealers + '&fetch_links=true&reverse_sort=false&active_only=false', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempIdsList = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerContactInfo = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempDealerReserveAccountInfo = []
            let tempUserConnections = []
            let tempAdmins = []
            let tempTempAgentOwner = []
            let tempSpiffees = []
            let tempDPPPGAP = []
            //setDealers(result)
            result.map((dealer, index) => {
                tempDealerList[0].push(dealer.FI_visit);
                tempDealerList[1].push(dealer.code);
                tempDealerList[2].push(dealer.name);
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(dealer.weekly_7);
                tempDealerList[5].push(dealer.GAPPLUS);
                tempDealerList[6].push(dealer.inside_sales);
                tempDealerList[7].push(dealer.signed_incentive_form);
                tempDealerList[8].push(dealer.franchise_dealer);
                tempDealerList[9].push(dealer.resource_state);

                tempIdsList[0].push(dealer.FI_visit);
                tempIdsList[1].push(dealer.code);
                tempIdsList[2].push(dealer.id);
                tempIdsList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.id : '');
                tempIdsList[4].push(dealer.weekly_7);
                tempIdsList[5].push(dealer.GAPPLUS);
                tempIdsList[6].push(dealer.inside_sales);
                tempIdsList[7].push(dealer.signed_incentive_form);
                tempIdsList[8].push(dealer.franchise_dealer);
                tempIdsList[9].push(dealer.resource_state);

                tempDealerContactInfo[0].push(dealer.contact_name);
                tempDealerContactInfo[1].push(dealer.contact_email);
                tempDealerContactInfo[2].push(dealer.phone_number);
                tempDealerContactInfo[3].push(dealer.address);
                tempDealerContactInfo[4].push(dealer.city);
                tempDealerContactInfo[5].push(dealer.state_code);
                tempDealerContactInfo[6].push(dealer.zip_code);

                tempDealerReserveAccountInfo.push(dealer.reserve_account_IDs);

                tempUserConnections.push(dealer.user_connections);

                tempAdmins.push(dealer.admin_IDs);

                tempTempAgentOwner.push(dealer.temporary_subagent_owner_ID);

                tempSpiffees.push(dealer.spiffee_IDs)

                tempDPPPGAP.push(dealer.dppp_gap_plus)
            })
            
            setTableData(tempIdsList);
            setDealerContactInfos(tempDealerContactInfo);
            setTableValues(tempDealerList)
            setDealerReserveAccounts(tempDealerReserveAccountInfo)
            setDealerUserConnections(tempUserConnections)
            setDealerAdmins(tempAdmins)
            setDealerTempOwnerId(tempTempAgentOwner)
            setDealerSpiffees(tempSpiffees)
            setDealerDPPPGAP(tempDPPPGAP)
        })

        if (typedSearch)
        {
            console.log('Resetting Skip')
            setSkip(30)
        }
    }

    const onBottom = () => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let formattedFilters = {}
        filterData.map((filter) => {
            if (filter.selectedValue === '')
            {

            }
            else
            {
                if (filter.key === 'contract_type')
                {
                    console.log(filter.selectedValue)
                    if (filter.selectedValue.split(' - ')[0] === 'Ancillary')
                    {
                        formattedFilters['ancillary_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                    else
                    {
                        formattedFilters['VSC_contracts'] = [filter.selectedValue.split(' - ')[1]]
                    }
                }
                formattedFilters[filter.key] = filter.selectedValue
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            search_field: searchElementValues[0],
            ...formattedFilters,
        })
        };
    
        props.tokenSafeAPIRequest('/dealership/search?limit=15&fetch_links=true&reverse_sort=false&active_only=false&skip=' + skip, requestOptions, tokenValues, (result) => {
            let tempDealerList = tableValues;
            let tempIdsList = tableData;
            let tempDealerContactInfo = dealerContactInfos;
            let tempDealerReserveAccountInfo = dealerReserveAccounts;
            let tempUserConnections = dealerUserConnections;
            let tempAdmins = dealerAdmins;
            let tempTempAgentOwner = dealerTempOwnerId;
            let tempSpiffees = dealerSpiffees
            let tempDPPPGAP = dealerDPPPGAP
            console.log(result)
            //console.log(tableValues, tableData)
            result.map((dealer, index) => {
                tempDealerList[0].push(dealer.FI_visit);
                tempDealerList[1].push(dealer.code);
                tempDealerList[2].push(dealer.name);
                tempDealerList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.first_name + ' ' + dealer.subagent_owner_ID.last_name : '');
                tempDealerList[4].push(dealer.weekly_7);
                tempDealerList[5].push(dealer.GAPPLUS);
                tempDealerList[6].push(dealer.inside_sales);
                tempDealerList[7].push(dealer.signed_incentive_form);
                tempDealerList[8].push(dealer.franchise_dealer);
                tempDealerList[9].push(dealer.resource_state);

                tempIdsList[0].push(dealer.FI_visit);
                tempIdsList[1].push(dealer.code);
                tempIdsList[2].push(dealer.id);
                tempIdsList[3].push(dealer.subagent_owner_ID ? dealer.subagent_owner_ID.id : '');
                tempIdsList[4].push(dealer.weekly_7);
                tempIdsList[5].push(dealer.GAPPLUS);
                tempIdsList[6].push(dealer.inside_sales);
                tempIdsList[7].push(dealer.signed_incentive_form);
                tempIdsList[8].push(dealer.franchise_dealer);
                tempIdsList[9].push(dealer.resource_state);

                tempDealerContactInfo[0].push(dealer.contact_name);
                tempDealerContactInfo[1].push(dealer.contact_email);
                tempDealerContactInfo[2].push(dealer.phone_number);
                tempDealerContactInfo[3].push(dealer.address);
                tempDealerContactInfo[4].push(dealer.city);
                tempDealerContactInfo[5].push(dealer.state_code);
                tempDealerContactInfo[6].push(dealer.zip_code);

                tempDealerReserveAccountInfo.push(dealer.reserve_account_IDs);

                tempUserConnections.push(dealer.user_connections);

                tempAdmins.push(dealer.admin_IDs);

                tempTempAgentOwner.push(dealer.temporary_subagent_owner_ID);

                tempSpiffees.push(dealer.spiffee_IDs)

                tempDPPPGAP.push(dealer.dppp_gap_plus)
            })
            
            setTableData(tempIdsList);
            setDealerContactInfos(tempDealerContactInfo);
            setTableValues(tempDealerList)
            setDealerReserveAccounts(tempDealerReserveAccountInfo)
            setDealerUserConnections(tempUserConnections)
            setDealerAdmins(tempAdmins)
            setDealerTempOwnerId(tempTempAgentOwner)
            setDealerSpiffees(tempSpiffees)
            setDealerDPPPGAP(tempDPPPGAP)
            //setDealers()
        })

        setSkip((skip) => skip + 15)
    }

    function replaceNullsWithHyphens(array) {
        return array.map(item => item === null ? '-' : item);
    }

    const createNewDealer = (e) => {
        console.log(e)
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            name: e.DBAName === '' ? e.name:e.name + '(' + e.DBAName + ')',
            subagent_owner_ID: e.subagent,
            signed_incentive_form: e.signedIncentiveForm,
            franchise_dealer: e.franchise,
            inside_sales: e.insideSales,
            FI_visit: e.FIVisit,
          })
        };

        props.tokenSafeAPIRequest('/dealership', requestOptions, tokenValues, (result, status) => {
            console.log(result)

            requestOptions = {
                method: 'POST',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                  commission_amount: 0,
                  commission_rate: 0,
                  connection_type: 'splitee',
                  owner: true,
                  user_ID: e.subagent,
                  dealership_ID: result.id
                })
              };

            props.tokenSafeAPIRequest('/connection', requestOptions, tokenValues, (result, status2) => {
                console.log(result)
                if (status === 200 && status2 === 200)
                {
                    setCreateNewDealerModal(false)
                    setNotification('Added Dealer Successfully at: ' + getCurrentTimeFormatted())
                    refreshData()
                } else {setNotification(props.getServerResponse(result))}
            })
        })
    }

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    const editDealership = (newDealerInfo) => {

        console.log(newDealerInfo)

        let editedDealership = transposeArray(tableData)[selectedDealer]
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            FI_visit: newDealerInfo.FIVisit,
            name: newDealerInfo.name,
            subagent_owner_ID: newDealerInfo.subagent,
            inside_sales: newDealerInfo.insideSales,
            signed_incentive_form: newDealerInfo.signedIncentiveForm,
            franchise_dealer: newDealerInfo.franchise,
          })
        };

        console.log(requestOptions)

        props.tokenSafeAPIRequest('/dealership/' + editedDealership[2], requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setEditDealerModal(false)
                setNotification(getCurrentTimeFormatted() + ': Edited Dealer Successfully')
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
            console.log(result)
        })
    }

    const [notification, setNotification] = useState('')

    const deleteDealership = () => {

        let editedDealership = transposeArray(tableData)[selectedDealer]

        console.log('Deleting: ', editedDealership)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/dealership/' + editedDealership[2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification(getCurrentTimeFormatted() + ': Edited Dealer Successfully')
                setEditDealerModal(false)
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editDealerContactInfo = (newDealerInfo) => {
        let editedDealership = transposeArray(tableData)[selectedDealer]

        console.log(newDealerInfo)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }
        
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        newDealerInfo = {
            contact_name: newDealerInfo.name === "" ? null : newDealerInfo.name,
            contact_email: newDealerInfo.email === "" ? null : newDealerInfo.email,
            phone_number: newDealerInfo.phoneNumber === "" ? null : newDealerInfo.phoneNumber,
            address: newDealerInfo.street === "" ? null : newDealerInfo.street,
            city: newDealerInfo.city === "" ? null : newDealerInfo.city,
            zip_code: newDealerInfo.zipCode === "" ? null : newDealerInfo.zipCode,
            state_code: newDealerInfo.stateCode === "" ? null : newDealerInfo.stateCode,
        }

        console.log(newDealerInfo)
    
        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(newDealerInfo)
        };

        props.tokenSafeAPIRequest('/dealership/' + editedDealership[2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification(getCurrentTimeFormatted() + ': Edited Dealer Successfully')
                setEditContactInfoModal(false)
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const editReserveAccountInfo = (newReserveAccounts, deletedAccounts) => {
        let existingAccounts = []
        let newAccounts = []

        console.log(newReserveAccounts)
        console.log(deletedAccounts)

        transposeArray(newReserveAccounts).map((account, index) => {
        if (account[2] === undefined)
            {
                newAccounts.push({
                    name: account[0],
                    notes: account[1],
                    dealership_owner_ID: transposeArray(tableData)[selectedDealer][2],
                    account_type: 1
                })
            }
            else 
            { 
                existingAccounts.push({
                    name: account[0],
                    notes: account[1],
                    id: account[2],
                    dealership_owner_ID: transposeArray(tableData)[selectedDealer][2],
                    account_type: 1
                })
             }
        })
        
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newAccounts)
        };

        console.log(newAccounts);
        console.log(existingAccounts)
        console.log(deletedAccounts)

        props.tokenSafeAPIRequest('/account/import', requestOptions, tokenValues, (result, status) => {
            console.log(result)

            if (status === 200)
            {
                if (result.length > 0)
                {
                    setNotification('Added New Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                }
            } else {setNotification(props.getServerResponse(result))}

            var requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify(existingAccounts)
            };

            props.tokenSafeAPIRequest('/account/bulk', requestOptions, tokenValues, (result2, status2) => {
                console.log(result2)

                if (status2 === 200)
                {
                    setNotification('Edited Existing Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                } else {setNotification(props.getServerResponse(result2))}

                var requestOptions = {
                    method: 'DELETE',
                    headers: myHeaders,
                    redirect: 'follow',
                    body: JSON.stringify(deletedAccounts)
                };

                props.tokenSafeAPIRequest('/account/bulk', requestOptions, tokenValues, (result3, status3) => {
                    console.log(result3)
                    if (status3 === 200)
                    {
                        if (deletedAccounts.length > 0)
                        {
                            setNotification('Deleted Reserve Accounts Successfully at: ' + getCurrentTimeFormatted())
                        }
                    } else {
                        if (deletedAccounts.length !== 0)
                        {
                            setNotification(props.getServerResponse(result3))
                        }
                    }
                    setEditReserveAccountsModal(false)
                    refreshData()
                })
            })
        })
    }

    const editFlateesInfo = ((newFlatees, deletedFlatees) => {

        let newFlateesInfo = [];
        let existingFlateesInfo = [];
        let deleteFlatees = []

        transposeArray(newFlatees).map((flatee, index) => {
            if (flatee[0] === '-')
            {
                newFlateesInfo.push({
                    user_ID: flatee[1],
                    dealership_ID: transposeArray(tableData)[selectedDealer][2],
                    commission_amount: flatee[2],
                    id: flatee[3],
                    connection_type: 'flatee',
                })
            }
            else
            {
                existingFlateesInfo.push({
                    user_ID: flatee[1],
                    dealership_ID: transposeArray(tableData)[selectedDealer][2],
                    commission_amount: flatee[2],
                    id: flatee[3],
                    connection_type: 'flatee',
                })
            }
        })

        deletedFlatees.map((flatee, index) => {
            deleteFlatees.push(flatee[3])
        })

        console.log(newFlateesInfo)
        console.log(existingFlateesInfo)
        console.log(deleteFlatees)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newFlateesInfo.concat(existingFlateesInfo))
        };

        props.tokenSafeAPIRequest('/connection/edit/' + transposeArray(tableData)[selectedDealer][2] + '/flatee', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setNotification('Updated Flatees Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    })

    const editAdminsInfo = ((newAdmins, deletedAdmins) => {
        console.log(transposeArray(newAdmins), deletedAdmins)

        let tempNewAdmins = []
        transposeArray(newAdmins).map((admin, index) => {
            tempNewAdmins.push(admin[1])
        })

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                admin_IDs: tempNewAdmins
            })
        };

        props.tokenSafeAPIRequest('/dealership/' + transposeArray(tableData)[selectedDealer][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Dealer Admins Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    })

    const editSpliteesInfo = ((newSplitees, deletedSplitees, overrideSplits) => {
        console.log(transposeArray(newSplitees))
        console.log(deletedSplitees)

        let newSpliteeInfo = [];
        let existingSpliteeInfo = [];
        let deleteSplitees = []

        console.log(newSplitees[0].length)

        transposeArray(newSplitees).map((splitee, index) => {
            if (splitee[0] === '-')
            {
                newSpliteeInfo.push({
                    connection_type: 'splitee',
                    owner: splitee[2] === '' ? false:splitee[2],
                    dealership_ID: transposeArray(tableData)[selectedDealer][2],
                    user_ID: splitee[1],
                    profit_split: overrideSplits ? splitee[3]/100:(100/newSplitees[0].length)/100,
                })
            }
            else
            {
                existingSpliteeInfo.push({
                    connection_type: 'splitee',
                    owner: splitee[2],
                    dealership_ID: transposeArray(tableData)[selectedDealer][2],
                    user_ID: splitee[1],
                    id: splitee[4],
                    profit_split: overrideSplits ? splitee[3]/100:(100/newSplitees[0].length)/100,
                })
            }
        })

        deletedSplitees.map((splitee, index) => {
            deleteSplitees.push(splitee[4])
        })

        console.log(newSpliteeInfo)
        console.log(existingSpliteeInfo)
        console.log(deleteSplitees)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify(newSpliteeInfo.concat(existingSpliteeInfo))
        };

        props.tokenSafeAPIRequest('/connection/edit/' + transposeArray(tableData)[selectedDealer][2] + '/splitee', requestOptions, tokenValues, (result, status) => {
            if (status === 200)
            {
                setNotification('Updated Splitees Successfully at: ' + getCurrentTimeFormatted())
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })
    })

    const updateWeekly7 = (rowIndex, tableValues, tableData) => {
        console.log(transposeArray(tableData)[rowIndex][2])

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                weekly_7: !transposeArray(tableData)[rowIndex][4],
            })
        };

        props.tokenSafeAPIRequest('/dealership/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Weekly 7 Eligibility Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const updateActiveStatus = (rowIndex, tableValues, tableData) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                resource_state: transposeArray(tableData)[rowIndex][9] === 0 ? 1:0,
            })
        };

        props.tokenSafeAPIRequest('/dealership/' + transposeArray(tableData)[rowIndex][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Status Successfully at: ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const setTempDealerOwner = (agent, duration, type) => {
        console.log(agent, duration, type)

        let daysToAdd = 0;

        if (type.value === 'days') {daysToAdd = duration}
        if (type.value === 'weeks') {daysToAdd = duration * 7}
        if (type.value === 'months') {daysToAdd = duration * 30}
        if (type.value === 'years') {daysToAdd = duration * 365}

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/time', requestOptions, tokenValues, (result, status) => {
            let currentDate = new Date(result);
            console.log(currentDate)
            let futureDate = new Date(currentDate.getTime() + (daysToAdd * 24 * 60 * 60 * 1000));
            console.log(futureDate)

            requestOptions = {
                method: 'PATCH',
                headers: myHeaders,
                redirect: 'follow',
                body: JSON.stringify({
                    temporary_subagent_owner_ID: agent.value,
                    temporary_owner_expiry_date: futureDate
                })
            };

            console.log(daysToAdd)
            console.log(currentDate)
            console.log(futureDate)

            console.log(requestOptions)

            props.tokenSafeAPIRequest('/dealership/' + transposeArray(tableData)[selectedDealer][2], requestOptions, tokenValues, (result, status) => {
                if (status === 200)
                {
                    setNotification('Set Temp Agent Owner Successfully at: ' + getCurrentTimeFormatted())
                    refreshData()
                } else {setNotification(props.getServerResponse(result))}
            })
        })
    }

    const editDPPPGAP = (table, deletedContracts) => {

        console.log('Editing DPPP/GAP')

        console.log(table)

        let newContractInfo = [];
        let existingContractInfo = [];

        console.log(transposeArray(table))

        transposeArray(table).map((contract, index) => {
            existingContractInfo.push({
                admin_ID: contract[0],
                contract_subtype: contract[1].subType,
                contract_type: contract[1].type,
                DPPP: contract[2] > 0 ? contract[2]:null,
                GAP: contract[3] > 0 ? contract[3]:null
            })
        })

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        console.log(existingContractInfo)

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'PATCH',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                dppp_gap_plus: existingContractInfo
            })
        };

        props.tokenSafeAPIRequest('/dealership/' + transposeArray(tableData)[selectedDealer][2], requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Edit Dealership DPPP/GAP Successfully at: ' + getCurrentTimeFormatted())
                setEditDPPPGapModal(false)
                refreshData()
            } else {setNotification(props.getServerResponse(result))}
        })

    }

    const displayComponents = [
        (props) => <IconButton onClick={() => ''} Style={{backgroundColor: props.colors.primary}} size={27} image={Edit} colors={props.colors}></IconButton>,
        (props) => <span >{props.item.name === null ? '-':props.item.name}</span>,
        (props) => <span >{}</span>,
        (props) => <span >{props.item.code === null ? '-':props.item.code}</span>,
        (props) => <div style={{cursor: 'pointer', width: '28px', height: '28px', border: props.item.weekly_7 === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.item.weekly_7 === true  ? props.colors.primary:''}}></div>,
        (props) => <IconButton onClick={() => ''} Style={{backgroundColor: props.colors.primary}} size={27} image={Edit} colors={props.colors}></IconButton>,
        (props) => <Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{props.item.name}</Button>,
        (props) => <Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{props.item.name}</Button>,
        (props) => <Button onClick={() => ''} colors={props.colors} Style={{borderRadius: '5px'}}>{props.item.name}</Button>,
        (props) => <div style={{cursor: 'pointer', width: '28px', height: '28px', border: false === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: false === true  ? props.colors.primary:''}}></div>
    ]

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    const [exportDealers, setExportDealers] = useState(false)

    const exportKeys = [{id: 'code', label: 'Dealer Code'}, {id: 'name', label: 'Dealership'}, {id: 'address', label: 'Street'}, {id: 'city', label: 'City'}, {id: 'state_code', label: 'State'}, {id: 'all_connections', label: 'Associated Agents'}, {id: 'signed_incentive_form', label: 'Signed Incentive Form'}, {id: 'inside_sales', label: 'Inside Sales'}, {id: 'admin_count', label: 'Total Admins'}]

    const exportPostProcessing = (result) => {
        console.log(result)
        result.map((dealer) => {
            let tempUserConnections = []
            dealer.user_connections.map((user) => {
                tempUserConnections.push('Role: ' + user.connection_type + ' | Name:' + user.user_ID.first_name + ' ' + user.user_ID.last_name);
            })
            dealer.all_connections = tempUserConnections;
            dealer.code = 'D' + dealer.code.slice(1).toString().padStart(4, '0')
            dealer.admin_count = dealer.admin_IDs.length
            return dealer;
        })
        return result;
    }
    
    return (
        <ManageLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <ManageHeader colors={props.colors} searchElements={searchElements} buttonText={'Create New Dealer'} buttonText2={'Export Dealers'} title='Manage Dealers' onClick={() => setCreateNewDealerModal(true)} onClick2={() => setExportDealers(true)} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></ManageHeader>
            {/* <TableTest data={dealers} displayComponents={displayComponents} colors={props.colors}></TableTest> */}
            <ManageTable colors={props.colors} tableData={tableValues} dealers={dealers} filterData={filterData} keys={generateKey()} tableIDs={tableData} tableInfo={tableTitles} searchElementValue={searchElementValues[0]} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></ManageTable>
            <ModalManager colors={props.colors}>
                <EditDealerModal colors={props.colors} isOpen={editDealerModal} setIsOpen={() => setEditDealerModal(false)} selectedDealer={selectedDealer} tableValues={tableValues} tableData={tableData} tokenSafeAPIRequest={props.tokenSafeAPIRequest} onSubmit={editDealership} onDelete={deleteDealership}></EditDealerModal>
                <EditConnectionsModal colors={props.colors} isOpen={editConnectionsModal} setIsOpen={() => setEditConnectionsModal(false)} dealerTempOwnerId={dealerTempOwnerId[selectedDealer]} selectedDealer={selectedDealer} dealerAdmins={dealerAdmins} dealerUserConnections={dealerUserConnections} dealerSpiffees={dealerSpiffees} tokenSafeAPIRequest={props.tokenSafeAPIRequest} editFlateesInfo={editFlateesInfo} editAdminsInfo={editAdminsInfo} editSpliteesInfo={editSpliteesInfo} setTempDealerOwner={setTempDealerOwner}></EditConnectionsModal>
                <EditContactInfoModal colors={props.colors} isOpen={editContactInfoModal} setIsOpen={() => setEditContactInfoModal(false)} selectedDealer={selectedDealer} dealerContactInfos={dealerContactInfos} editDealer={editDealerContactInfo}></EditContactInfoModal>
                <EditReserveAccountsModal colors={props.colors} isOpen={editReserveAccountsModal} setIsOpen={() => setEditReserveAccountsModal(false)} selectedDealer={selectedDealer} dealerReserveAccounts={dealerReserveAccounts} editReserveAccounts={editReserveAccountInfo}></EditReserveAccountsModal>
                <EditDPPPGapModal colors={props.colors} isOpen={editDPPPGapModal} setIsOpen={() => setEditDPPPGapModal(false)} selectedDealer={selectedDealer} dealerDPPPGAP={dealerDPPPGAP} dealerAdmins={dealerAdmins} tokenSafeAPIRequest={props.tokenSafeAPIRequest} editDPPPGAP={editDPPPGAP}></EditDPPPGapModal>
                <CreateNewDealerModal colors={props.colors} isOpen={createNewDealerModal} setIsOpen={() => setCreateNewDealerModal(false)} selectedDealer={selectedDealer} onSubmit={createNewDealer} tokenSafeAPIRequest={props.tokenSafeAPIRequest} refreshData={refreshData}></CreateNewDealerModal>
                <ExportData colors={props.colors} isOpen={exportDealers} setIsOpen={() => setExportDealers(false)} url={'/dealership/search'} filterData={{}} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'List of all Dealers Export'} postProcessing={exportPostProcessing} calStartDate={'1900-01-01'} calEndDate={'2100-01-01'}></ExportData>
            </ModalManager>
            <Notification colors={props.colors} message={notification}></Notification>
        </ManageLayout>
    )
}

export default Main