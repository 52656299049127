import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue, m } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../../elements/UserTab.jsx'
import Button from '../../elements/Button.jsx'

import CommissionReceivedImage from '../../media/icons/icons8-open-email-96.png'
import Agent from '../../media/icons/icons8-agent-96.png'
import OpenEnvelope from '../../media/icons/icons8-open-envelope-96.png'
import Adjustments from '../../media/icons/icons8-adjustment-96.png'
import BarChart from '../../media/icons/icons8-bar-chart-96.png'
import Customer from '../../media/icons/icons8-customer-96.png'
import Holiday from '../../media/icons/icons8-christmas-berry-96.png'
import View from '../../media/icons/icons8-view-96-2.png'
import Search from '../../media/icons/icons8-search-bar-96.png'
import Expense from '../../media/icons/icons8-expense-96.png'
import Settings from '../../media/icons/icons8-settings-96.png'
import Employees from '../../media/icons/icons8-employees-96.png'
import Dealer from '../../media/icons/icons8-land-sales-96.png'
import Insurance from '../../media/icons/icons8-insurance-96.png'
import Spiffee from '../../media/icons/icons8-female-meeting-96.png'
import OS from '../../media/icons/icons8-badge-96.png'
import MGMT from '../../media/icons/icons8-management-96.png'
import TransientClaim from '../../media/icons/icons8-requirement-96.png'
import MoneyTransfer from '../../media/icons/icons8-money-transfer-96.png'
import TransferMoney from '../../media/icons/icons8-transfer-money-96.png'

import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend } from 'recharts';

const Main = (props) => {

    const data = [
        {
          name: 'Dec 11',
          VSC: 4000,
          AncillaryGap: 2400,
          AncillaryTAndW: 2400,
        },
        {
          name: 'Dec 18',
          VSC: 3000,
          AncillaryGap: 1398,
          AncillaryTAndW: 2210,
        },
        {
          name: 'Dec 25',
          VSC: 2000,
          AncillaryGap: 9800,
          AncillaryTAndW: 2290,
        },
        {
          name: 'Jan 01',
          VSC: 2780,
          AncillaryGap: 3908,
          AncillaryTAndW: 2000,
        },
        {
          name: 'Jan 08',
          VSC: 1890,
          AncillaryGap: 4800,
          AncillaryTAndW: 2181,
        },
        {
          name: 'Jan 15',
          VSC: 2390,
          AncillaryGap: 3800,
          AncillaryTAndW: 2500,
        },
        {
          name: 'Jan 22',
          VSC: 3490,
          AncillaryGap: 4300,
          AncillaryTAndW: 2100,
        },
      ];

      const dataKeyToName = (name) => {
        if (name === 'AncillaryGap') {return 'Ancillary - GAP'}
        if (name === 'AncillaryTAndW') {return "Ancillary - T&W"}
        return name;
      }

      const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          return (
            <div className="custom-tooltip" style={{backgroundColor: props.colors.block, padding: '5px 10px 5px 10px', borderRadius: '5px'}}>
              <p style={{borderBottom: '1px solid ' + props.colors.border}}>Week Of: <span style={{fontWeight: '500'}}>{label}</span></p>
              {payload.map((entry, index) => (
                <p key={`item-${index}`}>{dataKeyToName(entry.name) + ': '}<span style={{fontWeight: '500'}}>${entry.value.toLocaleString()}</span></p>
              ))}
            </div>
          );
        }
      
        return null;
      };

    return (
        <div style={{width: '100%', height: '80%', padding: '20px', display: 'flex', flexDirection: 'column', minWidth: '500px'}}>
            <span style={{fontWeight: '500', fontSize: '20px', marginBottom: '10px'}}>Metrics</span>
            <ResponsiveContainer width="100%" height="100%">
                <AreaChart
                width={500}
                height={400}
                data={data}
                margin={{
                    top: 10,
                    right: 30,
                    left: 0,
                    bottom: 50,
                }}
                >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis />
                <Tooltip content={<CustomTooltip />}/>
                <Legend />
                <Area type="monotone" dataKey="VSC" stackId="1" stroke={props.colors.primary} fill={props.colors.primary} />
                <Area type="monotone" dataKey="AncillaryGap" stackId="1" stroke="#3dc7ff" fill="#3dc7ff" />
                <Area type="monotone" dataKey="AncillaryTAndW" stackId="1" stroke="#82dbff" fill="#82dbff" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}

export default Main
