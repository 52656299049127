import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import ModalManager from '../elements/ModalManager'
import FiltersModal from '../assets/metrics/FiltersModal'

import Dropdown from '../elements/ReactSelectDropdown'
import CalendarModal from '../assets/metrics/CalendarModal'

import ExportData from '../assets/metrics/ExportData'

const Main = (props) => {

    const list = ['Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield', 'Liberty Shield']

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Agent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admin',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Report Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Quantity',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Gross',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>${props.data}</span>),
            default: '',
        },
        {
            title: 'Contract Count',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{formatNumber(props.data)}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default], 
        [tableTitles[7].default], 
        [tableTitles[8].default], 
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    const [selectedAdmin, setSelectedAdmin] = useState({value: '', label: 'All Admins'})
    const [allAdmins, setAllAdmins] = useState('')
    const [headerValues, setHeaderValues] = useState([])
    const [skip, setSkip] = useState(0)
    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'subagent_ID',
        },
        {
            title: 'Dealership',
            data: '',
            value: {label: '', value: ''},
            key: 'dealership_ID',
        },
        {
            title: 'Report Type',
            data: [
                {value: 'Saturday', label: 'Saturday'},
                {value: 'Adjustment', label: 'Adjustment'},
                {value: 'Weekly 7', label: 'Weekly 7'},
                {value: 'Void', label: 'Void'},
                {value: 'Rejection', label: 'Rejection'},
                {value: 'Unwind', label: 'Unwind'},
                {value: 'Cancellation', label: 'Cancellation'},
                {value: 'Over / Under', label: 'Over / Under'},
                {value: 'Payback', label: 'Payback'},
                {value: 'Claim', label: 'Claim'},
                {value: '100% Cancellation', label: '100% Cancellation'},
            ],
            value: {label: '', value: ''},
            key: 'report_type',
        },
        {
            title: 'Contract Type',
            data: '',
            value: {label: '', value: ''},
            key: 'contract_type',
        },
    ])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        props.tokenSafeAPIRequest('/admin/search', requestOptions, '', (result, status) => {
            console.log(result)
            let tempAdmins = []
            tempAdmins.push({label: 'All Admins', value: ''})
            result.map((admin) => {
                tempAdmins.push({label: admin.name, value: admin.id})
            })
            setAllAdmins(tempAdmins)
        })

        let tempDealerList = []
        let tempContractList = []
        let tempSubagentList = []

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
        })
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
        })

        requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        }

        props.tokenSafeAPIRequest('/admin/contract_types?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.VSC_contracts.map((type, index) => {
                tempContractList.push({value: 'VSC - ' + type, label: 'VSC - ' + type})
            })
            result.ancillary_contracts.map((type, index) => {
                tempContractList.push({value: 'Ancillary - ' + type, label: 'Ancillary - ' + type})
            })
        })

        setFilterData([
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'subagent_ID',
            },
            {
                title: 'Dealership',
                data: tempDealerList,
                value: {label: '', value: ''},
                key: 'dealership_ID',
            },
            {
                title: 'Report Type',
                data: [
                    {value: 'Saturday', label: 'Saturday'},
                    {value: 'Adjustment', label: 'Adjustment'},
                    {value: 'Weekly 7', label: 'Weekly 7'},
                    {value: 'Void', label: 'Void'},
                    {value: 'Rejection', label: 'Rejection'},
                    {value: 'Unwind', label: 'Unwind'},
                    {value: 'Cancellation', label: 'Cancellation'},
                    {value: 'Over / Under', label: 'Over / Under'},
                    {value: 'Payback', label: 'Payback'},
                    {value: 'Claim', label: 'Claim'},
                    {value: '100% Cancellation', label: '100% Cancellation'},
                ],
                value: {label: '', value: ''},
                key: 'report_type',
            },
            {
                title: 'Contract Type',
                data: tempContractList,
                value: {label: '', value: ''},
                key: 'contract_type',
            },
        ])
    },[])

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            admin_ID: selectedAdmin.value === '' ? null:selectedAdmin.value,
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedAdmin.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedAdmin.value
        }

        props.tokenSafeAPIRequest('/metrics/admin?limit=15&fetch_links=true&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [], 
                    [], 
                    [], 
                ])
                let tempStats = [
                    {title: 'Total Net', value: '$' + 0},
                    {title: 'Total Contract Count', value: '' + 0},
                    {title: 'Total Dealers', value: 0},
                    {title: 'Total Reports', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Net', value: '$' + formatNumber(result.total_net)},
                    {title: 'Total Contract Count', value: '' + formatNumber(result.total_contract_count)},
                    {title: 'Total Dealers', value: (formatNumber(result.total_dealerships) * 100)},
                    {title: 'Total Reports', value: formatNumber(result.total_reports)},
                ]
                setHeaderValues(tempStats)

                let tempTableValues = [
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                    [], 
                    [], 
                    [], 
                ]
                result.reports.map((report) => {
                    tempTableValues[0].push(report.report_subtype === 'Adjustment' ? report.subagent_ID.first_name + ' ' + report.subagent_ID.last_name:report.submitted_by.first_name + ' ' + report.submitted_by.last_name)
                    tempTableValues[1].push(report.admin_ID === null ? 'No Admin':report.admin_ID.name)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report.dealership_ID.name)
                    tempTableValues[3].push(report.report_type)
                    tempTableValues[4].push(report.report_subtype === 'Adjustment' ? report.report_type:report.contract_type + ' - ' + report.contract_subtype)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                    tempTableValues[6].push(report.quantity)
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.total_contract_count)
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[selectedAdmin, filterData, sort, calStartDate, calEndDate])

    const onBottom = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            admin_ID: selectedAdmin.value === '' ? null:selectedAdmin.value,
            ...filterDataBy
          })
        }

        let tempStringAdjust = ''
        if (selectedAdmin.value === '')
        {
            tempStringAdjust = '';
        }
        else
        {
            tempStringAdjust = '/' + selectedAdmin.value
        }

        props.tokenSafeAPIRequest('/metrics/admin?limit=15&fetch_links=true&skip=' + (skip+15) + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            if (result.reports.length === 0)
            {
                return '';
            }
            else
            {
                console.log(tableValues)

                let tempTableValues = tableValues
                result.reports.map((report) => {
                    tempTableValues[0].push(report.report_subtype === 'Adjustment' ? report.subagent_ID.first_name + ' ' + report.subagent_ID.last_name:report.submitted_by.first_name + ' ' + report.submitted_by.last_name)
                    tempTableValues[1].push(report.admin_ID === null ? 'No Admin':report.admin_ID.name)
                    tempTableValues[2].push(report.dealership_ID === null ? 'No Dealership':report.dealership_ID.name)
                    tempTableValues[3].push(report.report_type)
                    tempTableValues[4].push(report.report_subtype === 'Adjustment' ? report.report_type:report.contract_type + ' - ' + report.contract_subtype)
                    tempTableValues[5].push(formatDate(new Date(report.date)))
                    tempTableValues[6].push(report.quantity)
                    tempTableValues[7].push(report.contract_gross)
                    tempTableValues[8].push(report.total_contract_count)
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => { console.log(item)
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        subagent_ID: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        subagent_ID: '',
        dealership_ID: '',
        report_type: '',
        contract_type: '',
        date: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'timestamp', label: 'Date' },
        { value: 'contract_gross', label: 'Gross' },
        { value: 'net', label: 'Net' },
    ]

    const sortToName = {
        timestamp: 'Date',
        contract_gross: 'Gross',
        net: 'Net',
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'admin_ID.name', label: 'Admin'}, {id: 'dealership_ID.name', label: 'Dealership'}, {id: 'report_type', label: 'Report Type'}, {id: 'contract_type', label: 'Contract Type'}, {id: 'timestamp', label: 'Date'}, {id: 'quantity', label: 'QTY'}, {id: 'contract_gross', label: 'Gross'}, {id: 'total_contract_count', label: 'Contract Count'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            admin_ID: selectedAdmin.value === '' ? null:selectedAdmin.value,
            start_date: startDate,
            ...filterDataBy
        }
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }
    
    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <OutputColumn colors={props.colors} list={list} title={"Admins"}></OutputColumn>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <Dropdown colors={props.colors} onChange={(e) => setSelectedAdmin(e)} index={props.index} indexParent={props.indexParent} data={allAdmins} width={195} value={selectedAdmin.label}>Test</Dropdown>
                    <span onClick={() => console.log('')} style={{fontWeight: '600', marginLeft: '20px', marginTop: '6px'}}>Filter Admin</span>
                    <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                </div>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <ExportData colors={props.colors} isOpen={exportDataModal} calStartDate={calStartDate} calEndDate={calEndDate} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/admin'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'Contract Count Report'}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
