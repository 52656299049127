import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const timeScale = [
        {value: 'hours', label: 'Hours'},
        {value: 'days', label: 'Days'},
        {value: 'weeks', label: 'Weeks'},
    ]

    const [data, setData] = useState({
        title: '',
        duration: '',
        duration_type: '',
        category: '',
        link: '',
        message: '',
    })

    const [displayData, setDisplayData] = useState({
        title: '',
        duration: '',
        duration_type: '',
        category: '',
        link: '',
        message: '',
    })

    const onDataChange = (e, value) => {
        console.log(e, value)
        if (value === 'duration_type')
        {
            console.log('trying to change a subagent I see!')
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value
            }))
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    return (
        <div style={{width: '400px', height: '456px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>New Annoucement</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: '500'}}>Title</span>
                <TextInput prefix="" onChange={(e) => onDataChange(e, 'title')} colors={props.colors} data={displayData.title}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Duration</span>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'duration')} data={displayData.duration} prefix='' Style={{width: '100%', borderRight: '1px solid ' + props.colors.border}}></TextInput>
                    <Dropdown colors={props.colors} onChange={(e) => onDataChange(e, 'duration_type')} data={timeScale} value={displayData.duration_type} Style={{width: '100px'}}></Dropdown>
                </div>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Category</span>
                <TextInput prefix="" onChange={(e) => onDataChange(e, 'category')} colors={props.colors} data={displayData.category}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Link</span>
                <TextInput prefix="" onChange={(e) => onDataChange(e, 'link')} colors={props.colors} data={displayData.link}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Message</span>
                <TextInput prefix="" onChange={(e) => onDataChange(e, 'message')} colors={props.colors} data={displayData.message}></TextInput>
                <Button onClick={() => props.createAnnoucement(data)} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Submit</Button>
            </div>
        </div>
    )
}

export default Main
