import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from './Sidebar'
import Controller from './Controller'
import MetricHeader from './MetricHeader'

const Main = (props) => {

    const defaultStyle = 
        props.active ? 
        {
            display: 'block',
            backgroundColor: props.colors.primary,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            color: props.colors.fontInvert,
            fontWeight: '500',
        }
        :
        {
            display: 'block',
            backgroundColor: props.colors.block,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            border: '1px solid ' + props.colors.border,
            color: props.colors.font,
            fontWeight: '500',
        }

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'start', overflow: 'hidden'}}>
            {props.children[3]}
            {props.children[4]}
            <div style={{width: '57px', height: '100%', flexShrink: 0}}>
                {/*<Sidebar colors={props.colors} tab={0}></Sidebar>*/}
                {props.children[0]}
            </div>  
            <div style={{height: '100%', flexGrow: 1, display: 'flex', flexDirection: 'column', marginTop: '10px', marginLeft: '1px', maxHeight: '99%', maxWidth: '99%'}}>
                {props.children[1]}
                {props.children[2]}
            </div>
        </div>
        // <div style={{width: '1920px', height: '100vh'}}>

        // </div>
    )
}

export default Main
