import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const [name, setName] = useState('')

    console.log(props)

    const addressTypeToName = (value) => {
        if (value === 1) return 'Dealer Address'
        if (value === 2) return 'Spiffee Address'
        if (value === 3) return 'Subagent Address'
    }

    const processSpifftype = (spiffType) => {
        if (spiffType === '$25 Visa') return 'Visa'
        else if (spiffType === '$50 Visa') return 'Visa'
        else if (spiffType === '$100 Visa') return 'Visa'
        else {return spiffType}
    }

    const formatData = (name, spiffees, reserveInputs) => {
        return ({
            templateName: name,
            template_type: 'Saturday',
            dealer: {name: props.importedThursdayReports[props.selectedThursdayReport].dealership_name, id: props.importedThursdayReports[props.selectedThursdayReport].dealership_ID},
            spiffees:
                spiffees.map((spiff) => {
                    console.log(spiff)
                    return ({
                        "spiffee": {name: spiff.name, id: spiff.id},
                        "amount_per_contract": spiff.amount,
                        "shipping_address": {name: addressTypeToName(spiff.address), id: spiff.address},
                        "ATTN": spiff.ATTN,
                        "spiff_type": {name: processSpifftype(spiff.spiffType), id: spiff.spiffType}
                    })
                }),
            reserveInputs:
                reserveInputs.map((input) => {
                    console.log(input)
                    return ({
                        "account": {name: input.account, id: input.id},
                        "amount": input.amount,
                        "reason": input.reason
                    })
                })
        })
    }

    // let raw = {
    //     name: data.templateName,
    //     template_type: 'Saturday',
    //     dealership_ID: data.dealer,
    //     spiffs: 
    //         data.spiffees.map((spiff) => {
    //             return ({
    //                 "spiffee_ID": spiff.spiffee,
    //                 "amount": spiff.amount_per_contract,
    //                 "address_type": spiff.shipping_address.id,
    //                 "ATTN": spiff.ATTN,
    //                 "spiff_type": spiff.spiff_type.id
    //             })
    //         })
    //     ,
    //     reserve_inputs: 
    //         data.reserveInputs.map((input) => {
    //             return ({
    //                 "account_ID": input.account,
    //                 "amount": input.amount,
    //                 "reason": input.reason
    //             })
    //         })  
    //     ,  

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Save Template</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{fontWeight: '500'}}>Name</span>
                <TextInput prefix="" onChange={(e) => setName(e)} colors={props.colors} data={name}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Spiffees</span>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {props.spiffees.map((spiffee, index) => {
                        return <div style={{margin: '3px', marginLeft: '0'}}><span style={{backgroundColor: props.colors.border, padding: '3px', paddingLeft: '8px', paddingRight: '8px', borderRadius: '5px', margin: '3px', width: 'auto'}}>{spiffee.name} | ${spiffee.amount}</span></div>
                    })}
                </div>
                <span onClick={() => console.log(name)} style={{marginTop: '10px', fontWeight: '500'}}>Reserve Inputs</span>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {props.reserveInputs.map((inputs, index) => {
                        return <div style={{margin: '3px', marginLeft: '0'}}><span style={{backgroundColor: props.colors.border, padding: '3px', paddingLeft: '8px', paddingRight: '8px', borderRadius: '5px', margin: '3px', width: 'auto'}}>{inputs.account} | ${inputs.amount}</span></div>
                    })}
                </div>
                <Button Style={{marginTop: '20px'}} onClick={() => props.saveTemplate(formatData(name, props.spiffees, props.reserveInputs))} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
