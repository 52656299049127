import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const seperateCityAndState = (address) => {
        const parts = address.split(', ');
        // Check if the array has two parts: city and state
        if (parts.length === 2) {
            // Return an array with the city and state
            return [parts[0], parts[1]];
        } else {
            // If the format is not as expected, return an error message or handle accordingly
            throw new Error('Invalid location format. Expected format: "City, State".');
        }
    }

    let tempDataStorage = transposeArray(props.tableValues)[props.selectedSpiffee]

    console.log(props.spiffees[props.selectedSpiffee])

    const [data, setData] = useState({
        first_name: props.spiffees[props.selectedSpiffee].first_name === null ? '':props.spiffees[props.selectedSpiffee].first_name,
        middle_name: props.spiffees[props.selectedSpiffee].middle_name === null ? '':props.spiffees[props.selectedSpiffee].middle_name,
        last_name: props.spiffees[props.selectedSpiffee].last_name === null ? '':props.spiffees[props.selectedSpiffee].last_name,
        code: tempDataStorage[1] === null ? '':tempDataStorage[1],
        position: tempDataStorage[3] === null ? '':tempDataStorage[3],
        address: props.spiffees[props.selectedSpiffee].address === null ? '':props.spiffees[props.selectedSpiffee].address,
        city: seperateCityAndState(tempDataStorage[5])[0],
        state_code: seperateCityAndState(tempDataStorage[5])[1],
        zip_code: props.spiffees[props.selectedSpiffee].zip_code === null ? '':props.spiffees[props.selectedSpiffee].zip_code,
        phone_number: tempDataStorage[8] === null ? '':tempDataStorage[8],
        birthday: tempDataStorage[10] === null ? '':tempDataStorage[10],
        email: tempDataStorage[9] === null ? '':tempDataStorage[9],
        SSN: props.spiffees[props.selectedSpiffee].SSN === null ? '':props.spiffees[props.selectedSpiffee].SSN,
        id: props.spiffees[props.selectedSpiffee].id === null ? '':props.spiffees[props.selectedSpiffee].id,
        ITIN: props.spiffees[props.selectedSpiffee].ITIN === null ? '':props.spiffees[props.selectedSpiffee].ITIN,
        corporate_spiffee: props.spiffees[props.selectedSpiffee].corporate_spiffee === null ? '':props.spiffees[props.selectedSpiffee].corporate_spiffee,
        name: props.spiffees[props.selectedSpiffee].name === null ? '':props.spiffees[props.selectedSpiffee].name
    })

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    return (
        <div style={{width: '715px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Spiffee</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '15%', display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                <IconButton onClick={() => props.onDelete()} colors={props.colors} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Spiffee</span>
            </div>
            <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '10px'}}>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span onClick={() => console.log(data)} style={{fontWeight: '500', marginBottom: '5px'}}>{props.spiffees[props.selectedSpiffee].corporate_spiffee === false ? 'First Name':'Name'}</span>
                        <TextInput onChange={(e) => props.spiffees[props.selectedSpiffee].corporate_spiffee ? onDataChange(e, 'name'):onDataChange(e, 'first_name')} colors={props.colors} data={props.spiffees[props.selectedSpiffee].corporate_spiffee ? data.name:data.first_name} prefix=''></TextInput>
                    </div>
                    {props.spiffees[props.selectedSpiffee].corporate_spiffee ? '':<div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Last Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'last_name')} colors={props.colors} data={data.last_name} prefix=''></TextInput>
                    </div>}
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span onClick={() => console.log(props.spiffees[props.selectedSpiffee])} style={{fontWeight: '500', marginBottom: '5px'}}>Middle Name</span>
                        <TextInput onChange={(e) => onDataChange(e, 'middle_name')} colors={props.colors} data={data.middle_name} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Position</span>
                        <TextInput onChange={(e) => onDataChange(e, 'position')} colors={props.colors} data={data.position} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Street</span>
                        <TextInput onChange={(e) => onDataChange(e, 'address')} colors={props.colors} data={data.address} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>City</span>
                        <TextInput onChange={(e) => onDataChange(e, 'city')} colors={props.colors} data={data.city} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>State</span>
                        <TextInput onChange={(e) => onDataChange(e, 'state_code')} colors={props.colors} data={data.state_code} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Zip Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'zip_code')} colors={props.colors} data={data.zip_code} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Phone Number - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'phone_number')} colors={props.colors} data={data.phone_number} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Birthday - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'birthday')} colors={props.colors} data={data.birthday} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Email - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={data.email} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>SSN - (Optional)</span>
                        <TextInput onChange={(e) => onDataChange(e, 'SSN')} colors={props.colors} data={data.SSN} prefix=''></TextInput>
                    </div>
                </div>
                <div style={{flexGrow: 1, display: 'flex', flexDirection: 'row', marginTop: '10px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>ITIN</span>
                        <TextInput onChange={(e) => onDataChange(e, 'ITIN')} colors={props.colors} data={data.ITIN} prefix=''></TextInput>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                        <span style={{fontWeight: '500', marginBottom: '5px'}}>Code</span>
                        <TextInput onChange={(e) => onDataChange(e, 'code')} colors={props.colors} data={data.code} prefix=''></TextInput>
                    </div>
                </div>
                <Button onClick={() => props.editSpiffee(data)} colors={props.colors} active={true} Style={{width: '150px', marginLeft: 'auto', marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
