import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const [name, setName] = useState('')

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    console.log(transposeArray(props.tableData)[props.selectedReport])

    return (
        <div style={{width: '360px', height: '530px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Save Template</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                <span style={{fontWeight: '500'}}>Name</span>
                <TextInput prefix="" onChange={(e) => setName(e)} colors={props.colors} data={name}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Dealer</span>
                <TextInput colors={props.colors} isDisabled={true} style={{marginTop: '10px', fontWeight: '500'}} data={props.tableValues[2][props.selectedReport]}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Subagent</span>
                <TextInput colors={props.colors} isDisabled={true} style={{marginTop: '10px', fontWeight: '500'}} data={props.tableValues[3][props.selectedReport]}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Admin</span>
                <TextInput colors={props.colors} isDisabled={true} style={{marginTop: '10px', fontWeight: '500'}} data={props.tableValues[4][props.selectedReport]}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Contract Type</span>
                <TextInput colors={props.colors} isDisabled={true} style={{marginTop: '10px', fontWeight: '500'}} data={props.tableValues[5][props.selectedReport]}></TextInput>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Gross / Contract</span>
                <TextInput colors={props.colors} isDisabled={true} prefix='$' style={{marginTop: '10px', fontWeight: '500'}} data={props.tableValues[6][props.selectedReport]}></TextInput>
                <Button Style={{marginTop: '20px'}} onClick={() => {props.quickSaveTemplate([name, ...transposeArray(props.tableData)[props.selectedReport]], transposeArray(props.tableValues)[props.selectedReport]); props.setIsOpen()}} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
