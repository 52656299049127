import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import TextInput from './TextInput'

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
            allowNegative={true/false} - allows number to go negative
            disablePlus={true/false} - allows you to increase number
            disableMinus={true/false} - allows you to decrease number
            maxValue={number} - sets a max value
            minValue={number} - sets a min value
            horizontalPadding - Makes wider with padding
    */}

    const [currentValue, setCurrentValue] = useState(props.data)

    const defaultStyle = 
        {
            backgroundColor: props.colors.block,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '1px',
            paddingBottom: '1px',
            borderRadius: '10000px',
            border: '1px solid ' + props.colors.border,
            color: props.colors.font,
            fontWeight: '500',
            display: 'flex',
            flexDirection: 'row',
        }

    const counterChecks = (change) => {
        if (props.children + change < 0 && props.allowNegative === false)
        {
            props.onChange(props.children, props.index, props.indexParent)
            return props.children;
        }
        else 
        {
            if (props.children + change > props.maxValue || props.children + change < props.minValue)
            {
                props.onChange(props.children, props.index, props.indexParent);
                return props.children;
            }
            else
            {
                //passed checks
                props.onChange(props.children + change, props.index, props.indexParent); 
                return props.children + change
            }
        }
    }

    const setValue = (value) => {
        console.log(value)
        if (value === '')
        {
            props.onChange(0, props.index, props.indexParent); 
            return 0
        }
        if (value > 99)
        {
            props.onChange(99, props.index, props.indexParent); 
            return 99
        }
        if (value < 0 && props.allowNegative === false)
        {
            props.onChange(props.children, props.index, props.indexParent)
            return props.children;
        }
        else 
        {
            if (value > props.maxValue || value < props.minValue)
            {
                props.onChange(props.children, props.index, props.indexParent);
                return props.children;
            }
            else
            {
                //passed checks
                props.onChange(Number.parseFloat(value), props.index, props.indexParent); 
                return value
            }
        }
    }

    const getNumberLength = (value) => {
        let stringVal = value + ''
        return (stringVal.length * 10) + 40
    }

    return (
        <motion.div className='noSelect' style={props.Style === undefined ? defaultStyle:props.default === undefined ? {...defaultStyle, ...props.Style}:props.Style}>
            <button onClick={() => props.disableMinus ? '':counterChecks(-1)} style={{flexBasis: '10%', paddingRight: '20px', fontWeight: '600', fontFamily: 'Montserrat'}}>-</button>
            {/* <div className='center' style={{flexBasis: '90%', borderLeft: '1px solid', borderRight: '1px solid', borderColor: props.colors.border, paddingLeft: props.horizontalPadding, paddingRight: props.horizontalPadding}}><span style={{width: '40px'}}>{props.children}</span></div> */}
            <TextInput colors={props.colors} onChange={(e) => setValue(e)} data={props.children} Style={{border: 'none', textAlign: 'center'}} width={getNumberLength(props.children)}></TextInput>
            <button onClick={() => props.disablePlus ? '':counterChecks(1)} style={{flexBasis: '10%', paddingLeft: '20px', fontWeight: '600', fontFamily: 'Montserrat'}}>+</button>
        </motion.div>
    )
}

export default Main
