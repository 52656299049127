import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../elements/IconButton'
import Dropdown from '../elements/ReactSelectDropdown'
import TextInput from '../elements/TextInput'
import Button from '../elements/Button'

const Main = (props) => {

    const [reason, setReason] = useState('')

    return (
        <div style={{width: '360px', height: '200px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Delete Report</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', textAlign: 'center'}}>
                <span style={{marginTop: '10px', fontWeight: '500', margin: '30px', color: props.colors.muted}}>Are you sure you want to delete this report?</span>
                <Button onClick={() => props.onClick()} Style={{marginTop: '20px', backgroundColor: props.colors.warning}} active={true} colors={props.colors}>Delete</Button>
            </div>
        </div>
    )
}

export default Main
