import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [data, setData] = useState({
        first_name: props.MGMT[props.selectedMGMT].first_name !== null ? props.MGMT[props.selectedMGMT].first_name : '',
        last_name: props.MGMT[props.selectedMGMT].last_name !== null ? props.MGMT[props.selectedMGMT].last_name : '',
        SSN: props.MGMT[props.selectedMGMT].SSN !== null ? props.MGMT[props.selectedMGMT].SSN : '',
        username: props.MGMT[props.selectedMGMT].username !== null ? props.MGMT[props.selectedMGMT].username : '',
        password: '',
        email: props.MGMT[props.selectedMGMT].email !== null ? props.MGMT[props.selectedMGMT].email : '',
        id: props.MGMT[props.selectedMGMT].id !== null ? props.MGMT[props.selectedMGMT].id : '',
    });

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Office Staff</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '15%', display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                <IconButton onClick={() => props.deleteMGMT(data.id)} colors={props.colors} size={'25px'} Style={{backgroundColor: props.colors.warning, padding: '3px', borderRadius: '5px'}} image={Delete}></IconButton>
                <span style={{fontWeight: '600', marginTop: '5px', marginLeft: '10px'}}>Delete Office Staff</span>
            </div>
            <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
            <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>First Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'first_name')} colors={props.colors} data={data.first_name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Last Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'last_name')} colors={props.colors} data={data.last_name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Email</span>
                    <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={data.email} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Username</span>
                    <TextInput onChange={(e) => onDataChange(e, 'username')} colors={props.colors} data={data.username} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500', marginBottom: '5px'}}>Password</span>
                    <TextInput onChange={(e) => onDataChange(e, 'password')} colors={props.colors} data={data.password} prefix=''></TextInput>
                </div>
                <Button onClick={() => props.editMGMT(data)} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
