import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import { useCookies } from 'react-cookie'

import ReceiveDollar from '../media/icons/icons8-receive-dollar-96.png'
import RequestMoney from '../media/icons/icons8-request-money-96.png'
import Contract from '../media/icons/icons8-contract-96.png'

const Main = (props) => {

    {/*
    Documentation
        Props:
            default={false/true} - Enables default styles entirely - True by default
            Style={...styles} - Passthrough to styles for complete control - empty by default
    */}

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [headerData, setHeaderData] = useState([
        {
            image: ReceiveDollar,
            title: 'Weekly Gross',
            change: '-',
            total: 0,
        },
        {
            image: RequestMoney,
            title: 'Weekly Net',
            change: '-',
            total: 0,
        },
        {
            image: Contract,
            title: 'Weekly Contracts',
            change: '-',
            total: 0,
        },
        {
            image: Contract,
            title: 'Monthly Contract Count',
            change: '-',
            total: 0,
        },
    ])

    const [cookies, setCookies] = useCookies([])

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }
    
        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };
    
        props.tokenSafeAPIRequest('/metrics/subagent/me', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            setHeaderData([
                {
                    image: ReceiveDollar,
                    title: 'Weekly Gross',
                    change: '-',
                    total: formatNumber(result.total_gross),
                },
                {
                    image: RequestMoney,
                    title: 'Weekly Net',
                    change: '-',
                    total: formatNumber(result.total_net),
                },
                {
                    image: Contract,
                    title: 'Weekly Contracts',
                    change: '-',
                    total: formatNumber(result.total_contract_count),
                },
                {
                    image: Contract,
                    title: 'Monthly Contract Count',
                    change: '-',
                    total: formatNumber(result.total_monthly_contract_count),
                },
            ])
        })
    },[])

    const containerWidth = useRef(null)

    const [amountToDisplay, setAmountToDisplay] = useState(4)

    const updateNumItems = () => {
        const width = containerWidth.current.offsetWidth;
        console.log(width)

        if (width < 1237 && width > 901) 
        {
            setAmountToDisplay(3);
        }
        else if (width < 900) 
        {
            setAmountToDisplay(2);
        }
        else 
        {
            setAmountToDisplay(4);
        }
    }

    useEffect(() => {
        window.addEventListener('resize', updateNumItems);
        updateNumItems(); // Initial check on mount
    
        // Cleanup listener on unmount
        return () => {
          window.removeEventListener('resize', updateNumItems);
        };
    }, []);

    const defaultStyle = 
    {
        display: 'flex',
        flexDirection: 'row',
        margin: '39px'
    }

    return (
        <motion.div ref={containerWidth} onClick={props.onClick} style={props.Style === undefined ? defaultStyle : props.default === undefined ? { ...defaultStyle, ...props.Style } : props.Style}>
            {headerData.slice(0, amountToDisplay).map((element, index) => { 
                return <div style={{flexBasis: '25%', display: 'flex', flexDirection: 'row'}}>
                    <div className='center' style={{flexBasis: 'auto', backgroundColor: props.colors.block, borderRadius: '17px', border: '1px solid ' + props.colors.border}}>
                        <img style={{width: '58px', margin: '20px'}} src={element.image}></img>
                    </div>
                    <div style={{flexBasis: '66%', display: 'flex', flexDirection: 'column', marginLeft: '20px'}}>
                        <span style={{fontWeight: '500', fontSize: index === 3 ? '18px':'20px'}}>{element.title}</span>
                        <span style={{fontWeight: '600', fontSize: '16px', color: element.change > 0 ? 'green' : 'red'}}>{element.change}%</span>
                        <span style={{fontWeight: '500', fontSize: '36px'}}>{index >= 2 ? '' : '$'}{element.total}</span>
                    </div>
                </div>
            })}
        </motion.div>
    )
}

export default Main
