import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from './Sidebar'
import Controller from './Controller'
import MetricHeader from './MetricHeader'

const Main = (props) => {

    const defaultStyle = 
        props.active ? 
        {
            display: 'block',
            backgroundColor: props.colors.primary,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            color: props.colors.fontInvert,
            fontWeight: '500',
        }
        :
        {
            display: 'block',
            backgroundColor: props.colors.block,
            paddingLeft: '20px',
            paddingRight: '20px',
            paddingTop: '6px',
            paddingBottom: '6px',
            borderRadius: '10000px',
            border: '1px solid ' + props.colors.border,
            color: props.colors.font,
            fontWeight: '500',
        }

    return (
        <div style={{width: '100vw', height: '100vh', backgroundColor: props.colors.backgroundColor, display: 'flex', flexDirection: 'row', justifyContent: 'start', overflow: 'hidden'}}>
            {props.children[7]}
            {props.children[8]}
            <div style={{width: '57px', height: '100%', flexShrink: 0}}>
                {/*<Sidebar colors={props.colors} tab={0}></Sidebar>*/}
                {props.children[0]}
            </div>  
            <div style={{width: '370px', height: '100%', flexShrink: 0}}>
                {/* {<Controller colors={props.colors} buttonList={props.buttonList} title="Thursday Reporting"></Controller>} */}
                {props.children[1]}
            </div>
            <div style={{flexGrow: 1, flexShrink: 1, flexBasis: '0%', marginLeft: '20px', display: 'flex', flexDirection: 'column'}}>
                <div style={{flexBasis: '188px', borderBottom: '1px solid ' + props.colors.border}}>
                    {/* <MetricHeader colors={props.colors} headerData={props.headerData}></MetricHeader> */}
                    {props.children[2]}
                </div>
                <div style={{flexBasis: 'auto', height: 'calc(100% - 188px)', display: 'flex', flexDirection: 'row'}}>
                    <div style={{borderRight: '1px solid ' + props.colors.border, flexBasis: 'auto'}}>{props.children[3]}</div>
                    <div style={{borderRight: '1px solid ' + props.colors.border, flexBasis: 'auto'}}>{props.children[4]}</div>
                    <div style={{display: 'flex', flexDirection: 'column', flexBasis: 'auto', flexGrow: 1, paddingRight: '20px'}}>
                        <div style={{borderBottom: '1px solid ' + props.colors.border}}>{props.children[5]}</div>
                        {props.children[6]}
                    </div>
                </div>
            </div>
        </div>
        // <div style={{width: '1920px', height: '100vh'}}>

        // </div>
    )
}

export default Main
