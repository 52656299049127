import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import UserTab from '../../elements/UserTab.jsx'
import Button from '../../elements/Button.jsx'

import CommissionReceivedImage from '../../media/icons/icons8-open-email-96.png'
import Agent from '../../media/icons/icons8-agent-96.png'
import OpenEnvelope from '../../media/icons/icons8-open-envelope-96.png'
import Adjustments from '../../media/icons/icons8-adjustment-96.png'
import BarChart from '../../media/icons/icons8-bar-chart-96.png'
import Customer from '../../media/icons/icons8-customer-96.png'
import NotificationIcon from '../../media/icons/icons8-notification-96-2.png'

import { useCookies } from 'react-cookie' 

const Main = (props) => {

    console.log(props)

    function convertDateFormatWithSubtraction(dateStr) {
        console.log(dateStr)
        if (dateStr === '')
        {
            return convertDateFormatWithSubtraction(new Date())
        }
        // Parse the date string
        var dateObj = new Date(dateStr);
    
        // Subtract 5 hours from the date
        dateObj.setHours(dateObj.getHours() - 5);
    
        // Extracting date components
        var month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Months are 0-based in JS
        var day = ('0' + dateObj.getDate()).slice(-2);
        var year = dateObj.getFullYear().toString().substr(-2);
    
        // Constructing the new date format
        return month + '/' + day + '/' + year;
    }
    

    return (
        <div style={{width: '100%', height: 'auto', display: 'flex', flexDirection: 'column', padding: '20px', paddingLeft: '30px', paddingRight: '30px'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                <span style={{fontWeight: '500', fontSize: '20px'}}>Announcements</span>
                <Button colors={props.colors} onClick={() => props.setNewAnnoucementModal(true)} active={true} Style={{borderRadius: '5px', fontSize: '24px', width: '35px', height: '35px', padding: 0, marginLeft: '20px'}}>+</Button>
            </div>
            <div style={{height: 'auto', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '10px', marginTop: '20px', display: 'flex', flexDirection: 'column', padding: '5px', paddingLeft: '15px', paddingRight: '15px'}}>
                <div style={{width: '100%', height: '36px', display: 'flex', flexDirection: 'row', borderBottom: '1px solid ' + props.colors.border}}>
                    <span style={{fontWeight: '600' , marginTop: 'auto', marginBottom: 'auto'}}>{props.annoucement === undefined ? 'No Annoucements':props.annoucement.title}</span>
                    <span style={{marginLeft: '15px', backgroundColor: props.colors.primary, height: 'fit-content', borderRadius: '5px', padding: '1px' , marginTop: 'auto', marginBottom: 'auto', fontWeight: '500', color: props.colors.block, paddingLeft: '10px', paddingRight: '10px'}}>{props.annoucement === undefined ? '':props.annoucement.category}</span>
                    <span style={{marginLeft: 'auto', color: props.colors.muted, fontWeight: '500', marginTop: 'auto', marginBottom: 'auto'}}>{convertDateFormatWithSubtraction(props.annoucement === undefined ? '':props.annoucement.timestamp)}</span>
                </div>
                <div style={{width: '100%', height: '65px'}}>
                    <span style={{color: props.colors.muted, fontSize: '14px', lineHeight: '2px'}}>{props.annoucement === undefined ? '':props.annoucement.message}</span>
                </div>
                <div style={{width: '100%', height: '35px', marginTop: '10px'}}>
                    <Button colors={props.colors} Style={{padding: '3px 20px 3px 20px', marginLeft: 'auto'}} active={true} onClick={() => props.annoucement === undefined ? '':props.setAnnoucementLinkModal(true)}>Event Link</Button>
                </div>
            </div>
        </div>
    )
}

export default Main
