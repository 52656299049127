import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [dpppReason, setDPPPReason] = useState('')
    const [flatsReason, setFlatsReason] = useState('')

    console.log(props)

    return (
        <div style={{width: '360px', height: props.DPPPOmitted && props.flatsOmitted ? '600px':'300px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>DPPP / Flat Omitted</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            {props.DPPPOmitted && <div style={{flexBasis: props.DPPPOmitted && props.flatsOmitted ? '40%':'90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '10px', fontWeight: '500'}}>DPPP Omission Reason</span>
                <textarea onChange={(e) => setDPPPReason(e.target.value)} value={dpppReason} style={{border: '1px solid ' + props.colors.border, borderRadius: '5px', height: '100%', marginTop: '10px', fontFamily: 'Montserrat', padding: '10px'}}></textarea>
            </div>}
            {props.flatsOmitted && <div style={{flexBasis: props.DPPPOmitted && props.flatsOmitted ? '50%':'90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '10px', fontWeight: '500'}}>Flats Omission Reason</span>
                <textarea onChange={(e) => setFlatsReason(e.target.value)} value={flatsReason} style={{border: '1px solid ' + props.colors.border, borderRadius: '5px', height: '100%', marginTop: '10px', fontFamily: 'Montserrat', padding: '10px'}}></textarea>
            </div>}
            <Button onClick={() => {props.setIsOpen(); props.setDPPPOmittedReason(dpppReason); props.setFlatsOmittedReason(flatsReason); props.submitReport({reason: props.tempReasonState, dpppReason: dpppReason, flatsReason: flatsReason});}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Submit</Button>
        </div>
    )
}

export default Main
