import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Weekly 7 Eligibility</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                    <span style={{fontWeight: '500', marginRight: '10px'}}>Eligibility</span>
                    <div style={{cursor: 'pointer', width: '20px', height: '20px', border: false === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: false === true  ? props.colors.primary:''}}></div>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '20px'}}>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <span style={{fontWeight: '500'}}>Inegligible Dates</span>
                        <span>10/24/23</span>
                    </div>
                    <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1}}>
                        <span style={{fontWeight: '500'}}>Reinstated</span>
                        <span>-</span>
                    </div>
                </div>
                <Button colors={props.colors} active={true} Style={{marginTop: '15px'}}>Close</Button>
            </div>
        </div>
    )
}

export default Main
