import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link, useNavigate } from "react-router-dom"

import Modal from 'react-modal';

import CBA from '../media/images/CBALogo.png'

import TextInput from '../elements/TextInput'
import Button from '../elements/Button'
import Notification from '../elements/Notification'

import Login_Graphic from '../media/images/Login_Graphic.png'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [cookies, setCookie, removeCookie, updateCookies] = useCookies([])

    const navigate = useNavigate();

    removeCookie('real_user')

    const login = () => {
        const formData = new URLSearchParams();
        formData.append("username", username);
        formData.append("password", password)

        fetch(props.baseURL + '/auth/login', {
            method: "POST", 
            redirect: 'follow',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                'Accept': 'application/json',
            },
            body: formData
        })
        .then(res => res.json())
        .then(json => {
            console.log(json)
            const expireDate = new Date(json.access_token_expires)
            const expireDate2 = new Date(json.refresh_token_expires)
            const maxAgeDuration = (((expireDate.getTime() - new Date().getTime())/1000)- 18000)
            const maxAgeDuration2 = (((expireDate2.getTime() - new Date().getTime())/1000)- 18000)
            localStorage.clear();
            rememberMe ? setCookie('access_token', json.access_token, {maxAge: maxAgeDuration ? maxAgeDuration:0}):setCookie('access_token', json.access_token)
            rememberMe ? setCookie('refresh_token', json.refresh_token, {maxAge: maxAgeDuration2 ? maxAgeDuration2:0}):setCookie('refresh_token', json.refresh_token)
            setCookie('access_token_expires', json.access_token_expires)
            setCookie('refresh_token_expires', json.refresh_token_expires)
            localStorage.setItem('access_token', json.access_token)
            localStorage.setItem('refresh_token', json.refresh_token)
            localStorage.setItem('access_token_expires', json.access_token_expires)
            localStorage.setItem('refresh_token_expires', json.refresh_token_expires)
            removeCookie('real_user')
            if (json.access_token !== undefined && json.refresh_token !== undefined) 
            {
                getUserData(json.access_token)
            }
            else
            {
                setNotification(props.getServerResponse(json))
            }
        })
    }

    const refresh_token = cookies['refresh_token']

    const getUserData = (access_token) => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + access_token);

        console.log('getting data with this token: ' + access_token)

        var requestOptions = {
        method: 'GET',
        headers: myHeaders,
        redirect: 'follow'
        };

        fetch(props.baseURL + "/user/me?", requestOptions)
        .then(response => response.json())
        .then(result => {
            console.log('Logged in as: ' + result.first_name + ' ' + result.last_name)
            setCookie('name', result.first_name + ' ' + result.last_name)
            setCookie('role', result.role)
            localStorage.setItem('name', result.first_name + ' ' + result.last_name)
            localStorage.setItem('role', result.role)
            navigate('/')
        })
        .catch(error => console.log('error', error));
    }

    useEffect(() => {
        // Define the function to run when Enter is pressed
        const handleKeyPress = (event) => {
          if (event.key === 'Enter') {
            login();
          }
        };
    
        // Add event listener for key press
        window.addEventListener('keypress', handleKeyPress);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('keypress', handleKeyPress);
        };
      }, [username, password]); // Empty dependency array ensures the effect runs only once after initial render
    

    const [notification, setNotification] = useState('')

    const [rememberMe, setRememberMe] = useState(false)

    return (
        <div style={{width: '100%', height: '100vh', backgroundColor: props.colors.backgroundColor}}>

            <Notification colors={props.colors} message={notification}></Notification>

            <div style={{width: '450px', height: '80%', margin: 'auto', display: 'flex', flexDirection: 'column'}}>
                <img src={CBA} style={{marginLeft: 'auto', marginRight: 'auto', marginTop: '100px'}} width="305px"></img>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px'}}>Username</span>
                <TextInput colors={props.colors} data={username} onChange={(e) => setUsername(e)}></TextInput>
                <span style={{fontWeight: '500', fontSize: '20px', marginTop: '30px', marginBottom: '5px'}}>Password</span>
                <TextInput colors={props.colors} data={password} onChange={(e) => setPassword(e)} type="password"></TextInput>
                <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '15px'}}>
                    <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                        <div onClick={() => setRememberMe((rememberMe) => !rememberMe)} style={{cursor: 'pointer', width: '14px', height: '14px', border: rememberMe === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '3px', backgroundColor: rememberMe === true  ? props.colors.primary:''}}></div>
                        <span className='noSelect' onClick={() => setRememberMe((rememberMe) => !rememberMe)} style={{marginLeft: '10px', fontWeight: '500', cursor: 'pointer'}}>Remember Me</span>
                    </div>
                    <Link to="/forgotpassword"><span style={{fontWeight: '500', color: props.colors.primary, cursor: 'pointer'}}>Forgot Password</span></Link>
                </div>
                <Button onClick={() => login()} colors={props.colors} active={true} Style={{marginTop: '20px'}}>Login</Button>
            </div>
            <img src={Login_Graphic} style={{height: '20%'}}></img>
        </div>
    )
}

export default Main
