import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import { useCookies } from 'react-cookie'

import Delete from '../../media/icons/icons8-delete-96.png'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const dealers2 = [
        { value: 'days', label: 'Days' },
        { value: 'weeks', label: 'Weeks' },
        { value: 'months', label: 'Months' },
        { value: 'years', label: 'Years' },
    ]

    const bonusType = [
        { value: 'Bonus', label: 'Bonus' },
        { value: 'Deduction', label: 'Deduction' },
    ]

    const [tab, setTab] = useState(0)

    const dealers = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [data, setData] = useState({
        commission_rate: '',
        commission_rate_expiry: '',
        unlimited: false,
        duration: '',
        duration_type: '',
        bonuses_deductions: [],
        new_dealership_rate: '',
        new_dealership_id: '',
        new_dealership_description: '',
    })

    const [dataDisplay, setDataDisplay] = useState({
        commission_rate: '',
        commission_rate_expiry: '',
        unlimited: false,
        duration: '',
        duration_type: '',
        bonuses_deductions: [],
        new_dealership_rate: '',
        new_dealership_id: '',
        new_dealership_description: '',
    })

    const addBonusDeduction = () => {
        setDataDisplay(dataDisplay => {
            // Clone the current state
            const newDataDisplay = { ...dataDisplay };
    
            // Update the bonuses_deductions array
            newDataDisplay.bonuses_deductions = [...newDataDisplay.bonuses_deductions, { amount: '', description: '', type: '' }];
    
            return newDataDisplay;
        });
        setData(dataDisplay => {
            // Clone the current state
            const newDataDisplay = { ...dataDisplay };
    
            // Update the bonuses_deductions array
            newDataDisplay.bonuses_deductions = [...newDataDisplay.bonuses_deductions, { amount: '', description: '', type: '' }];
    
            return newDataDisplay;
        });
    }

    const deleteBonusDeduction = (index) => {
        console.log(index)
        const newBonusesDeductions = data.bonuses_deductions.filter((_, i) => i !== index);
        const newBonusesDeductions2 = dataDisplay.bonuses_deductions.filter((_, i) => i !== index);

        // Update the state with the new array
        setData({
            ...data,
            bonuses_deductions: newBonusesDeductions
        });;
        setDataDisplay({
            ...data,
            bonuses_deductions: newBonusesDeductions2
        });;
    }

    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e.value === undefined ? e:e.value
        }))
        setDataDisplay(prevData => ({...prevData, [value]: e.value === undefined ? e:e.label}))
    }

    const onBonusDeductionChange = (e, value, index) => {

        console.log(e, value, index)
        // Create a new array with all the existing items
        const updatedBonusesDeductions = [...dataDisplay.bonuses_deductions];
        const updatedBonusesDeductionsData = [...data.bonuses_deductions]
    
        // Update the specific item at the given index
        // We need to ensure we're not directly modifying the state, so we spread the existing item
        // and then overwrite the property given by 'value' with the new value from 'e'
        updatedBonusesDeductions[index] = {
            ...updatedBonusesDeductions[index],
            [value]: e.value === undefined ? e:e.value
        };

        updatedBonusesDeductionsData[index] = {
            ...updatedBonusesDeductionsData[index],
            [value]: e.value === undefined ? e:e.label
        };

        console.log(updatedBonusesDeductions, updatedBonusesDeductionsData)
    
        // Set the new state
        setDataDisplay({
            ...dataDisplay,
            bonuses_deductions: updatedBonusesDeductions
        });

        setData({
            ...data,
            bonuses_deductions: updatedBonusesDeductionsData
        })
    };

    function formatDateString(dateString) {
        // Parse the date string into a Date object
        const date = new Date(dateString);
    
        // Options for toLocaleDateString to format the date as 'Month Day, Year'
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
    
        // Format the date
        return date.toLocaleDateString('en-US', options);
    }

    const overrideCheck = () => {
        if (props.subagents[props.selectedSubagent] === null)
        {
            return false
        }
        if (props.subagents[props.selectedSubagent].commission_rate === 0)
        {
            return false
        }
        return true
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Code',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Name',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Override Rate',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const [dealerList, setDealerList] = useState()
    const [refresh, setRefresh] = useState(false)

    useEffect(() => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/full/' + props.subagents[props.selectedSubagent].id, requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let tempDropdownData = []
            result.dealership_connections.map((dealer, index) => {
                tempDropdownData.push({label: dealer.dealership_ID.name, value: dealer.dealership_ID.id})
            })

            setDealerList(tempDropdownData)

            let tempTableData = [
                [],
                [],
                [],
            ]
            let tempTableValues = [
                [],
                [],
                [],
            ]
            result.dealership_connections.map((dealer, index) => {
                if (dealer.commission_rate !== 0)
                {
                    tempTableData[0].push(dealer.dealership_ID.code)
                    tempTableData[1].push(dealer.dealership_ID.id)
                    tempTableData[2].push(dealer.commission_rate)
    
                    tempTableValues[0].push(dealer.dealership_ID.code)
                    tempTableValues[1].push(dealer.dealership_ID.name)
                    tempTableValues[2].push(dealer.commission_rate)
                }
            })

            console.log(tableValues)

            setTableData(tempTableData)
            setTableValues(tempTableValues)
        })
    },[refresh])

    console.log(props.subagents[props.selectedSubagent].dealership_connections)

    const clearNewDealerData = () => {
        setData(prevData => ({
            ...prevData,
            new_dealership_rate: '',
            new_dealership_id: '',
            new_dealership_description: '',
        }));
        setDataDisplay(prevData => ({
            ...prevData,
            new_dealership_rate: '',
            new_dealership_id: '',
            new_dealership_description: '',
        }));
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span onClick={() => console.log(data)} style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Override Commission Rate</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{display: 'flex', flexDirection: 'row', height: '35px', marginTop: '20px'}}>
                <Button onClick={() => setTab(0)} colors={props.colors} active={tab === 0 ? true:false} Style={{flexGrow: 1, marginRight: '5px'}}>Overall</Button>
                <Button onClick={() => setTab(1)} colors={props.colors} active={tab === 1 ? true:false} Style={{flexGrow: 1, marginLeft: '5px'}}>Dealers</Button>
                <Button onClick={() => setTab(2)} colors={props.colors} active={tab === 2 ? true:false} Style={{flexGrow: 1, marginLeft: '5px'}}>Bonus/Deductions</Button>
            </div>
            {tab === 0 ? 
            <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                {overrideCheck() ? <div>
                    <span style={{fontWeight: '500'}}>Current Override</span>
                    <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px', backgroundColor: props.colors.primary, color: props.colors.fontInvert, borderRadius: '5px'}}>
                        <span style={{marginLeft: '10px'}}>{props.subagents[props.selectedSubagent].commission_rate * 100}% - Expires: {formatDateString(props.subagents[props.selectedSubagent].commission_expiry)}</span>
                        <span onClick={() => {{props.editSubagentRate({commission_rate: 0.00051, duration: -1000, duration_type: 'years'}, props.subagents[props.selectedSubagent].id);}}} style={{marginLeft: 'auto', marginRight: '10px', fontWeight: '600', cursor: 'pointer'}}>X</span>
                    </div>
                </div>:''}
                <span style={{fontWeight: '500'}}>Commission Rate</span>
                <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'commission_rate')} prefix='' suffix="%" data={dataDisplay.commission_rate}></TextInput>
                <span style={{fontWeight: '500', marginTop: '15px'}}>Duration</span>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'duration')} prefix='' data={dataDisplay.duration} Style={{width: '30%', marginRight: '5px'}}></TextInput>
                    <Dropdown colors={props.colors} onChange={(e) => onDataChange(e, 'duration_type')} data={dealers2} value={dataDisplay.duration_type} Style={{width: 300}}></Dropdown>
                </div>
                <div style={{display: 'flex', flexDirection: 'row', marginTop: '5px'}}>
                    <div onClick={() => onDataChange(!data.unlimited, 'unlimited')} style={{cursor: 'pointer', width: '20px', height: '20px', border: data.unlimited === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: data.unlimited === true  ? props.colors.primary:''}}></div>
                    <span style={{fontWeight: '500', marginLeft: '10px'}}>Unlimited</span>
                </div>
                <Button onClick={() => {props.editSubagentRate(data, props.subagents[props.selectedSubagent].id);}} colors={props.colors} active={true} Style={{marginTop: '100px'}}>Save</Button>
            </div>
            :''}
            {tab === 1 ? <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <span style={{fontWeight: '500', marginTop: '15px'}}>Dealer Rate Override</span>
                </div>
                <div style={{width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '5px', marginTop: '5px'}}>
                    <div style={{display: 'flex', flexDirection: 'row'}}>
                        <Dropdown colors={props.colors} data={dealerList} value={dataDisplay.new_dealership_id} onChange={(e) => onDataChange(e, 'new_dealership_id')} Style={{width: 500, border: 0, borderRight: '1px solid ' + props.colors.border}}></Dropdown>
                        <TextInput colors={props.colors} onChange={(e) => onDataChange(e, 'new_dealership_rate')} data={dataDisplay.new_dealership_rate} prefix='' suffix="%" Style={{width: 100, border: 0}}></TextInput>
                        <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px', marginLeft: 'auto', marginTop: '5px', marginRight: '5px'}} size="16px">
                            <div style={{paddingLeft: '3px', paddingRight: '3px'}}><span>x</span></div>
                        </IconButton>
                    </div>
                    <textarea placeholder='Note' onChange={(e) => onDataChange(e.target.value, 'new_dealership_description')} value={dataDisplay.new_dealership_description} style={{fontFamily: 'montserrat', borderTop: '1px solid ' + props.colors.border, padding: '5px'}}></textarea>
                </div>
                <Table colors={props.colors} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></Table>
                <Button onClick={() => {props.setDealershipOverride(data, props.subagents[props.selectedSubagent].id, props.subagents[props.selectedSubagent].dealership_connections); setRefresh((refresh) => !refresh); clearNewDealerData();}} colors={props.colors} active={true} Style={{marginTop: '100px'}}>Save</Button>
            </div>
            :''}
            {tab === 2 ? <div style={{flexBasis: '75%', display: 'flex', flexDirection: 'column', marginTop: '20px'}}>
                <span style={{fontWeight: '500', marginTop: '15px'}}>Active Bonuses/Deductions</span>
                <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                    {props.subagents[props.selectedSubagent].bonuses_deductions.map((adjustment, index) => {
                        return <span onClick={() => ''} style={{backgroundColor: props.colors.block, border: '1px solid' + props.colors.border, borderRadius: '3px', padding: '1px 5px 1px 5px', margin: '3px'}}>{adjustment.bonus === true ? '-':'+'}${Math.abs(adjustment.amount) + ' To Week of: ' + formatDateString(adjustment.timestamp)}</span>
                    })}
                </div>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    <span style={{fontWeight: '500', marginTop: '15px'}}>Add Bonuses/Deductions</span>
                    <Button colors={props.colors} onClick={() => addBonusDeduction()} Style={{paddingLeft: '5px', paddingRight: '5px', borderRadius: '3px',  marginLeft: '10px', height: '25px', paddingTop: '0px', paddingBottom: '0px', marginTop: '15px'}}>+</Button>
                </div>
                {data.bonuses_deductions.map((change, index) => { 
                    return <div style={{width: '100%', display: 'flex', flexDirection: 'column', backgroundColor: props.colors.block, border: '1px solid ' + props.colors.border, borderRadius: '5px', marginTop: '5px'}}>
                                <div style={{display: 'flex', flexDirection: 'row'}}>
                                    <TextInput colors={props.colors} onChange={(e) => onBonusDeductionChange(e, 'amount', index)} data={dataDisplay.bonuses_deductions[index].amount} prefix='$' Style={{width: 100, border: 0, borderRight: '1px solid ' + props.colors.border}}></TextInput>
                                    <Dropdown colors={props.colors} onChange={(e) => onBonusDeductionChange(e, 'type', index)} data={bonusType} value={dataDisplay.bonuses_deductions[index].type} Style={{width: 200, border: 0}}></Dropdown>
                                    <IconButton onClick={() => deleteBonusDeduction(index)} img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px', marginLeft: 'auto', marginTop: '5px', marginRight: '5px'}} size="16px">
                                        <div style={{paddingLeft: '3px', paddingRight: '3px'}}><span>x</span></div>
                                    </IconButton>
                                </div>
                                <textarea placeholder='Note' onChange={(e) => onBonusDeductionChange(e.target.value, 'description', index)} value={dataDisplay.bonuses_deductions[index].description} style={{fontFamily: 'montserrat', borderTop: '1px solid ' + props.colors.border, padding: '5px'}}></textarea>
                            </div>
                })}
                <Button onClick={() => {props.addBonusDeduction(data, props.subagents[props.selectedSubagent].id, props.subagents[props.selectedSubagent].bonuses_deductions)}} colors={props.colors} active={true} Style={{marginTop: '100px'}}>Save</Button>
            </div>:''}
        </div>
    )
}

export default Main
