import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Button from './Button.jsx'
import IconButton from './IconButton.jsx'

import Edit from '../media/icons/icons8-edit-96.png'
import Up from '../media/icons/icons8-up-96.png'
import Down from '../media/icons/icons8-down-96.png'

const Main = (props) => {

    const [sort, setSort] = useState([-1, -1])

    const tableRef = useRef(null);
    const {scrollY} = useScroll({container: tableRef})

    const updateSort = (index) => {
        if (index === sort[0])
        {
            if (sort[1] >= 2)
            {
                setSort([-1, -1])
            }
            else { setSort((sort) => [index, (sort[1] + 1)]) }
        }
        else 
        {
            setSort([index, 1])
        }
    }

    const sortArrow = (index) => {
        if (index === sort[0])
        {
            return sort[1] === 1 ? <img src={Up} width='20px' style={{margin: 0, position: 'relative'}}></img>:sort[1] === 2 ? <img src={Down} width='20px' style={{margin: 0, position: 'relative'}}></img>:''
        }
    }

    const debounceTimerRef = useRef();

    useMotionValueEvent(scrollY, "change", (latest) => {
        if (latest > (tableRef.current.scrollHeight - 1500)) //number here is a mysterious constant that changes, might cause problems with responsive designs.
        {
            // Reset the debounce timer
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }

            debounceTimerRef.current = setTimeout(() => {
                console.log('Getting More Data')
                props.onBottom();
            }, 500); // 3000 ms = 3 seconds
        }
    })

    useEffect(() => {
        return () => {
            if (debounceTimerRef.current) {
                clearTimeout(debounceTimerRef.current);
            }
        };
    }, []);

    const [columnWidths, setColumnWidths] = useState([]); // Step 1: Initialize state for column widths

    useEffect(() => {
        // Function to measure and update column widths based on the wider of the title or data
        console.log('Updating Width!')
        const updateColumnWidths = () => {
            const widths = props.tableInfo.map((item, index) => {
                // Measure title width
                const titleRef = document.getElementById(`column-title-${index}-` + props.keys);
                const titleWidth = titleRef ? titleRef.offsetWidth : 0;

                // Measure data width
                const dataRef = document.getElementById(`column-data-${index}-` + props.keys);
                const dataWidth = dataRef ? dataRef.offsetWidth : 0;

                // Return the maximum of the two
                return Math.max(titleWidth, dataWidth);
            });

            setColumnWidths(widths); // Update state with calculated widths
        };

        updateColumnWidths();
        // Optionally, add an event listener for window resize to update widths dynamically
    }, [props.tableData[0].length, props.dealers]); // Depend on tableInfo and tableData to recalculate when they change
    

    return (
        <div style={{display: 'flex', flexDirection: 'row', padding: '20px', paddingTop: '0px', overflow: 'auto', flexGrow: 2}}>
            <div style={{display: 'flex', flexDirection: 'column', height: '100%'}}>
                <div style={{display: 'flex', flexDirection: 'row'}}>
                    {props.tableInfo.map((item, indexParent) => {

                        const titleStyle = {
                            width: columnWidths[indexParent] ? `${columnWidths[indexParent]}px` : 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 'min-content'
                        };

                        return (
                            /* Titles */
                            <div id={`column-title-${indexParent}-` + props.keys} style={titleStyle}>
                                <div onClick={() => ''} className='center noSelect' style={{backgroundColor: 'white', paddingLeft: '15px', paddingRight: '15px', border: '1px solid ' + props.colors.border, borderTopLeftRadius: indexParent === 0 ? '5px':'0px', borderTopRightRadius: indexParent === props.tableInfo.length-1 ? '5px':'0px', paddingTop: '10px', paddingBottom: '10px', cursor: 'pointer', display: 'flex', flexDirection: 'row'}}>
                                    <span style={{fontWeight: '500', whiteSpace: 'nowrap'}}>{item.title}</span>{sortArrow(indexParent)}
                                </div>
                            </div>
                            /*End Titles */
                        );
                    })}
                </div>
                <div ref={tableRef} style={{display: 'flex', flexDirection: 'row', overflowY: 'scroll', flexGrow: 2}}>
                    {props.tableInfo.map((item, indexParent) => {

                        const titleStyle = {
                            width: columnWidths[indexParent] ? `${columnWidths[indexParent]}px` : 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            minWidth: 'min-content'
                        };

                        return (
                            /* Data Stacked Vertically */
                            <div id={`column-data-${indexParent}-` + props.keys} style={titleStyle}>
                                {props.tableData[indexParent].map((data, index) => {
                                    return (
                                        <div className='center' style={{borderBottom: '1px solid ' + props.colors.border, borderLeft: indexParent === 0 ? '1px solid ' + props.colors.border:'', borderRight: indexParent === props.tableInfo.length-1 ? '1px solid ' + props.colors.border:'', minHeight: '50px', padding: '5px'}}>
                                            {item.displayElement({colors: props.colors, index: index, indexParent: indexParent, onChange: props.onChange, data: data, tableData: props.tableData, tableIDs: props.tableIDs, skip: props.skip, searchElement: props.searchElement})}
                                        </div>
                                    );
                                })}
                                {props.endButton ? indexParent === 0 ? props.children : '' : ''}
                            </div>
                            /*End Data Stacked Vertically */
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default Main;