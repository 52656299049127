import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'
import Table from '../../elements/Table'

import GoTo from '../../media/icons/icons8-share-96.png'

import { useCookies } from 'react-cookie'
 
const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    function getEndpointDescription(requestType, endpoint) {
        const endpointDescriptions = {
          "POST /account": "Create Account",
          "POST /account/import": "Create Account Bulk",
          "POST /account/transfer": "Create Account Transfer",
          "GET /account/{account_id}": "Get Account",
          "PATCH /account/{account_id}": "Edit Account",
          "DELETE /account/{account_id}": "Delete Account",
          "POST /account/search": "Search Account",
          "PATCH /account/bulk": "Edit Account",
          "POST /account/bulk": "Get Accounts Bulk",
          "DELETE /account/bulk": "Delete Account All",
          "POST /account/activate/{account_id}": "Activate Account",
          "POST /account/deactivate/{account_id}": "Deactivate Account",
          "DELETE /account/all": "Delete Account All",
          "POST /admin": "Create Admin",
          "POST /admin/import": "Import Bulk Admin",
          "POST /admin/bulk": "Get Admins Bulk",
          "GET /admin/{admin_id}": "Get Admin",
          "PATCH /admin/{admin_id}": "Edit Admin",
          "DELETE /admin/{admin_id}": "Delete Admin",
          "POST /admin/search": "Search Admin",
          "DELETE /admin/destroy/{admin_id}": "Destroy Admin",
          "POST /announcement": "Create Announcement",
          "GET /announcement/{announcement_id}": "Get Announcement",
          "PATCH /announcement/{announcement_id}": "Edit Announcement",
          "DELETE /announcement/{announcement_id}": "Delete Announcement",
          "POST /announcement/search": "Search Announcement",
          "POST /announcement/activate/{announcement_id}": "Activate Announcement",
          "POST /announcement/deactivate/{announcement_id}": "Deactivate Announcement",
          "POST /auth/login": "User Login",
          "POST /auth/impersonate/{user_id}": "Impersonate User",
          "POST /auth/refresh": "User Refresh",
          "POST /auth/forgot-password": "Forgot Password",
          "GET /auth/reset-password/{token}": "Reset Password",
          "POST /auth/verify": "Request Verification Email",
          "GET /auth/verify/{token}": "Verify Email",
          "POST /connection": "Create Dealership Connection",
          "POST /connection/import": "Create Dealership Connection Bulk",
          "PATCH /connection/bulk": "Edit Connections Bulk",
          "DELETE /connection/bulk": "Delete Connection All",
          "GET /connection/{connection_id}/dealership": "Get Connection Full",
          "POST /connection/dealership/bulk": "Get Dealerships From Connection Bulk",
          "GET /connection/{connection_id}": "Get Connection",
          "PATCH /connection/{connection_id}": "Edit Connection",
          "DELETE /connection/{connection_id}": "Delete Connection",
          "POST /connection/search": "Search Connection",
          "DELETE /connection/all": "Delete Connection All",
          "POST /check": "Create Check",
          "GET /check/{check_id}": "Get Check",
          "PATCH /check/{check_id}": "Edit Check",
          "DELETE /check/{check_id}": "Delete Check",
          "POST /check/search": "Search Check",
          "POST /check/activate/{check_id}": "Activate Check",
          "POST /check/deactivate/{check_id}": "Deactivate Check",
          "POST /dealership": "Create Dealership",
          "POST /dealership/import": "Import Bulk Dealership",
          "POST /dealership/bulk": "Get Dealerships Bulk",
          "GET /dealership/{dealership_id}/contract_types": "Get Dealerships Admin Contracts",
          "POST /dealership/search": "Search Dealership",
          "GET /dealership/name-search": "Search Name Dealership",
          "POST /dealership/subagent_search": "Subagent Search Dealership",
          "PATCH /dealership/{dealership_id}": "Edit Dealership",
          "DELETE /dealership/{dealership_id}": "Delete Dealership",
          "GET /dealership/{dealership_id}": "Get Dealership",
          "POST /dealership/activate/{dealership_id}": "Activate Dealership",
          "POST /dealership/deactivate/{dealership_id}": "Deactivate Dealership",
          "DELETE /dealership/all": "Delete Dealership All",
          "POST /holiday_card": "Create Holiday Card",
          "GET /holiday_card/{holiday_card_id}": "Get Holiday Card",
          "PATCH /holiday_card/{holiday_card_id}": "Edit Holiday Card",
          "DELETE /holiday_card/{holiday_card_id}": "Delete Holiday Card",
          "POST /holiday_card/search": "Search Holiday Card",
          "POST /holiday_card/activate/{holiday_card_id}": "Activate Holiday Card",
          "POST /holiday_card/deactivate/{holiday_card_id}": "Deactivate Holiday Card",
          "POST /log/search": "Search Logs",
          "POST /log/search/minified": "Search Logs Minified",
          "GET /metrics/subagent/me": "Subagent Metrics Me",
          "POST /metrics/combined_subagent_report": "Combined Subagent Report Generic",
          "POST /metrics/combined_subagent_report/{user_id}": "Combined Subagent Report",
          "POST /metrics/spiffee_report": "Spiffee Report Generic",
          "POST /metrics/spiffee_report/{spiffee_id}": "Spiffee Report",
          "POST /report/adjustment": "Create Adjustment Report",
          "DELETE /report/adjustment": "Delete Adjustment Report",
          "POST /report/adjustmentfind": "Get Adjustment Reports",
          "POST /report/adjustment/search": "Search Adjustment Report",
          "GET /report/adjustment/pending": "Reports Needing Approval",
          "GET /report/adjustment/need_approval": "Reports Needing My Approval",
          "GET /report/adjustment/completed": "Reports Completed",
          "POST /report/adjustment/approve": "Approve Adjustment Report",
          "POST /report/adjustment/reject": "Reject Adjustment Report",
          "POST /report/adjustment/activate/{adjustment_report_id}": "Activate Adjustment Report",
          "POST /report/adjustment/deactivate/{adjustment_report_id}": "Deactivate Adjustment Report",
          "POST /report/thursday_report": "Create Thursday Report",
          "DELETE /report/thursday_report": "Delete Thursday Report",
          "POST /report/thursday_report/approve": "Approve Thursday Report",
          "POST /report/thursday_report/reject": "Reject Thursday Report",
          "POST /report/thursday_report/find": "Get Thursday Reports",
          "GET /report/thursday_report/pending/{report_type}": "Reports Needing Approval",
          "GET /report/thursday_report/need_approval/{report_type}": "Reports Needing My Approval",
          "GET /report/thursday_report/completed": "Reports Completed",
          "GET /report/thursday_report/unlinked_completed": "Reports Unlinked Completed",
          "POST /report/thursday_report/search": "Search Thursday Report",
          "POST /report/thursday_report/activate/{thursday_report_id}": "Activate Thursday Report",
          "POST /report/thursday_report/deactivate/{thursday_report_id}": "Deactivate Thursday Report",
          "POST /report/saturday": "Create Saturday Report",
          "DELETE /report/saturday": "Delete Saturday Report",
          "POST /report/saturdayfind": "Get Saturday Reports",
          "PATCH /report/saturday/{report_code}": "Edit Saturday Report",
          "POST /report/saturday/search": "Search Saturday Report",
          "GET /report/saturday/pending": "Reports Needing Approval",
          "GET /report/saturday/need_approval": "Reports Needing My Approval",
          "POST /report/saturday/approve": "Approve Saturday Report",
          "POST /report/saturday/reject": "Reject Saturday Report",
          "POST /report/saturday/activate/{saturday_report_id}": "Activate Saturday Report",
          "POST /report/saturday/deactivate/{saturday_report_id}": "Deactivate Saturday Report",
          "POST /report/expense_report": "Create Expense Report",
          "GET /report/expense_report/{expense_report_id}": "Get Expense Report",
          "PATCH /report/expense_report/{expense_report_id}": "Edit Expense Report",
          "DELETE /report/expense_report/{expense_report_id}": "Delete Expense Report",
          "POST /report/expense_report/search": "Search Expense Report",
          "POST /report/expense_report/activate/{expense_report_id}": "Activate Expense Report",
          "POST /report/expense_report/deactivate/{expense_report_id}": "Deactivate Expense Report",
          "POST /report/template": "Create Template",
          "GET /report/template/{template_id}": "Get Template",
          "PATCH /report/template/{template_id}": "Edit Template",
          "DELETE /report/template/{template_id}": "Delete Template",
          "POST /report/template/search": "Search Template",
          "POST /report/template/activate/{template_id}": "Activate Template",
          "POST /report/template/deactivate/{template_id}": "Deactivate Template",
          "POST /report/search": "Search Report",
          "POST /report/approve": "Approve Report",
          "POST /report/contract_count": "Approve Report",
          "POST /report/activate/{report_id}": "Activate Report",
          "POST /report/deactivate/{report_id}": "Deactivate Report",
          "DELETE /report/all": "Delete All Report",
          "DELETE /report": "Delete Report",
          "POST /transaction": "Create Transaction",
          "GET /transaction/{transaction_id}": "Get Transaction",
          "PATCH /transaction/{transaction_id}": "Edit Transaction",
          "DELETE /transaction/{transaction_id}": "Delete Transaction",
          "POST /transaction/search": "Search Transaction",
          "POST /transaction/activate/{transaction_id}": "Activate Transaction",
          "POST /transaction/deactivate/{transaction_id}": "Deactivate Transaction",
          "POST /spiffee": "Create Spiffee",
          "POST /spiffee/import": "Import Bulk Admin",
          "POST /spiffee/search": "Search Spiffee",
          "GET /spiffee/dealership/{dealership_id}": "Get Dealership Spiffees",
          "POST /spiffee/bulk": "Get Spiffees Bulk",
          "PATCH /spiffee/{spiffee_id}": "Edit Spiffee",
          "GET /spiffee/{spiffee_id}": "Get Spiffee",
          "DELETE /spiffee/{spiffee_id}": "Delete Spiffee",
          "POST /spiffee/activate/{spiffee_id}": "Activate Spiffee",
          "POST /spiffee/deactivate/{spiffee_id}": "Deactivate Spiffee",
          "DELETE /spiffee/all": "Delete Spiffee All",
          "POST /spiff": "Create Spiff",
          "GET /spiff/{spiff_id}": "Get Spiff",
          "PATCH /spiff/{spiff_id}": "Edit Spiff",
          "DELETE /spiff/{spiff_id}": "Delete Spiff",
          "POST /spiff/search": "Search Spiff",
          "POST /spiff/activate/{spiff_id}": "Activate Spiff",
          "POST /spiff/deactivate/{spiff_id}": "Deactivate Spiff",
          "DELETE /spiff/all": "Delete Spiff",
          "POST /temp": "Create Temp",
          "GET /temp/{temp_id}": "Get Temp",
          "PATCH /temp/{temp_id}": "Edit Temp",
          "DELETE /temp/{temp_id}": "Delete Temp",
          "POST /temp/search": "Search Temp",
          "POST /temp/activate/{temp_id}": "Activate Temp",
          "POST /temp/deactivate/{temp_id}": "Deactivate Temp",
          "GET /user/me": "Get Me",
          "PATCH /user/me": "Update User",
          "GET /user/my_dealerships": "Get User Dealerships",
          "GET /user/{user_id}": "Get User",
          "PATCH /user/{user_id}": "Update User",
          "DELETE /user/{user_id}": "Delete User",
          "GET /user/full/{user_id}": "Get User",
          "POST /user/search": "Search User",
          "POST /user/bulk": "Get Users Bulk",
          "POST /user": "Create User",
          "POST /user/import": "Import Bulk User",
          "POST /user/activate/{user_id}": "Activate User",
          "POST /user/deactivate/{user_id}": "Deactivate User",
          "DELETE /user/all": "Delete User All",
          "GET /": "Root",
          "GET /time": "Server Time",
          "GET /version": "Version",
      
        };
      
        requestType = requestType.toUpperCase();

        // Replace the dynamic ID part in the endpoint URL with a placeholder
        const standardizedEndpoint = endpoint.replace(/\/[0-9a-fA-F]+(?=\/|$)/, '/{id}');

        console.log(standardizedEndpoint)
    
        // Combine requestType and standardized endpoint to create the lookup key
        const lookupKey = requestType + " " + standardizedEndpoint;
    
        // Return the description if found, or a default message
        return endpointDescriptions[lookupKey] || "Description not found for the specified endpoint and request type.";
    }

    function formatResponseToString(response) {
        console.log(response);
        // Check if the response is an array of objects
        if (Array.isArray(response) && response.every(item => typeof item === 'object' && item !== null)) {
            // Process each object in the array
            return response.map(obj => 
                Object.entries(obj)
                    .filter(([_, value]) => value !== null && value !== undefined) // Skip null or undefined values
                    .map(([key, value]) => `${key}: ${value}`)
                    .join(', ')
            ).join('; ');
        } else if (!response || typeof response !== 'object' || Array.isArray(response)) {
            console.log('Emptied!');
            return '';
        } else {
            // Process a single object
            return Object.entries(response)
                .filter(([_, value]) => value !== null && value !== undefined) // Skip null or undefined values
                .map(([key, value]) => `${key}: ${value}`)
                .join(', ');
        }
    }
      

    function convertTimestamp(inputTimestamp) {
        // Parse the input timestamp
        const date = new Date(inputTimestamp);
    
        // Define options for formatting
        const options = {
            timeZone: 'America/New_York',
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: 'numeric',
            minute: '2-digit',
            hour12: true
        };
    
        // Format the date to EST and the specified format
        const formatter = new Intl.DateTimeFormat('en-US', options);
        return formatter.format(date).replace(',', '');
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Time',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Action',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'IP',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default], 
    ])

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({
                action: true,
                user_id: props.MGMT[props.selectedMGMT].id,
                success: true,
            })
        };

        props.tokenSafeAPIRequest('/log/search', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            let tempTableData = [
                [],
                [],
                []
            ]
            let tempTableValues = [
                [],
                [],
                []
            ]
            result.map((action, index) => {
                tempTableData[0].push(convertTimestamp(action.timestamp))
                tempTableData[1].push(getEndpointDescription(action.request_type, action.endpoint))
                tempTableData[2].push(action.ip_address)

                tempTableValues[0].push(convertTimestamp(action.timestamp))
                tempTableValues[1].push(getEndpointDescription(action.request_type, action.endpoint))
                tempTableValues[2].push(action.ip_address)
            })

            console.log(tempTableData)
            console.log(tempTableValues)

            setTableData(tempTableData)
            setTableValues(tempTableValues)
        })
    },[])

    const generateKey = () => {
        let tempNum = Math.random() * 100000
        console.log(tempNum)
        return (Math.round(tempNum) + 'F') ;
    }

    return (
        <div style={{width: 'auto', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Management Actions</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <Table colors={props.colors} keys={generateKey()} tableData={tableValues} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></Table>
                <Button onClick={() => props.setIsOpen()} colors={props.colors} active={true} Style={{marginLeft: 'auto', width: '150px'}}>Close</Button>
            </div>
        </div>
    )
}

export default Main
