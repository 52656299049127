import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    console.log(props)

    let tempDisplayStorage = transposeArray(props.dealerContactInfos)[props.selectedDealer]
    let tempDataStorage = transposeArray(props.dealerContactInfos)[props.selectedDealer]

    console.log(tempDisplayStorage, tempDataStorage)

    const [data, setData] = useState({
        name: tempDataStorage[0],
        email: tempDataStorage[1],
        phoneNumber: tempDataStorage[2],
        street: tempDataStorage[3],
        city: tempDataStorage[4],
        zipCode: tempDataStorage[6],
        stateCode: tempDataStorage[5],
    });

    const [displayData, setDisplayData] = useState({
        name: tempDataStorage[0] === null ? '' : tempDataStorage[0],
        email: tempDataStorage[1] === null ? '' : tempDataStorage[1],
        phoneNumber: tempDataStorage[2] === null ? '' : tempDataStorage[2],
        street: tempDataStorage[3] === null ? '' : tempDataStorage[3],
        city: tempDataStorage[4] === null ? '' : tempDataStorage[4],
        zipCode: tempDataStorage[6] === null ? '' : tempDataStorage[6],
        stateCode: tempDataStorage[5] === null ? '' : tempDataStorage[5],
    })

    const onDataChange = (e, value) => {
        console.log(e, value)
        if (value === 'subagent')
        {
            console.log('trying to change a subagent I see!')
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e.label
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e.value
            }))
        }
        else
        {
            setDisplayData(prevData => ({
                ...prevData,
                [value]: e
            }));
            setData(prevData => ({
                ...prevData,
                [value]: e
            }))
        }
    }

    return (
        <div style={{width: '360px', height: 'auto', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Edit Contact Info</span>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border, width: '24px'}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column', marginTop: '15px'}}>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px'}}>
                    <span style={{fontWeight: '500'}}>Name</span>
                    <TextInput onChange={(e) => onDataChange(e, 'name')} colors={props.colors} data={displayData.name} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Email</span>
                    <TextInput onChange={(e) => onDataChange(e, 'email')} colors={props.colors} data={displayData.email} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Number</span>
                    <TextInput onChange={(e) => onDataChange(e, 'phoneNumber')} colors={props.colors} data={displayData.phoneNumber} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Street</span>
                    <TextInput onChange={(e) => onDataChange(e, 'street')} colors={props.colors} data={displayData.street} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>City</span>
                    <TextInput onChange={(e) => onDataChange(e, 'city')} colors={props.colors} data={displayData.city} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>State Code</span>
                    <TextInput onChange={(e) => onDataChange(e, 'stateCode')} colors={props.colors} data={displayData.stateCode} prefix=''></TextInput>
                </div>
                <div style={{display: 'flex', flexDirection: 'column', flexGrow: 1, marginRight: '10px', marginTop: '10px'}}>
                    <span style={{fontWeight: '500'}}>Zip Code</span>
                    <TextInput onChange={(e) => onDataChange(e, 'zipCode')} colors={props.colors} data={displayData.zipCode} prefix=''></TextInput>
                </div>
                <Button onClick={(e) => props.editDealer(data)} colors={props.colors} active={true} Style={{marginTop: '15px'}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
