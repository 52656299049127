import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton';
import Table from '../../elements/Table'
import Dropdown from '../../elements/ReactSelectDropdown'
import Counter from '../../elements/Counter'
import TextInput from '../../elements/TextInput'

import Delete from '../../media/icons/icons8-delete-96.png'
import Add from '../../media/icons/icons8-add-96.png'

import Button from '../../elements/Button'

import { useCookies } from 'react-cookie'

const Main = (props) => {

    const [cookies, setCookies] = useCookies([])

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'ID',
            displayElement: (props) => (<span>{props.data}</span>),
            default: 0,
        },
        {
            title: 'Name',
            displayElement: (props) => (<TextInput prefix='' width="150px" onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: "",
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={[]} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Admin',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Contract Type',
            displayElement: (props) => (<Dropdown colors={props.colors} onChange={props.onChange} index={props.index} indexParent={props.indexParent} data={props.dropdownData} width={195} value={props.data}>Test</Dropdown>),
            default: undefined,
        },
        {
            title: 'Gross / Contract',
            displayElement: (props) => (<TextInput prefix='$' width="100px" onChange={props.onChange} index={props.index} indexParent={props.indexParent} colors={props.colors} data={props.data}></TextInput>),
            default: 0,
        },
        {
            title: 'Delete',
            displayElement: (props) => (<IconButton onClick={() => deleteRow(props.index, props.tableIDs)} index={props.index} indexParent={props.indexParent} colors={props.colors} image={Delete} size={25} Style={{backgroundColor: props.colors.warning}}></IconButton>),
            default: undefined,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], //Quantity
        [tableTitles[1].default], //Template
        [tableTitles[2].default], //Dealer
        [tableTitles[3].default], //Admin
        [tableTitles[4].default], //Contract Type
        [tableTitles[5].default], //Gross/Contract
        [tableTitles[6].default], //Edit Buttons (Empty as they don't need data)
    ]) 

    const [tableData, setTableData] = useState([
        [tableTitles[0].default],
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default],
        [tableTitles[4].default],
        [tableTitles[5].default],
        [tableTitles[6].default],
    ])

    const [dropdownData, setDropdownData] = useState([
        [[]],
        [[]],
        [[]],
        [[]], 
        [[]],
        [[]],
        [[]],
    ])

    const [rowSelected, setRowSelected] = useState([false])

    const [deletedTemplates, setDeletedTemplates] = useState([])

    const addRow = () => {
        console.log('Add Row!')
        setTableValues((tableValues) => tableValues.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
        setRowSelected((rowSelected) => [...rowSelected, false]);
        setDropdownData((dropdownData) => dropdownData.map((data, index) => {
            return [...data, []]
        }))
        setTableData((tableData) => tableData.map((column, index) => [
            ...column,
            tableTitles[index].default
        ]))
    }

    const deleteRow = (index, currentTableData) => {
        console.log('Deleted Row: ' + index)

        setDeletedTemplates((deletedTemplates) => [...deletedTemplates, currentTableData[6][index]])

        setTableValues((tableValues) => tableValues.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
        setRowSelected((rowSelected) => [...rowSelected.slice(0, index), ...rowSelected.slice(index + 1)])
        setDropdownData((dropdownData) => dropdownData.map((data) => {
            return [...data.slice(0, index), ...data.slice(index + 1)];
        }))
        setTableData((tableData) => tableData.map((column) => {
            return [...column.slice(0, index), ...column.slice(index + 1)];
        }))
    }

    const onTableChange = (e, y, x) => {
        console.log(e,x,y)
        if (x === 2)
        {
            clearRowData(y)
            loadAdmins(e.value, y)
        }
        if (x === 3)
        {
            loadContractTypes(e.value, y)
        }
        setTableValues((tableValues) => tableValues.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.label;
                }
                return data;
            })
        }))
        setTableData((tableData) => tableData.map((column, indexParent) => {
            return column.map((data, index) => {
                if (index === y && indexParent === x)
                {
                    if (e.label === undefined)
                    {
                        return e;
                    }
                    return e.value;
                }
                return data;
            })
        }))
    }

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/user/my_dealerships?reverse_sort=true', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempDealerList = []
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
            setTableTitles((tableTitles) => {
                return tableTitles.map((title) => {
                    if (title.title === 'Dealer')
                    {
                        return {
                            ...title,
                            displayElement: (props) => (
                              <Dropdown
                                colors={props.colors}
                                onChange={props.onChange}
                                index={props.index}
                                indexParent={props.indexParent}
                                data={tempDealerList} 
                                width={195}
                                value={props.data}
                              >
                              </Dropdown>
                            ),
                        };
                    }
                    return title;
                })
            })
        })

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({template_type: 'Thursday'})
        };

        props.tokenSafeAPIRequest('/report/template/search?fetch_links=true&limit=100', requestOptions, tokenValues, (result) => {
            console.log(result)
            let tempTableValues = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            let tempTableData = [
                [],
                [],
                [],
                [],
                [],
                [],
                [],
            ]
            result.map((template, index) => {
                tempTableData[0].push(index)
                tempTableData[1].push(template.name)
                tempTableData[2].push(template.dealership_ID.id)
                tempTableData[3].push(template.admin_ID.id)
                tempTableData[4].push(template.contract_type)
                tempTableData[5].push(template.gross_per_contract)
                tempTableData[6].push(template.id)

                tempTableValues[0].push(index)
                tempTableValues[1].push(template.name)
                tempTableValues[2].push(template.dealership_ID.name)
                tempTableValues[3].push(template.admin_ID.name)
                tempTableValues[4].push(template.contract_type)
                tempTableValues[5].push(template.gross_per_contract)
                tempTableValues[6].push('')
                
                loadAdmins(template.dealership_ID.id, index)
                loadContractTypes(template.admin_ID.id, index)
            })

            console.log(tempTableValues)
            console.log(tempTableData)
    
            setTableValues(tempTableValues)
            setTableData(tempTableData)
        })
    },[])

    const loadAdmins = (dealer, y) => {

        console.log(dealer, y)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/dealership/' + dealer + '?fetch_links=True', requestOptions, tokenValues, (result) => {
            let tempAdminList = []
            result.admin_IDs.map((admin, index) => {
                tempAdminList.push({value: admin.id, label: admin.name})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[3]]
                newSubArray[y] = tempAdminList;
                newData[3] = newSubArray;
                return newData
            })
        })
    }

    const loadContractTypes = (admin, y) => {

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        props.tokenSafeAPIRequest('/admin/' + admin, requestOptions, tokenValues, (result) => {
            let tempContractTypeList = []
            result.VSC_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'VSC', subType: contractType}, label: "VSC - " + contractType})
            })
            result.ancillary_contracts.map((contractType, index) => {
                tempContractTypeList.push({value: {type: 'Ancillary', subType: contractType}, label: "Ancillary - " + contractType})
            })

            setDropdownData((dropdownData, index) => {
                const newData = [...dropdownData]
                const newSubArray = [...newData[4]]
                newSubArray[y] = tempContractTypeList;
                newData[4] = newSubArray;
                return newData
            })
        })
    }

    const clearRowData = (row) => {
        console.log(transposeArray(tableData))
        let tempData = transposeArray(tableData)
        let tempDataDisplay = transposeArray(tableValues)
        tempData[row][3] = ''
        tempData[row][4] = ''
        tempDataDisplay[row][3] = ''
        tempDataDisplay[row][4] = ''
        setTableData(transposeArray(tempData))
        setTableValues(transposeArray(tempDataDisplay))
    }

    const transposeArray = (array) => {
        return array[0].map((_, colIndex) => array.map(row => row[colIndex]));
    };

    return (
        <div style={{width: '1130px', height: '456px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '99%'}}>Manage Template</span>

                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%'}}>
                <Table colors={props.colors} tableData={tableValues} tableIDs={tableData} tableInfo={tableTitles} dropdownData={dropdownData} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false}></Table>
            </div>
            <div style={{flexBasis: '5%', display: 'flex', flexDirection: 'row'}}>
                <Button onClick={addRow} colors={props.colors} Style={{backgroundColor: props.colors.primary, color: 'white', fontWeight: '600', fontSize: '20px', borderRadius: '5px', padding: '0px 10px 0px 10px', width: '35px', marginLeft: '20px'}}>+</Button>
                <span style={{marginTop: '4px', marginLeft: '15px', fontWeight: '500'}}>Add New Template</span>
            </div>
            <div style={{flexBasis: '5%'}}>
                <Button onClick={() => props.saveTemplates(tableData, tableValues, deletedTemplates)} colors={props.colors} active={true} Style={{float: 'right', width: 200}}>Save</Button>
            </div>
        </div>
    )
}

export default Main
