import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import IconButton from '../../elements/IconButton'
import Dropdown from '../../elements/ReactSelectDropdown'
import TextInput from '../../elements/TextInput'
import Button from '../../elements/Button'

const Main = (props) => {

    const dealers = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    const [newInputData, setNewInputData] = useState(props.selectedInput)
    
    const onChange = (e, indexParent) => {
        setTempReserveInfo((tempReserveInfo) => tempReserveInfo.map((data, index) => {
            if (index === indexParent)
            {
                if (index === 7 && indexParent === 0)
                {
                    //bypass for ID
                    return e.value
                }
                if (e.label === undefined)
                {
                    return e;
                }
                return e.label;
            } else {return data}
        }))
    }

    const [tempReserveInfo, setTempReserveInfo] = useState(
        props.selectedInput === undefined ? '':
        [
            props.selectedInput.account,
            props.selectedInput.amount,
            props.selectedInput.reason,
            props.selectedInput.id,
        ]
    )

    console.log(props)

    return (
        <div style={{width: '360px', height: '350px', display: 'flex', flexDirection: 'column'}}>
            <div style={{flexBasis: '10%', display: 'flex', flexDirection: 'row'}}>
                <span style={{fontWeight: '500', fontSize: '20px', flexBasis: '60%'}}>Edit Reserve Input</span>
                <Button onClick={() => {props.deleteReserveInput(props.inputIndex); props.setIsOpen()}} Style={{flexBasis: '30%', backgroundColor: props.colors.warning, height: '35px'}} active={true} colors={props.colors}>Delete</Button>
                <div style={{flexBasis: '9%'}}></div>
                <IconButton img={false} colors={props.colors} Style={{borderRadius: '3px', backgroundColor: props.colors.border}} size="16px">
                    <div onClick={props.setIsOpen} style={{paddingLeft: '3px', paddingRight: '3px'}}><span onClick={props.setIsOpen}>x</span></div>
                </IconButton>
            </div>
            <div style={{flexBasis: '90%', display: 'flex', flexDirection: 'column'}}>
                <span style={{marginTop: '15px'}}>Account</span>
                <div style={{marginTop: '5px'}}><Dropdown colors={props.colors} onChange={(e) => onChange(e, 0)} index={props.index} indexParent={props.indexParent} data={props.reserveAccounts} value={tempReserveInfo[0]}></Dropdown></div>
                <span style={{marginTop: '15px'}}>Amount</span>
                <TextInput Style={{marginTop: '5px'}} prefix="$" onChange={(e) => onChange(e, 1)} colors={props.colors} data={tempReserveInfo[1]}></TextInput>
                <span style={{marginTop: '15px'}}>Reason</span>
                <TextInput Style={{marginTop: '5px'}} prefix="" onChange={(e) => onChange(e, 2)} colors={props.colors} data={tempReserveInfo[2]}></TextInput>
                <Button onClick={() => {props.setIsOpen(); props.updateReserveInputs(tempReserveInfo, props.inputIndex)}} Style={{marginTop: '20px'}} active={true} colors={props.colors}>Save</Button>
            </div>
        </div>
    )
}

export default Main
