import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import OutputLayoutColumn from '../elements/OutputLayoutColumn'

import Sidebar from '../elements/Sidebar'
import Controller from '../elements/Controller'
import OutputColumn from '../elements/OutputColumn'
import Button from '../elements/Button'
import OutputHeader from '../elements/OutputHeader'
import OutputFilter from '../elements/OutputFilter'
import OutputBarChart from '../elements/OutputBarChart'
import OutputTable from '../elements/OutputTable'

import Dropdown from '../elements/ReactSelectDropdown'

import ModalManager from '../elements/ModalManager'
import FiltersModal from '../assets/metrics/FiltersModal'

import DealerAdminsListSignups from '../assets/metrics/DealerAdminsListSignups'

import ExportData from '../assets/metrics/ExportData'

import CalendarModal from '../assets/metrics/CalendarModal'

const Main = (props) => {

    const list = ['Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski', 'Jon Bukowski']

    const filterValues = ['Liberty Shield','VSC','Weekly 7','4 Season Motors']

    const [viewAdmins, setViewAdmins] = useState(false);
    const [selectedDealer, setSelectedDealer] = useState(false)

    const formatDateFilters = (date) => {
        if (!(date instanceof Date)) {
            return '';
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString(); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${year}-${month}-${day}`;
    }

    const [tableTitles, setTableTitles] = useState([
        {
            title: 'Agent',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Dealer',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Admins',
            displayElement: (props) => (<Button onClick={() => {setViewAdmins(true); setSelectedDealer(props.index)}} colors={props.colors} Style={{borderRadius: '5px'}}>View</Button>),
            default: '',
        },
        {
            title: 'Date',
            displayElement: (props) => (<span style={{marginLeft: '30px', marginRight: '30px', whiteSpace: 'nowrap'}}>{props.data}</span>),
            default: '',
        },
        {
            title: 'Inside Sales',
            displayElement: (props) => (<div style={{width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: false,
        },
        {
            title: 'Incentive Form',
            displayElement: (props) => (<div style={{width: '28px', height: '28px', border: props.data === true ? '2px solid ' + props.colors.primary:'2px solid ' + props.colors.border, borderRadius: '5px', backgroundColor: props.data === true  ? props.colors.primary:''}}></div>),
            default: false,
        },
    ])

    const [tableValues, setTableValues] = useState([
        [tableTitles[0].default], 
        [tableTitles[1].default],
        [tableTitles[2].default],
        [tableTitles[3].default], 
        [tableTitles[4].default],
        [tableTitles[5].default],
    ])

    const [calStartDate, setCalStartDate] = useState(('2000-01-01'))
    const [calEndDate, setCalEndDate] = useState(('2200-01-01'))

    function formatNumber(value) {
        try {
            // Attempt to convert the input to a number
            const num = parseFloat(value);
            // Check if the conversion resulted in a valid number
            if (!isNaN(num)) {
              // Use Intl.NumberFormat for US-style number formatting with two decimal places
              return new Intl.NumberFormat('en-US', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }).format(num);
            } else {
              // Return a default value if the input is not a valid number
              return '0.00';
            }
          } catch (e) {
            // In case of any unexpected errors, return a default value
            return '0.00';
          }
    }

    const [rowSelected, setRowSelected] = useState([false])

    const onTableChange = (e,x,y) => {
        console.log(e,x,y)
    }

    const [headerValues, setHeaderValues] = useState([])
    const [selectedTimescale, setSelectedTimescale] = useState({value: '1 Month', label: '1 Month'})
    const [skip, setSkip] = useState(0)
    const [filtersModal, setFiltersModal] = useState(false)
    const [sort, setSort] = useState('timestamp')

    const timescales = [
        {value: '1 Year', label: '1 Year'},
        {value: '4 Months', label: '4 Months'},
        {value: '1 Month', label: '1 Month'},
        {value: '1 Week', label: '1 Week'},
    ]
    const [filterData, setFilterData] = useState([
        {
            title: 'Subagent',
            data: '',
            value: {label: '', value: ''},
            key: 'subagent_owner_ID',
        },
        {
            title: 'Inside Sales',
            data: [
                {value: true, label: 'True'},
                {value: false, label: 'False'},
            ],
            value: {label: '', value: ''},
            key: 'inside_sales',
        },
        {
            title: 'Incentive Form',
            data: [
                {value: true, label: 'True'},
                {value: false, label: 'False'},
            ],
            value: {label: '', value: ''},
            key: 'signed_incentive_form',
        },
    ])

    function formatDate(date) {
        if (!(date instanceof Date)) {
            throw new Error('Input must be a Date object');
        }
    
        let day = date.getDate();
        let month = date.getMonth() + 1; // getMonth() returns 0-11
        let year = date.getFullYear().toString().substr(-2); // Get last two digits
    
        // Pad single digit month and day with a zero
        month = month < 10 ? '0' + month : month;
        day = day < 10 ? '0' + day : day;
    
        return `${month}/${day}/${year}`;
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let tempDealerList = []
        let tempSubagentList = []

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({})
        }

        props.tokenSafeAPIRequest('/dealership/names?active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.map((dealer, index) => {
                tempDealerList.push({value: dealer.id, label: dealer.name})
            })
        })
        
        requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow',
            body: JSON.stringify({subagent: true})
          }

        props.tokenSafeAPIRequest('/user/search?limit=99999&active_only=false', requestOptions, '', (result) => {
            console.log(result)
            result.map((subagent, index) => {
                tempSubagentList.push({value: subagent.id, label: subagent.name})
            })
        })

        setFilterData([
            {
                title: 'Subagent',
                data: tempSubagentList,
                value: {label: '', value: ''},
                key: 'subagent_owner_ID',
            },
            {
                title: 'Inside Sales',
                data: [
                    {value: true, label: 'True'},
                    {value: false, label: 'False'},
                ],
                value: {label: '', value: ''},
                key: 'inside_sales',
            },
            {
                title: 'Incentive Form',
                data: [
                    {value: true, label: 'True'},
                    {value: false, label: 'False'},
                ],
                value: {label: '', value: ''},
                key: 'signed_incentive_form',
            },
        ])
    },[])

    const getDateFromTimescale = (timescale) => {
        const currentDate = new Date();
  
        switch (timescale) {
          case '1 Year':
            currentDate.setFullYear(currentDate.getFullYear() - 1);
            break;
          case '4 Months':
            currentDate.setMonth(currentDate.getMonth() - 4);
            break;
          case '1 Month':
            currentDate.setMonth(currentDate.getMonth() - 1);
            break;
          case '1 Week':
            currentDate.setDate(currentDate.getDate() - 7);
            break;
          default:
            return "Invalid timescale";
        }

        // Construct the date string in 'yyyymmdd' format
        const year = currentDate.getFullYear();
        const month = ('0' + (currentDate.getMonth() + 1)).slice(-2); // Months are 0-indexed in JS
        const day = ('0' + currentDate.getDate()).slice(-2);
        
        return `${year}-${month}-${day}`;
    }

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/dealership/signups?fetch_links=true&limit=15&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)
            if (result.dealerships.length === 0)
            {
                setTableValues([
                    [], 
                    [],
                    [],
                    [], 
                    [],
                    [],
                ])
                let tempStats = [
                    {title: 'Total Signups', value: '$' + 0},
                    {title: 'Total Inside Sales', value: '' + 0},
                    // {title: 'Total DPPP', value: 0},
                    // {title: 'Total GAP Plus', value: 0},
                ]
                setHeaderValues(tempStats)
            }
            else
            {
                let tempStats = [
                    {title: 'Total Signups', value: '' + formatNumber(result.total_dealerships)},
                    {title: 'Total Inside Sales', value: '' + formatNumber(result.total_inside_sales)},
                    // {title: 'Total DPPP', value: (Number.parseFloat(result.total_dppp) * 100)},
                    // {title: 'Total GAP Plus', value: Number.parseFloat(result.total_gap)},
                ]
                setHeaderValues(tempStats)

                let tempTableValues = [
                    [],
                    [],
                    [],
                    [],
                    [],
                    [],
                ]

                result.dealerships.map((signup) => {
                    tempTableValues[0].push(signup.subagent_owner_ID.first_name + ' ' + signup.subagent_owner_ID.last_name)
                    tempTableValues[1].push(signup.name)
                    tempTableValues[2].push(signup.admin_IDs)
                    tempTableValues[3].push(formatDate(new Date(signup.timestamp)))
                    tempTableValues[4].push(signup.inside_sales)
                    tempTableValues[5].push(signup.signed_incentive_form)
                })

                setTableValues(tempTableValues)
            }
        })
        setSkip(0)
    },[selectedTimescale, filterData, sort, calStartDate, calEndDate])

    const onBottom = () => {
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");

        let filterDataBy = {}
        let startDate = ''
        filterData.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result      
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
    
        var requestOptions = {
          method: 'POST',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify({
            ...filterDataBy
          })
        }

        props.tokenSafeAPIRequest('/metrics/dealership/signups?fetch_links=true&limit=15&skip=' + (skip+15) + '&sort_field=' + sort + '&start_date=' + calStartDate + '&end_date=' + calEndDate, requestOptions, '', (result, status) => {
            console.log(result)

            let tempTableValues = tableValues

            result.dealerships.map((signup) => {
                tempTableValues[0].push(signup.subagent_owner_ID.first_name + ' ' + signup.subagent_owner_ID.last_name)
                tempTableValues[1].push(signup.name)
                tempTableValues[2].push(signup.admin_IDs)
                tempTableValues[3].push(formatDate(new Date(signup.timestamp)))
                tempTableValues[4].push(signup.inside_sales)
                tempTableValues[5].push(signup.signed_incentive_form)
            })

            setTableValues(tempTableValues)
        })
        setSkip((skip) => skip + 15)
    }

    const saveFilters = (data, displayData) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => { console.log(item)
                return {...item, value: {value: data[item.key], label: displayData[item.key]}}
            })
        })
    } 

    const removeFilter = (key) => {
        setFilterData((prevState, index) => {
            return prevState.map((item, index) => {
                if (item.key === key)
                {
                    return {...item, value: {value: '', label: ''}}
                }
                else {return item}
            })
        })

        setFilterModalDisplayData((prevState) => {
            return {...prevState, [key]: ''}
        })

        setFilterModalData((prevState) => {
            return {...prevState, [key]: ''}
        })
    }

    const [filterModalDisplayData, setFilterModalDisplayData] = useState({
        subagent_owner_ID: '',
        inside_sales: '',
        signed_incentive_form: '',
    })

    const [filterModalData, setFilterModalData] = useState({
        subagent_owner_ID: '',
        inside_sales: '',
        signed_incentive_form: '',
    })

    const updateFilterModalData = (e, key) => {
        setFilterModalDisplayData(data => ({
            ...data,
            [key]: e.label
        }))
        setFilterModalData(data => ({
            ...data,
            [key]: e.value
        }))
    }

    const sortOptions = [
        { value: 'timestamp', label: 'Date' },
        { value: 'name', label: 'Dealership' },
    ]

    const sortToName = {
        timestamp: 'Date',
        name: 'Dealership'
    }

    const masterList = [
        {
            name: 'Export Report',
            link: '',
            onClick: () => {setExportDataModal(true)},
        },
        {
            name: 'Combined Dealer Report',
            link: '/output/combinedDealerReport',
            key: "CombinedDealerReport",
            onClick: () => {},
        },
        {
            name: 'Combined Subagent Report',
            link: '/output/combinedSubagentReport',
            key: "CombinedSubagentReport",
            onClick: () => {},
        },
        {
            name: 'Contract Count Report',
            link: '/output/contractCountReport',
            key: "ContractCountReport",
            onClick: () => {},
        },
        {
            name: 'Inside Sales Report',
            link: '/output/insidesalesreport',
            key: "InsideSalesReport",
            onClick: () => {},
        },
        // {
        //     name: '-List of Gift Cards',
        //     link: '/output/listOfGiftCards',
        //     key: "GiftCardList",
        //     onClick: () => {},
        // },
        {
            name: 'List of Reserve Input/Outputs',
            link: '/output/listOfReserveInputs',
            key: "ReserveAccounts",
            onClick: () => {},
        },
        // {
        //     name: '-List of Reserve Outputs',
        //     link: '/output/listOfReserveOutputs',
        //     key: "ReserveInputs",
        //     onClick: () => {},
        // },
        {
            name: 'List of Saturday Reports',
            link: '/output/listOfSaturdayReports',
            key: "SaturdayReport",
            onClick: () => {},
        },
        {
            name: 'New Dealer Signups',
            link: '/output/newDealerSignups',
            key: "NewDealerSignups",
            onClick: () => {},
        },
        // {
        //     name: '-Outstanding Pre-spiffs',
        //     link: '/output/outstandingPrespiffs',
        //     key: "OutstandingPrespiffs",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Recent Address Changes',
        //     link: '/output/recentAddressChanges',
        //     key: "RecentAddressChanges",
        //     onClick: () => {},
        // },
        {
            name: 'Reporting History',
            link: '/output/reportingHistory',
            key: "ReportingHistory",
            onClick: () => {},
        },
        // {
        //     name: '-Reserve Account Reports',
        //     link: '/output/reserveAccountReports',
        //     key: "ReserveAccountReport",
        //     onClick: () => {},
        // },
        {
            name: 'Spiffees Report',
            link: '/output/spiffeesReport',
            key: "SpiffeesReport",
            onClick: () => {},
        },
        // {
        //     name: '-Subagent Business',
        //     link: '/output/subagentBusiness',
        //     key: "SubagentBusiness",
        //     onClick: () => {},
        // },
        // {
        //     name: '-Subagent Net & Inside Sales',
        //     link: '/output/subagentNetAndInsideSales',
        //     key: "SubagentNetInsideSales",
        //     onClick: () => {},
        // },
        {
            name: 'Void & Needs to be Replaced',
            link: '/output/voidAndNeedsReplaced',
            key: "VoidAndNeedsToBeReplaced",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Weeks List',
            link: '/output/reserveAccountWeeks',
            key: "ReserveAccountWeeks",
            onClick: () => {},
        },
        {
            name: 'Combined Spiffees Report',
            link: '/output/combinedSpiffeesReport',
            key: "CombinedSpiffeesReport",
            onClick: () => {},
        },
        {
            name: 'List of Expense Reports',
            link: '/output/expenseReports',
            key: "ListOfExpenseReports",
            onClick: () => {},
        },
        {
            name: 'List of Adjustment Reports',
            link: '/output/adjustmentReports',
            key: "ListOfAdjustmentReports",
            onClick: () => {},
        },
        {
            name: 'List of DPPP/GAP Plus',
            link: '/output/DPPPGAP',
            key: "ListOfDPPP/GAP",
            onClick: () => {},
        },
        {
            name: 'Reserve Account Totals',
            link: '/output/reserveAccountTotals',
            key: "ReserveAccountTotals",
            onClick: () => {},
        },
    ]

    useEffect(() => {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));

        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/permission/me', requestOptions, '', (result, status) => {
            console.log(result) //'*':'*' - Superadmin
            let tempButtonList = [{
                name: 'Export Report',
                link: '',
                onClick: () => {setExportDataModal(true)},
            }]
            if (result.static_role === 'superadmin' || result.static_role === 'admin')
            {
                localStorage.setItem('button_list', JSON.stringify(masterList))
                setButtonList(masterList)
            }
            else
            {
                result.allow.Metrics.map((metric) => {
                    masterList.map((button) => {
                        if (metric === button.key)
                        {
                            tempButtonList.push(button)
                        }
                    })
                })
                tempButtonList = tempButtonList.sort((a,b) => {
                    if (a.name === "Export Report") return -1; // Move "Export Report" to the top
                    if (b.name === "Export Report") return 1;
                    return a.name.localeCompare(b.name);
                })
                localStorage.setItem('button_list', JSON.stringify(tempButtonList))
                setButtonList(tempButtonList)
            }
        })  
    },[])

    const [exportDataModal, setExportDataModal] = useState(false)
    const [buttonList, setButtonList] = useState(JSON.parse(localStorage.getItem('button_list')))

    const exportKeys = [{id: 'subagent_owner_ID.first_name', label: 'Agent First Name'}, {id: 'subagent_owner_ID.last_name', label: 'Agent Last Name'}, {id: 'name', label: 'Dealership'}, {id: 'admin', label: 'Admins'}, {id: 'timestamp', label: 'Date'}, {id: 'inside_sales', label: 'Is Inside Sales'}, {id: 'signed_incentive_form', label: 'Signed Incentive Form'}]

    const buildFilterData = (data) => {
        let filterDataBy = {}
        let startDate = ''
        data.map((category, index) => {
            if (category.key === 'contract_type')
            {
                filterDataBy = {
                    ...filterDataBy, 
                    contract_type: category.value.value === '' ? null:category.value.value.split(' - ')[0], 
                    contract_subtype: category.value.value === '' ? null:category.value.value.split(' - ')[1]
                }
            }
            else if (category.key === 'date')
            {
                const today = new Date();
                const result = new Date(today);
                if (category.value.value === '')
                {
                    result.setFullYear(today.getFullYear() - 100);
                }
                else
                {
                    result.setDate(today.getDate() - category.value.value);
                }
                startDate = result
            }
            else
            {
                filterDataBy = {...filterDataBy, [category.key]: category.value.value === '' ? null:category.value.value}
            }
        })
        return {
            ...filterDataBy,
            start_date: getDateFromTimescale(selectedTimescale.label),
        }
    }

    const exportPostProcessing = (result) => {
        result.dealerships.map((dealer) => {
            let tempDealer = dealer
            let tempDealerAdmins = []
            tempDealer.admin_IDs.map((admin) => {
                tempDealerAdmins.push(admin.name)
            })
            tempDealer.admin = tempDealerAdmins.toString();
            console.log(tempDealerAdmins.toString())
            return tempDealer
        })
        return result;
    }

    const [selectDatesModal, setSelectDatesModal] = useState(false)

    const filterDate = (start, end) => {
        if (start.getTime() > end.getTime())
        {
            
        }
        else
        {
            start = formatDateFilters(start)
            end = formatDateFilters(end)
            setCalStartDate(start)
            setCalEndDate(end)
        }
    }

    return (
        <OutputLayoutColumn colors={props.colors}>
            <Sidebar colors={props.colors} tab={1}></Sidebar>
            <Controller colors={props.colors} tokenSafeAPIRequest={props.tokenSafeAPIRequest} activeButton={true} buttonList={buttonList} title={"Outputs"}></Controller>
            <OutputColumn colors={props.colors} list={list} title={"Subagents"}></OutputColumn>
            <div> {/*THERE IS A HIDDEN STYLE TAG FROM THE OUTPUT LAYOUT --- width: "auto", marginLeft: '20px', display: 'flex', flexDirection: 'column'*/}
                <Button colors={props.colors} onClick={() => setSelectDatesModal(true)} active={true} Style={{marginLeft: 'auto', marginRight: '20px'}}>Select Week</Button>
                <OutputHeader colors={props.colors} headerValues={headerValues}></OutputHeader>
                <OutputFilter colors={props.colors} onClick={() => setFiltersModal(true)} filterData={filterData} removeFilter={removeFilter} setSort={setSort} sort={sort} sortOptions={sortOptions} sortToName={sortToName}></OutputFilter>
                <OutputBarChart colors={props.colors} title={'Net'}></OutputBarChart>
                <OutputTable colors={props.colors} tableData={tableValues} tableInfo={tableTitles} rows={rowSelected} setRows={setRowSelected} onChange={onTableChange} selectMenu={false} onBottom={onBottom}></OutputTable>
            </div>
            <ModalManager colors={props.colors}>
                <FiltersModal colors={props.colors} isOpen={filtersModal} setIsOpen={() => setFiltersModal(false)} filterData={filterData} saveFilters={saveFilters} displayData={filterModalDisplayData} data={filterModalData} updateData={updateFilterModalData}></FiltersModal>
                <DealerAdminsListSignups colors={props.colors} isOpen={viewAdmins} tableIDs={tableValues} selectedDealer={selectedDealer} setIsOpen={() => setViewAdmins(false)}></DealerAdminsListSignups>
                <ExportData colors={props.colors} calStartDate={calStartDate} calEndDate={calEndDate} isOpen={exportDataModal} setIsOpen={() => setExportDataModal(false)} filterData={buildFilterData(filterData)} url={'/metrics/dealership/signups'} sort={sort} tokenSafeAPIRequest={props.tokenSafeAPIRequest} exportKeys={exportKeys} fileTitle={'New Dealer Signups Report'} startDate={getDateFromTimescale(selectedTimescale.value)} postProcessing={exportPostProcessing}></ExportData>
                <CalendarModal colors={props.colors} isOpen={selectDatesModal} setIsOpen={() => setSelectDatesModal(false)} filterDate={filterDate}></CalendarModal>
            </ModalManager>
        </OutputLayoutColumn>
    )
}

export default Main
