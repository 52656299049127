import React, { Component, useState, useEffect, useRef, useLayoutEffect } from 'react'

import { AnimatePresence, motion, useMotionValue, useTransform, animate, useScroll, useMotionValueEvent, useAnimationControls, motionValue } from "framer-motion"

import { NavLink, Link } from "react-router-dom"

import Modal from 'react-modal';

import Sidebar from '../elements/Sidebar.jsx'
import Controller from '../elements/Controller.jsx'
import MetricHeader from '../elements/MetricHeader.jsx'
import MainLayout from '../elements/MainLayout.jsx'
import ModalManager from '../elements/ModalManager.jsx'
import Settings from '../assets/userSettings/Settings.jsx'
import Notification from '../elements/Notification.jsx'

import { useCookies } from 'react-cookie'

const Main = (props) => {
    
    const [cookies, setCookies] = useCookies([])

    const buttonList = [
        {
            name: 'Home',
            link: '/',
            onClick: () => {},
        },
        {
            name: 'Save Changes',
            link: '',
            onClick: () => {updateSettings(data)},
        },
    ]

    const dealers2 = [
        { value: '4 Season Motors', label: '4 Season Motors' },
        { value: 'Chopper City', label: 'Chopper City' },
        { value: 'Auto Station', label: 'Auto Station' },
        { value: 'Duke City Auto LLC', label: 'Duke City Auto LLC' },
        { value: 'Pitre Buick GMC', label: 'Pitre Buick GMC' },
        { value: 'Sunset Motors', label: 'Sunset Motors' },
        { value: 'Speedy Wheels', label: 'Speedy Wheels' },
        { value: 'Elite Autos', label: 'Elite Autos' },
        { value: 'Golden Motors', label: 'Golden Motors' },
        { value: 'Thunder Auto', label: 'Thunder Auto' },
        { value: 'Cityscape Cars', label: 'Cityscape Cars' },
        { value: 'Majestic Motors', label: 'Majestic Motors' },
        { value: 'Eagle Eye Autos', label: 'Eagle Eye Autos' },
        { value: 'Sprint Auto Solutions', label: 'Sprint Auto Solutions' },
        { value: 'Grand Prix Motors', label: 'Grand Prix Motors' },
        { value: 'Royal Wheels', label: 'Royal Wheels' },
        { value: 'Pinnacle Cars', label: 'Pinnacle Cars' },
        { value: 'Prime Drive', label: 'Prime Drive' },
        { value: 'Dynamic Motors', label: 'Dynamic Motors' },
        { value: 'Precision Auto', label: 'Precision Auto' },
        { value: 'Evergreen Vehicles', label: 'Evergreen Vehicles' },
        { value: 'Blue Ribbon Autos', label: 'Blue Ribbon Autos' },
        { value: 'Safari Motors', label: 'Safari Motors' },
        { value: 'Vanguard Auto', label: 'Vanguard Auto' },
        { value: 'Velocity Motors', label: 'Velocity Motors' },
        { value: 'Omega Cars', label: 'Omega Cars' },
        { value: 'Supreme Autos', label: 'Supreme Autos' },
        { value: 'Victory Vehicles', label: 'Victory Vehicles' },
        { value: 'Zenith Motors', label: 'Zenith Motors' }
    ]

    console.log(props)

    const [userData, setUserData] = useState('')

    const [data, setData] = useState({})

    useEffect(() => {
        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'GET',
          headers: myHeaders,
          redirect: 'follow',
        };

        props.tokenSafeAPIRequest('/user/me', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            setData({
                first_name: result.first_name === null ? '' : result.first_name,
                last_name: result.last_name === null ? '' : result.last_name,
                email: result.email === null ? '' : result.email,
                address: result.address === null ? '' : result.address,
                state_code: result.state_code === null ? '' : result.state_code,
                city: result.city === null ? '' : result.city,
                zip_code: result.zip_code === null ? '' : result.zip_code,
                phone_number: result.phone_number === null ? '' : result.phone_number,
                username: result.username === null ? '' : result.username,
                role: result.role === null ? '' : result.role,
                dark_mode: result.dark_mode === null ? '' : result.dark_mode,
                helpful_tips: result.helpful_tips === null ? '' : result.helpful_tips,
                last_password_change: result.last_password_change === null ? '' : result.last_password_change,
                email_confirmed_date: result.email_confirmed_date === null ? '' : result.email_confirmed_date,
                id: result.id === null ? '' : result.id,
            });
        })
    },[])
    
    const onDataChange = (e, value) => {
        setData(prevData => ({
            ...prevData,
            [value]: e
        }))
    } 

    function getCurrentTimeFormatted() {
        const now = new Date();
  
        let hours = now.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        
        hours = hours % 12; // Convert to 12-hour format
        hours = hours ? hours : 12; // The hour '0' should be '12'
        
        const minutes = now.getMinutes().toString().padStart(2, '0');
        const seconds = now.getSeconds().toString().padStart(2, '0');
      
        return `${hours.toString().padStart(2, '0')}:${minutes}:${seconds} ${ampm}`;
    }

    function replaceEmptyStringsWithNull(obj) {
        Object.keys(obj).forEach(key => {
            if (obj[key] === "") {
                obj[key] = null;
            }
        });
        return obj;
    }

    const updateSettings = (newSettings) => {
        console.log(newSettings)

        let tokenValues = {
            refresh_token: cookies['refresh_token'],
            refresh_token_expires: cookies['refresh_token_expires'],
            access_token: cookies['access_token'],
            access_token_expires: cookies['access_token_expires'],
        }

        //get dealers
        var myHeaders = new Headers();
        myHeaders.append("Accept", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('access_token'));
        myHeaders.append("Content-Type", "application/json");
    
        var requestOptions = {
          method: 'PATCH',
          headers: myHeaders,
          redirect: 'follow',
          body: JSON.stringify(replaceEmptyStringsWithNull(newSettings))
        };

        props.tokenSafeAPIRequest('/user/me', requestOptions, tokenValues, (result, status) => {
            console.log(result)
            if (status === 200)
            {
                setNotification('Updated Setting Successfully : ' + getCurrentTimeFormatted())
            } else {setNotification(props.getServerResponse(result))}
        })
    }

    const [notification, setNotification] = useState('')

    return (
        <MainLayout colors={props.colors}>
            <Sidebar colors={props.colors} tab={0}></Sidebar>
            <Controller colors={props.colors} activeButton={true} buttonList={buttonList} title={"User Settings/Info"} tokenSafeAPIRequest={props.tokenSafeAPIRequest}></Controller>
            <div>{/*Just to make sure nothing goes wrong with MainLayout, as this takes the place of MetricHeader*/}</div>
            <Settings colors={props.colors} userData={userData} onDataChange={onDataChange} data={data}></Settings>
            <Notification colors={props.colors} message={notification}></Notification>
        </MainLayout>
    )
}

export default Main
